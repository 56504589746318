import { useEffect, useState } from "react"
import { partnerConflictStore } from "../stores/partnerStore"
import { apiRoutes } from "../utils/constants"

const useLoadConflicts = (partnerId?: string) => {
  const [loadingPageSimulation, setLoadingPageSimulation] = useState(true)

  useEffect(() => {
    if (!partnerId) return
    let timer: NodeJS.Timeout
  
    partnerConflictStore.makeRequest(undefined, {
      url: `${apiRoutes.PARTNERS}/${partnerId}/conflicts`
    })
  
    timer = setTimeout(() => {
      setLoadingPageSimulation(false)
    }, 100)
  
    return () => {
      partnerConflictStore.clear()
      if (timer) clearTimeout(timer)
    }
  }, [partnerId])

  return loadingPageSimulation
}

export default useLoadConflicts
