import styled from "styled-components";
import { colors, spacing } from "../../styles/_var";

export const Wrapper = styled.div`

`

export const ResultWrapper = styled(({ concealed, ...rest }) => <div {...rest} />)`
  background-color: ${colors.yellow};
  ${({ concealed }) => concealed && `
    background-color: ${colors.lightgrey};
  `}
  padding: ${spacing.xs};
  border-radius: 20px;
  max-width: 32rem;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: ${spacing.sm};
`

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
`
