import React, { useEffect } from "react"
import GoogleLogin, { GoogleLoginResponse, GoogleLoginResponseOffline } from 'react-google-login';
import { gapi } from 'gapi-script';
import { Wrapper } from './GoogleAuthButton.styles'
import { userStore } from "../../stores/userStore";

type GoogleAuthButtonProps = {
  handleLogin?: (response: GoogleLoginResponse | GoogleLoginResponseOffline) => void,
  handleError?: (error: any) => void,
  buttonText?: string
}


const GoogleAuthButton: React.FC<GoogleAuthButtonProps> = ({
  handleError,
  handleLogin,
  buttonText='Sign in with Google'
}) => {
  useEffect(() => {
    function start() {
      gapi.client.init({
        clientId: process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_KEY,
        scope: 'email',
      });
    }

    gapi.load('client:auth2', start);
  }, [])

  const defaultHandleLogin = (response: any) => {
    userStore.googleAuth(response.tokenId)
  }

  const defaultHandleError = (err: any) => {
    // pass
  }

  return (
    <Wrapper>
      <GoogleLogin
        buttonText={buttonText}
        clientId={process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_KEY}
        onSuccess={handleLogin || defaultHandleLogin}
        onFailure={handleError || defaultHandleError}
        responseType='id_token'
      />
    </Wrapper>
  )
}

export default GoogleAuthButton
  