import { observer } from 'mobx-react-lite'
import React, { useEffect } from 'react'
import ActionResponse from '../../../components/ActionResponse'
import AuthLayoutHeader from '../../../components/AuthLayoutHeader'
import Input from '../../../components/Input'
import LoginLink from '../../../components/LoginLink'
import SimpleForm from '../../../components/SimpleForm'
import { forgotPasswordStore } from '../../../stores/userStore'
import validators from '../../../utils/validators'


const ForgotPassword = () => {
  const onSubmitForm = (data: any) => {
    forgotPasswordStore.makeRequest(data, { useToastOnSuccess: true })
  }

  useEffect(() => {
    return () => {
      forgotPasswordStore.clear()
    }
  }, [])

  if (forgotPasswordStore.complete) {
    return (
      <>
        <ActionResponse 
          title='Success' 
          message='Check your email inbox (or spam folders) for your reset link.'
          variant='success'
        />
        <LoginLink />
      </>
    )
  }

  return (
    <>
      <AuthLayoutHeader
        title='Forgot your password' 
        subtitle='Initiate password reset'
      />
      <SimpleForm 
        formFields={forgotPasswordFields}
        submitForm={onSubmitForm}
        actionButtons={{
          label: 'Reset Password',
          loading: forgotPasswordStore.loading,
          isSubmit: true
        }}
      />
      <LoginLink />
    </>
  )
}

export default observer(ForgotPassword)

const forgotPasswordFields = [
  {
    name: 'email',
    label: 'Enter your Email or Username',
    component: Input,
    max_length: 50,
    validate: validators.required
  }
]

