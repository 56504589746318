import React, { FunctionComponent } from 'react'
import { getStyleSpacing } from '../../utils/helpers'
import { ComponentSpacing } from '../../utils/interfaces'
import { Wrapper } from './Box.styles'

interface IBox extends ComponentSpacing, React.HTMLAttributes<HTMLDivElement> {
  children?: React.ReactNode,
  alignCenter?: boolean,
}

const Box: FunctionComponent<IBox> = React.memo(({
  children,
  mt,
  mb,
  mr,
  ml,
  ...props
}) => {
  const spacingStyle = getStyleSpacing({mt, mb, mr, ml})

  return (
    <Wrapper style={spacingStyle} {...props}>
      {children}
    </Wrapper>
  )
})


export default Box
