import React, { FunctionComponent, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import CircularProgress from '@mui/material/CircularProgress';
import Button from '../../../components/Button'
import StoryThumbnail from '../../../components/StoryThumbnail'
import Text from '../../../components/Text'
import EmptyState from '../../../components/EmptyState'
import { Story } from '../../../utils/interfaces'
import uiStore from '../../../stores/uiStore'
import { myDraftsStore, myPublishedStoryStore, myStoryStore, storyStore } from '../../../stores/storyStore'
import { useNavigate } from 'react-router-dom'
import Box from '../../../components/Box';
import { colors } from '../../../styles/_var';

type StoryListProps = {
  makeRequest: (page?: number) => void,
  data: Story[] | null,
  showNextButton: boolean,
  noDataText: string,
  hasData: boolean,
  loading?: boolean
}

const StoryList: FunctionComponent<StoryListProps> = ({
  makeRequest,
  data,
  showNextButton,
  noDataText,
  hasData,
  loading
}) => {
  useEffect(() => {
    makeRequest(1)
  }, [makeRequest])

  const navigate = useNavigate()

  const onEdit = (storyId?: string) => () => {
    if (!storyId) return
    navigate(`/stories/${storyId}/edit`)
  }

  const onDelete = (storyId?: string) => () => {
    if (!storyId) return
    uiStore.openConfirmDialog({
      dialogTitle: 'Delete Confirmation',
      dialogBody: 'Are you sure you want to delete this story?',
      onConfirmAction: async () => {
        await storyStore.deleteEntity({ itemId: storyId })
        myPublishedStoryStore.removeItem(storyId)
        myDraftsStore.removeItem(storyId)
        myStoryStore.removeItem(storyId)
      }
    })
  }

  if (loading) {
    return (
      <Box alignCenter>
        <CircularProgress style={{color: colors.primary}} />
      </Box>
    )
  }

  return (
    <div>
      {data?.map((story, i) => (
        <StoryThumbnail
          mb={2}
          {...story}
          key={i}
          onEdit={onEdit(story.uuid)}
          onDelete={onDelete(story.uuid)}
        />
      ))}
      {showNextButton && (
        <Button
          alignCenter
          variant='secondary'
          onClick={() => makeRequest()}
        >
          <Text variant='regular'>Load More...</Text>
        </Button>
      )}
      {!hasData && <EmptyState message={noDataText} />}
    </div>
  )
}

export default observer(StoryList)
