import React from 'react'
import Text from '../../components/Text'
import { colors } from '../../styles/_var'
import Box from '../../components/Box'


const ContactUsInstructions = React.memo(() => {
  return (
    <>
      <Box mt={4}>
        <Text weight='bold' variant="medium">Support</Text>
        <Text variant="regular" color={colors.darkgrey}>Already a customer? If you are encountering a technical or payment issue, the customer support team will be happy to assist you.</Text>
      </Box>
      <Box mt={4}>
        <Text weight='bold' variant="medium">Report Abuse</Text>
        <Text variant="regular" color={colors.darkgrey}>
          The Matuskii team is responsible for this platform service and not the competitions being displayed on it. Please report any abuse or illegal competitions to us via this contact form and we will be in touch
        </Text>
      </Box>
    </>
  )
})

export default ContactUsInstructions
