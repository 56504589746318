import React, { FunctionComponent } from 'react'
import { ReactComponent as Home } from '../../assets/images/home.svg'
import { ReactComponent as Message } from '../../assets/images/message.svg'
import { ReactComponent as Story } from '../../assets/images/story.svg'
import { ReactComponent as Heart } from '../../assets/images/heart.svg'
import { ReactComponent as Notification } from '../../assets/images/notification.svg'
import { ReactComponent as HomeActive } from '../../assets/images/home-active.svg'
import { ReactComponent as MessageActive } from '../../assets/images/message-active.svg'
import { ReactComponent as StoryActive } from '../../assets/images/story-active.svg'
import { ReactComponent as HeartActive } from '../../assets/images/heart-active.svg'
import { ReactComponent as NotificationActive } from '../../assets/images/notification-active.svg'
import { routes } from '../../utils/constants'
import { Wrapper, MenuItemWrapper, IconLabel, StyledNavLink, UnreadMarker } from './Menuitems.styles'
import { useLocation } from 'react-router-dom'
import Box from '../Box'
import { chatRoomStore } from '../../stores/chatStore'
import { observer } from 'mobx-react-lite'
import { notificationsStore } from '../../stores/notificationStore'

type MenuItemProps = {
  Icon: React.FunctionComponent,
  ActiveIcon: React.FunctionComponent,
  label: string,
  route: string,
}

const MenuItem: FunctionComponent<MenuItemProps> = observer(({
  Icon,
  ActiveIcon,
  label,
  route
}) => {
  const { pathname } = useLocation()
  let hasUnreadIcon = false

  if (label === 'Messages') {
    hasUnreadIcon = chatRoomStore.hasUnread
  } else if (label === 'Notifications') {
    hasUnreadIcon = !!notificationsStore.hasUnread
  }
  

  return (
    <StyledNavLink to={route}>
      {({ isActive }) => {
        const useActiveIcon = pathname !== '/' && label === 'Home' ? false : isActive

        return (
          <MenuItemWrapper>
            <Box style={{ marginBottom: '0.5rem', position: 'relative' }}>
              {useActiveIcon ? <ActiveIcon /> : <Icon />}
              {hasUnreadIcon && <UnreadMarker />}
            </Box>
            <IconLabel variant='subtext' weight="bold">
              {label}
            </IconLabel>
          </MenuItemWrapper>
        )
      }}
    </StyledNavLink>
  )
})

const MenuItems = ({ showMessages }: { showMessages?: boolean}) => {
  const items: MenuItemProps[] = [
    {
      Icon: Home,
      ActiveIcon: HomeActive,
      label: 'Home',
      route: routes.DASHBOARD,
    },
    {
      Icon: Heart,
      ActiveIcon: HeartActive,
      label: 'My Partners',
      route: routes.MY_PARTNERS,
    },
    {
      Icon: Story,
      ActiveIcon: StoryActive,
      label: 'Stories',
      route: routes.STORIES,
    },
    {
      Icon: Notification,
      ActiveIcon: NotificationActive,
      label: 'Notifications',
      route: routes.NOTIFICATIONS,
    }
  ]

  if (showMessages) {
    items.push({
      Icon: Message,
      ActiveIcon: MessageActive,
      label: 'Messages',
      route: routes.MESSAGES,
    })
  }


  return (
    <Wrapper>
      {items.map((menu, i) => <MenuItem {...menu} key={i} />)}
    </Wrapper>
  )
}

export default MenuItems
