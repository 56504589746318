import styled from "styled-components"
import { colors, device, spacing } from "../../styles/_var"


export const RightWrapper = styled.div`
  text-align: left;
  width: 80%;
  max-width: 38.3rem;
`

export const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  
  @media ${device.md} {
    position: fixed;
    padding: 0;
  }
`

export const Content = styled.div`
  max-width: 26rem;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: scroll;
  min-height: 100%;
`

export const RightContent = styled(Content)`
  width: 100%;
  max-width: 100%;
`

export const Logo = styled.img`
  display: block;
  width: 15.2rem;
  height: auto;
  margin-bottom: ${spacing.sm};
`

export const AuthImage = styled.img`
  display: block;
  width: 20rem;
`

export const Left = styled.div`
  position: relative;
  box-sizing: border-box;
  width: 50%;
  height: 100%;
  background-color: ${colors.brown};
  display: none;

  @media ${device.md} {
    display: block;
  }
`

export const Right = styled.div`
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  display: block;
  overflow: auto;
  padding: ${spacing.sm} 0;

  @media ${device.lg} {
    width: 50%;
  }
`
  
export const AuthFooter = styled.div`
  position: fixed;
  width: 100%;
  bottom: 5px;
`