import Radio from "@mui/material/Radio";
import styled from "styled-components";
import { colors } from "../../styles/_var";


export const Wrapper = styled.div`

`

export const StyledRadio = styled(Radio)`
  &.MuiRadio-root {
    color: ${colors.primary};
    padding-right: 4px;
  }

  &.MuiRadio-root.Mui-checked {
    color: ${colors.primary};
  }
`