import { FormControlLabel, RadioGroup } from "@mui/material"
import React, { useState } from "react"
import { SelectChangeEvent } from '@mui/material/Select'
import { useNavigate } from 'react-router-dom'
import { colors } from "../../styles/_var"
import { capitalize } from "../../utils/helpers"
import Button from "../Button"
import Input from "../Input"
import Radio from "../Radio"
import Text from "../Text"
import { Wrapper, FormWrapper, StyledPhoneInput } from "./QuickBaerificationForm.styles"
import { routes } from "../../utils/constants"
import validators from "../../utils/validators"

const EMAIL = 'email'
const PHONE = 'phone'
const TWITTER = 'twitter'
const INSTAGRAM = 'instagram'

const types = [EMAIL, PHONE, TWITTER, INSTAGRAM]

const QuickBaerificationForm = React.memo(() => {
  const [searchBy, setSearchBy] = useState<string>(EMAIL)
  const [extension, setExtension] = useState('+1')
  const [value, setValue] = useState('')
  const [inputError, setInputError] = useState('')
  const navigate = useNavigate()

  const isValid = () => {
    if (searchBy === EMAIL && validators.isEmail(value)) {
      setInputError('Please enter a valid email')
      return false
    }

    if ((searchBy === INSTAGRAM || searchBy === TWITTER) && validators.isUsername(value)) {
      setInputError('Please enter a valid username');
      return false
    }

    return true
  }

  const onSubmit = () => {
    if (!value.trim()) return
    if (!isValid()) return

    const searchValue = searchBy === PHONE ? `${extension}-${value}` : value
    const url = `${routes.BAERFICACTION_RESULTS}?searchby=${searchBy}&value=${searchValue}`
    navigate(url)
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchBy(e.target.value)
  }

  const onExtensionChange = (e: SelectChangeEvent<any>) => {
    setExtension(e.target.value)
  }

  const onValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value)
  }

  const onKeypress = (e: React.KeyboardEvent<HTMLDivElement>) => {
    setInputError('')

    if (e.which === 13 || e.key === 'Enter') {
      onSubmit()
    }
  }

  const searchByPhone = searchBy === PHONE
  let searchLabel = `Enter Bae's ${searchBy}`
  if (searchBy === TWITTER || searchBy === INSTAGRAM) {
    searchLabel += ' username'
  }

  return (
    <Wrapper>
      <Text
        variant="regular"
        color={colors.grey}
        weight='bold'
      >
        Quick Baerfication
      </Text>
      <FormWrapper searchByPhone={searchByPhone}>
        {searchByPhone ? (
          <StyledPhoneInput
            onChange={onValueChange}
            extension={extension} 
            onExtensionChange={onExtensionChange}
          />
          ) : (
            <Input
              error={!!inputError}
              helperText={inputError}
              onChange={onValueChange}
              onKeyDown={onKeypress}
              value={value}
              variant="outlined"
              placeholder={searchLabel}
            />
          )}
        <Button ml={2} onClick={onSubmit} disabled={!value.trim()}>
          BAERIFY
        </Button>
      </FormWrapper>
      <RadioGroup value={searchBy} onChange={handleChange}>
        {types.map((type, i) => (
          <FormControlLabel
            key={i}
            value={type}
            control={<Radio />}
            label={capitalize(type)}
          />
        ))}
      </RadioGroup>
    </Wrapper>
  )
})

export default QuickBaerificationForm
