import React, { FunctionComponent, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'
import { TextFieldProps } from '@mui/material/TextField'
import { getStyleSpacing } from '../../utils/helpers'
import { ComponentSpacing } from '../../utils/interfaces'
import { InputWrapper, StyledInput } from './Input.styles'

type InputProps = TextFieldProps & ComponentSpacing

const Input:FunctionComponent<InputProps> = React.forwardRef((props, ref) => {
  const {
    mt,
    mb,
    mr,
    ml,
    style={},
    variant='standard',
    placeholder,
    ...rest
  } = props
  const [showPassword, setShowPassword] = useState(false)

  const spacingComponents = { mr, ml, mb, mt }
  const spacingStyle = getStyleSpacing(spacingComponents)
  const componentStyle = {...spacingStyle, ...style}

  const isPassword = props.type === 'password'

  const onClickPasswordToggle = () => {
    setShowPassword(!showPassword)
  }


  if (isPassword) {
    rest.type = showPassword ? 'text' : 'password'
    rest.InputProps = {
      endAdornment: passwordAdornment(showPassword, onClickPasswordToggle)
    }
  }

  return (
    <InputWrapper className={props.className || ''} style={componentStyle}>
      <StyledInput 
        ref={ref}
        variant={variant} 
        placeholder={placeholder}
        size="medium"
        {...rest}
      />
    </InputWrapper>
  )
})

const passwordAdornment = (showPassword: boolean, onClick: () => void) => (
  <FontAwesomeIcon
    onClick={onClick}
    icon={showPassword ? faEyeSlash : faEye}
    style={{cursor: 'pointer'}} />
)


export default Input
