import styled from "styled-components";
import { spacing } from "../../styles/_var";

export const Wrapper = styled.div`
  margin-top: ${spacing.xxs};
  display: flex;

  & input.MuiInputBase-input {
    padding-bottom: 2px;
  }
`
