import React, { FunctionComponent, ReactNode } from "react"
import { IconProp, SizeProp } from "@fortawesome/fontawesome-svg-core"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Text, { ITextProps } from "../Text/Text"
import { Wrapper } from './TextWithIcon.styles'
import { getStyleSpacing } from "../../utils/helpers"

interface TextWithIconProps extends ITextProps {
  icon: IconProp | ReactNode,
  iconSize?: SizeProp,
  children: any,
}

const TextWithIcon: FunctionComponent<TextWithIconProps> = ({ 
  icon,
  mt,
  mb,
  mr,
  ml,
  iconSize='1x',
  className,
  onClick,
  maxWidth,
  ...rest
}) => {
  const spacingComponents = { mr, ml, mb, mt }
  const spacingStyle = getStyleSpacing(spacingComponents)
  let iconElement;

  if (React.isValidElement(icon)) {
    iconElement = icon
  } else {
    iconElement = <FontAwesomeIcon size={iconSize} icon={icon as IconProp} color={rest.color} />
  }


  return (
    <Wrapper
      className={className}
      center={rest.center}
      style={{...spacingStyle, maxWidth: maxWidth, ...(rest.style || {})}}
      onClick={onClick}
    >
      {iconElement}
      <Text {...rest} />
    </Wrapper>
  )
}

export default TextWithIcon
