import React, { useEffect, useState } from 'react'
import { Outlet } from 'react-router-dom'
import { useNavigate } from "react-router-dom";
import logo from '../../assets/images/logo2.png'
import { chatRoomStore } from '../../stores/chatStore'
import { fetchNotificationStore } from '../../stores/notificationSettingsStore'
import { notificationsStore } from '../../stores/notificationStore'
import { creditCardStore } from '../../stores/paymentStore'
import CircularProgress from '@mui/material/CircularProgress';
import { userStore, fetchTodaysStatsStore } from '../../stores/userStore'
import Auth from '../../utils/auth'
import { routes } from '../../utils/constants'
import MainDashboardLayout from '../MainDashboardLayout'
import { Hub } from '@aws-amplify/core'
import * as gen from '../../utils/generated'
import websocketHandler from '../../utils/websocketHandler'
import { isDev } from '../../utils/helpers'
import { partnerStore, recentBaerifications } from '../../stores/partnerStore'
import { CONNECTION_STATE_CHANGE, ConnectionState } from '@aws-amplify/pubsub';
import { colors } from '../../styles/_var'
import Text from '../../components/Text'
import CenteredView from '../../components/CenteredView'
import { Logo } from '../MainAuthLayout/MainAuthLayout.styles'
import { datadogLogs } from '@datadog/browser-logs'
import chatConfig from '../../utils/chatConfig';


chatConfig.configure()

type DLState = {
  dashboardReady: boolean | null
}

type DLProps = {
  router: any,
}

const RETRY_NOTIFICATION_TIME = 300000


class DashboardLayout extends React.Component<DLProps, DLState> {
  private interval: NodeJS.Timer | undefined
  private reconnectionInterval: NodeJS.Timer | undefined
  private username: string | null
  private hubListener: undefined | (() => void) = undefined

  constructor(props: any) {
    super(props)
    this.state = { dashboardReady: null }
    this.username = Auth.fetchUsername() 
  }

  async componentDidMount() {
    if (!this.username) return

    this.setState({ dashboardReady: false })
    await userStore.fetchUserDetails(this.username)
    if (!userStore.userInfoLoaded) return

    if (!userStore.isActivated) {
      window.location.href = routes.ACTIVATE_USER
    } else {
      this.setState({ dashboardReady: true })
      this.appDataPrefetch()
      this.fetchNotificationsOnInterval()
      this.initializeWebsocketSubscription(this.username)
      this.monitorWebsocketSubscription()
      this.initializeDataDogLogs()
    }
  }

  componentWillUnmount() {
    clearInterval(this.interval)
    clearInterval(this.reconnectionInterval)

    // this.stopMonitoringWebsocketSubscription()
    datadogLogs.clearUser()
  }

  fetchNotificationsOnInterval() {
    this.interval = setInterval(() => {
      if (!userStore.userInfoLoaded) return
      notificationsStore.makeRequest(undefined, { page: 1 })
    }, RETRY_NOTIFICATION_TIME)
  }

  appDataPrefetch() {
    fetchNotificationStore.makeRequest()
    creditCardStore.listEntity()
    notificationsStore.makeRequest(undefined, { page: 1 })
    chatRoomStore.loadChatRooms()
    partnerStore.listEntity()
    recentBaerifications.makeRequest()
    fetchTodaysStatsStore.makeRequest()
  }

  initializeDataDogLogs() {
    if (isDev) return
    datadogLogs.setUser({ username: userStore.username })
    datadogLogs.addLoggerGlobalContext('service', 'baerify_client')
    datadogLogs.addLoggerGlobalContext('host', window.location.hostname)
    datadogLogs.addLoggerGlobalContext('environment', process.env.REACT_APP_NODE_ENV)
  }

  connectToWebsocket(channel: string) {
    const { connectionState, RETRY_STATES } = chatConfig
    if (connectionState && !RETRY_STATES.includes(connectionState)) return

    console.log('connection to websocket has begun')

    chatConfig.subscriptionObject = gen.subscribe(channel, payload => {
      websocketHandler(payload)
    }, (error: any) => {
      console.log('error:: ', error)
      this.handleWebsocketSubscriptionError()
    })
  }

  handleWebsocketSubscriptionError() {
    chatConfig.retryTime = chatConfig.retryTime * 5

    setTimeout(() => {
      if (!this.username) return
      console.log(`reconnecting in ${chatConfig.retryTime} seconds`)
      this.connectToWebsocket(this.username)
    }, chatConfig.retryTime)
  }

  initializeWebsocketSubscription(channel: string) {
    if (chatConfig.subscriptionObject) return
    this.connectToWebsocket(channel)
  }

  monitorWebsocketSubscription() {
    this.hubListener = Hub.listen('api', (data: any) => {
      const { payload } = data;
      if (payload.event === CONNECTION_STATE_CHANGE) {
        chatConfig.connectionState = payload.data.connectionState as ConnectionState;

        if (chatConfig.connectionState === 'Connected') {
          chatConfig.retryTime = chatConfig.DEFAULT_RETRY_TIME
        } else if (chatConfig.RETRY_STATES.includes(chatConfig.connectionState)) {
          this.handleWebsocketSubscriptionError()
        }
        console.log(chatConfig.connectionState)
      }
    });
  }

  stopMonitoringWebsocketSubscription() {
    if (this.hubListener) this.hubListener()
  }

  render() {
    if (this.state.dashboardReady ===  null) return null

    if (this.state.dashboardReady === false) return <PreparingDashboard />

    return (
      <MainDashboardLayout>
        <Outlet />
      </MainDashboardLayout>
    )
  }
}

const PreparingDashboard = React.memo(() => (
  <CenteredView>
    <Logo src={logo} alt="site-logo" style={{marginRight: 'auto', marginLeft: 'auto'}}  />
    <CircularProgress style={{color: colors.primary, textAlign: 'center'}} />
    <Text variant='medium' color={colors.darkgrey}>Preparing Dashboard...</Text>
  </CenteredView>
))

const beforeLoadDashboard = (Component: any) =>  {
  function ComponentWithRouterProp(props: any) {
    let navigate = useNavigate();
    const [ready, setReady] = useState(false)

    useEffect(() => {
      const authenticationCheck = () => {
        if (!Auth.isLoggedIn()) {
          Auth.logUserOut()
          navigate(routes.LOGIN)
        } else {
          setReady(true)
        }
      }
      authenticationCheck()
    }, [navigate])

    if (!ready) return null

    return (
      <Component {...props} />
    );
  }
  return ComponentWithRouterProp;
}


export default beforeLoadDashboard(DashboardLayout)
