import React, { FunctionComponent, useState } from "react"
import MuiImageList from '@mui/material/ImageList';
import ImageViewer from 'react-simple-image-viewer';
import ImageListItem from '@mui/material/ImageListItem';
import IconButton from '@mui/material/IconButton';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import Text from "../Text";
import { Wrapper } from "./ImageList.styles";
import { getThumbnailUrl } from "../../utils/helpers";


export type ImageType = {
  id?: number
  picture: string,
  caption: string
}

type ImageListProps = {
  photos: ImageType[],
  isLoadingIfNoId?: boolean,
  variant?: "masonry" | "quilted" | "standard" | "woven",
  actionIcon?: React.ReactNode,
  onActionClick?: (item: ImageType) => void,
  onImageClick?: (item: ImageType, index: number) => void,
}

const ImageList: FunctionComponent<ImageListProps> = ({
  photos,
  isLoadingIfNoId,
  variant='standard',
  actionIcon,
  onActionClick,
  onImageClick
}) => {
  const [currImage, setCurrImage] = useState<any>({})
  const [viewerIsOpen, setViewerIsOpen] = useState(false)
  const imagesForViewer = photos.map(photo => photo.picture)

  return (
    <Wrapper>
      <MuiImageList variant={variant}>
        {photos.map((photo, i) => (
          <ImageListItem key={photo.id || photo.picture}>
            <img
              style={{opacity: isLoadingIfNoId ? (photo.id ? 1 : 0.4) : 1, cursor: 'pointer'}}
              src={getThumbnailUrl(photo.picture)}
              alt={photo.caption}
              width='40%'
              height={50}
              loading="lazy"
              onError={e => e.currentTarget.src = photo.picture}
              onClick={() => {
                onImageClick && onImageClick(photo, i)
                setCurrImage(i)
                setViewerIsOpen(true)
              }}
            />
            <ImageListItemBar
              style={{padding: '2px 10px'}}
              subtitle={<Text variant='regular'>{photo.caption}</Text>} 
              actionIcon={(!!actionIcon && !!onActionClick) ? (
                <IconButton
                  onClick={() => onActionClick(photo)}
                  sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                  aria-label='info about photo'
                >
                  {actionIcon}
                </IconButton>
              ) : undefined
              }
            />
          </ImageListItem>
        ))}
      </MuiImageList>
      {viewerIsOpen && <ImageViewer
        src={imagesForViewer}
        currentIndex={currImage}
        onClose={() => setViewerIsOpen(false)}
        closeOnClickOutside
      />}
    </Wrapper>
  )
}

export default ImageList
