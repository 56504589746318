import React from 'react'
import DOMPurify from 'dompurify'
import parse from 'html-react-parser'
import BackButton from '../../../components/BackButton'
import CircularProgress from '@mui/material/CircularProgress';
import { Story } from '../../../utils/interfaces'
import DashboardWithSidebar from '../../../layouts/DashboardWithSidebar'
import { Content, StoryThumbnail, ThumbnailWrapper } from './Stories.styles'
import StoryInfo from './StoryInfo';
import StoryHeader from './StoryHeader'
import CommentSection from '../../../components/CommentSection'
import { storyCommentStore } from '../../../stores/storyCommentStore'
import { observer } from 'mobx-react-lite'
import { storyStore } from '../../../stores/storyStore'
import { CommentType } from '../../../components/Comment'
import { apiRoutes, getPaginationDefaults } from '../../../utils/constants'
import { useParams, useSearchParams } from 'react-router-dom'
import { notificationsStore } from '../../../stores/notificationStore'
import { colors } from '../../../styles/_var';
import Box from '../../../components/Box';

const getUrl = (id: string='') => {
  if (!id) return
  return apiRoutes.STORY_COMMENTS.replace(':storyId', id)
}

type ViewStoryProps = {
  storyId: string,
  searchParams?: URLSearchParams,
  comments: CommentType[] | [],
  loading: boolean,
  story: Story | null
}

type ViewStoryState = {}

class ViewStory extends React.Component<ViewStoryProps, ViewStoryState> {
  async componentDidMount() {
    await this.retrieveStory()
    await this.fetchComments(1)
    this.fetchCommentFromNotification()
  }

  componentWillUnmount() {
    storyCommentStore.clearData()
    storyStore.setItem(null)
  }

  async retrieveStory() {
    const storyId = this.props.storyId
    if (!storyId) return
    storyStore.retrieve(storyId)
  }

  async fetchComments(page?: number) {
    const storyId = this.props.storyId
    if (!storyId) return

    await storyCommentStore.listEntity({ 
      url: getUrl(storyId),
      page: page
    })
  }

  fetchCommentFromNotification() {
    if (!this.props.searchParams) return
    const commentId = this.props.searchParams?.get('commentId')
    if (!commentId) return
    const comment = notificationsStore.getComment(commentId)
    if (!comment) return


    if (comment.replying) {
      storyCommentStore.retrieveEntity({ 
        itemId: comment.replying,
        url: apiRoutes.STORY_COMMENTS.replace(':storyId', this.props.storyId),
        onRetrieveItem(data) {
          data.replies = {
            comments: [comment],
            pagination_info: getPaginationDefaults()
          }
          storyCommentStore.deleteFromList(data.id)
          storyCommentStore.addToList(data, '-')
        }
      })
    } else {
      storyCommentStore.deleteFromList(comment.id)
      storyCommentStore.addToList(comment, '-')
    }
  }

  likeComment(comment: CommentType, originalComment?: CommentType) {
    const story = this.props.story
    if (!story?.uuid) return    
    storyCommentStore.toggleCommentLike(story.uuid, {...comment, liked: !comment.liked}, originalComment)
  }

  loadReplies(comment: CommentType) {
    const storyId = this.props.story?.uuid
    if (!storyId) return
    storyCommentStore.fetchReplies(storyId, comment)
  }

  async deleteReply(comment: CommentType, replyId: number){
    const storyId = this.props.story?.uuid
    if (!storyId) return

    await storyCommentStore.deleteEntity({
      url: getUrl(storyId),
      itemId: replyId,
      shouldRemoveFromList: false,
    })

    const newReplies = comment.replies?.comments.filter(e => e.id !== replyId)
    storyCommentStore.updateInList({
      ...comment,
      replies: {
        pagination_info: comment.replies?.pagination_info || getPaginationDefaults(),
        comments: newReplies || []
      }
    })
  }

  likeStory() {
    const story = this.props.story
    if (!story) return

    storyStore.toggleLike({...story, liked: !story.liked})
  }

  async postComment(comment: string, originalComment: CommentType | undefined = undefined) {
    const storyId = this.props.story?.uuid
    if (!storyId) return

    const payload: any = { content: comment, replying: originalComment?.id }
    await storyCommentStore.createEntity(payload, {
      url: getUrl(storyId),
      shouldAddToList: !originalComment,
      onCreatedItem: (createdItem) => {
        if (!originalComment) return

        const newReplies = [createdItem, ...(originalComment.replies?.comments || [])]
        storyCommentStore.updateInList({
          ...originalComment,
          num_of_replies: (originalComment.num_of_replies || 0) + 1,
          replies: {
            pagination_info: originalComment.replies?.pagination_info || getPaginationDefaults(),
            comments: newReplies || []
          }
        })
      }
    })
  }

  async deleteComment(commentId: number) {
    const storyId = this.props.story?.uuid
    if (!storyId) return

    await storyCommentStore.deleteEntity({ url: getUrl(storyId), itemId: commentId })
  }

  render() {
    return (
      <DashboardWithSidebar>
        <BackButton />
        {this.props.loading && <Box alignCenter mt={2}><CircularProgress style={{color: colors.primary}} /></Box>}
        {(!this.props.loading && this.props.story) && (
          <>
            <StoryHeader owner={this.props.story.owner} type={this.props.story.type} title={this.props.story.title} />
            <ThumbnailWrapper>
              <StoryThumbnail src={this.props.story.thumbnail_image} />
            </ThumbnailWrapper>
            <Content>{parse(DOMPurify.sanitize(this.props.story.content))}</Content>
            <StoryInfo
              likeStory={() => this.likeStory()}
              liked={!!this.props.story.liked}
              comments={this.props.story.comments || 0}
              likes={this.props.story.likes || 0} 
            />
            <CommentSection
              deleteReply={this.deleteReply.bind(this)}
              loadReplies={this.loadReplies.bind(this)}
              likeComment={this.likeComment.bind(this)}
              postComment={(comment, originalComment) => this.postComment(comment, originalComment)}
              comments={this.props.comments}
              hasMoreComments={storyCommentStore.hasNext}
              loadMoreComments={this.fetchComments.bind(this)}
              fetching={storyCommentStore.fetchingList}
              deleteComment={commentId => this.deleteComment(commentId)}
            />
          </>
        )}
      </DashboardWithSidebar>
    )
  }
}

const ViewStoryWrapper = observer(() => {
  const { storyId } = useParams()
  const [searchParams] = useSearchParams()
  if (!storyId) return null

  return (
    <ViewStory
      story={storyStore.item}
      loading={storyStore.fetching}
      searchParams={searchParams}
      storyId={storyId}
      comments={storyCommentStore.list}
    />
  )
})

export default ViewStoryWrapper