import { action, makeObservable } from "mobx";
import { apiRoutes } from "../utils/constants";
import { fetch } from "../utils/helpers";
import { Publication, Story } from "../utils/interfaces";
import EntityStore, { Config as EntityConfig, defaultConfig as entityDefaultConfig} from "./entityStore";
import FieldStore, { Config, defaultConfig } from "./fieldStore";

class PublishedStoryStore extends FieldStore<Publication[]> {
  config: Config<any> = {
    ...defaultConfig,
    url: apiRoutes.PUBLICATIONS,
    isPaginated: true
  }
}

class MyStoryStore extends FieldStore<Story[]> {
  config: Config<any> = {
    ...defaultConfig,
    url: apiRoutes.MY_STORIES,
    isPaginated: true
  }
}

class MyPublishedStoryStore extends FieldStore<Story[]> {
  config: Config<any> = {
    ...defaultConfig,
    url: apiRoutes.MY_PUBLISHED_STORIES,
    isPaginated: true
  }
}

class MyDraftsStore extends FieldStore<Story[]> {
  config: Config<any> = {
    ...defaultConfig,
    url: apiRoutes.MY_DRAFTS,
    isPaginated: true
  }
}

export const publishedStoryStore = new PublishedStoryStore()
export const myStoryStore = new MyStoryStore()
export const myPublishedStoryStore = new MyPublishedStoryStore()
export const myDraftsStore = new MyDraftsStore()

class StoryStore extends EntityStore<Story> {
  config: EntityConfig = {
    ...entityDefaultConfig,
    url: apiRoutes.STORIES,
    idKey: 'uuid',
    shouldUpdateInList: false
  }

  constructor() {
    super()
    makeObservable(this, {
      retrieve: action,
      toggleLike: action
    })
  }

  async retrieve(storyId: string) {
    let story = myDraftsStore.getItem(storyId, 'uuid')
    if (story) {
      this.setItem(story)
      return story
    }
  
    story = myStoryStore.getItem(storyId, 'uuid')
    if (story) {
      this.setItem(story)
      return story
    }
  
    await this.retrieveEntity({ itemId: storyId })
    return this.item
  }

  async toggleLike(story: Story) {
    const url = `${this.config.url}/${story.uuid}/toggle_like`
    fetch({ url, method: 'POST' })

    myDraftsStore.updateItem(story, 'uuid')
    myStoryStore.updateItem(story, 'uuid')
    this.updateInList(story)
    this.setItem(story)
  }
}

export const storyStore = new StoryStore()