import React, { FunctionComponent } from 'react'
import Box from '../../../components/Box'
import { ChatRoomHeader } from './Messages.styles'
import Avatar from '@mui/material/Avatar'
import Text from '../../../components/Text'
import Popover from '@mui/material/Popover';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faEllipsisVertical } from '@fortawesome/free-solid-svg-icons'
import ChatMessageOptions from './ChatMessageOptions'
import { ParticipantType } from '../../../utils/interfaces'
import TextWithIcon from '../../../components/TextWithIcon'
import useIsMobile from '../../../hooks/useIsMobile'


type ChatConversationHeaderProps = {
  participant?: ParticipantType, 
  onDelete: () => void,
  goBack: () => void,
}

const ChatConversationHeader: FunctionComponent<ChatConversationHeaderProps> = React.memo(({
  participant,
  onDelete,
  goBack
}) => {
  const isMobile = useIsMobile()
  const [anchorEl, setAnchorEl] = React.useState<SVGSVGElement | null>(null);
  const handleClick = (event: React.MouseEvent<SVGSVGElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const onClickDelete = () => {
    onDelete()
    handleClose()
  }

  if (!participant) return <ChatRoomHeader />

  return (
    <ChatRoomHeader>
      <Box style={{display: 'flex', alignItems: 'center'}}>
        {isMobile ? (
          <TextWithIcon
            onClick={goBack}
            iconSize='1x'
            icon={faChevronLeft}
            variant='medium'
            weight='bold'
            link
          >
            {participant.user.first_name} {participant.user.last_name}
          </TextWithIcon>
        ) : (
          <>
            <Avatar
              src={participant.user.avatar}
              sx={{
                borderRadius: '10px',
                width: 40,
                height: 40,
                marginRight: 1,
              }}
            >
              <Text variant='medium' weight='bold'></Text>
            </Avatar>
            <Text variant='medium' weight='bold' ml={2}>
              {participant.user.first_name} {participant.user.last_name}
            </Text> 
          </>
        )}
      </Box>
      <div style={{display: 'flex', alignItems: 'center'}}>
        {isMobile && (
          <Avatar
            src={participant.user.avatar}
            sx={{
              borderRadius: '10px',
              width: 40,
              height: 40,
              marginRight: 1,
            }}
          >
            <Text variant='medium' weight='bold'>
              {participant.user.username ? participant.user.username[0].toUpperCase() : 'Un'}
            </Text>
          </Avatar>
        )}
        <FontAwesomeIcon
          onClick={handleClick}
          icon={faEllipsisVertical}
          size="2x"
        />
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <ChatMessageOptions onDelete={onClickDelete} />
        </Popover>
      </div>
    </ChatRoomHeader>
  )
})

export default ChatConversationHeader