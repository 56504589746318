import styled from "styled-components";
import { colors, spacing } from "../../styles/_var";

export const Wrapper = styled.div`
`

export const ListItem = styled(({ selectable, ...rest }) => <div {...rest} />)`
  padding: ${spacing.xs} 0;
  border-bottom: solid 2px ${colors.lightgrey};
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: all ease .2s;
  
  ${({ selectable }) => selectable && `
    cursor: pointer;
    &:hover {
      opacity: .7;
      p {
        font-weight: bold;
      }
    }
  `}

  & div:first-child, p:first-child {
    flex: 1;
  }

  &:last-child {
    border-bottom: none; 
  }
`