import React from 'react'
import { useParams } from 'react-router-dom'
import Link from '../../../components/Link'
import Text from '../../../components/Text'
import TextWithIcon from '../../../components/TextWithIcon'
import useGetPartner from '../../../hooks/useGetPartner'
import DashboardWithSidebar from '../../../layouts/DashboardWithSidebar'
import { partnerStore } from '../../../stores/partnerStore'
import { colors, spacing } from '../../../styles/_var'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { PartnerItemHeader } from './Partners.styles'
import PartnerForm from './PartnerForm'
import { routes } from '../../../utils/constants'
import { observer } from 'mobx-react-lite'

const EditPartner = () => {
  const { partnerId } = useParams()
  useGetPartner(partnerId)

  if (!partnerId) return null
  const partner = partnerStore.item
  if (!partner) return null

  const [extension, phone] = partner?.phone.split('-')

  return (
    <DashboardWithSidebar>
      <PartnerItemHeader style={{marginBottom: spacing.xs}}>
        <Text variant='medium' weight='bold'>
          Edit Partner
        </Text>
        <Link to={routes.MY_PARTNERS}>
          <TextWithIcon
            variant='regular'
            icon={faArrowLeft}
            link 
            color={colors.primary}
          >
            Back to partners
          </TextWithIcon>
        </Link>
      </PartnerItemHeader>
      <PartnerForm
        extension={extension} 
        partner={{...partner, phone}}
      />
    </DashboardWithSidebar>
  )
}

export default observer(EditPartner)