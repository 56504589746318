import React from 'react'
import BaerifierImageIcon from '../../components/BaerifierImageIcon'
import Link from '../../components/Link'
import Text from '../../components/Text'
import { colors } from '../../styles/_var'
import { routes } from '../../utils/constants'
import { BannerMessage, BannerWrapper } from './LandingPage.styles'

const LandingPageBanner = React.memo(() => {
  return (
    <BannerWrapper>
      <BannerMessage>
        <Text variant='large' weight='bold' color={colors.primary}>
          Is Your Bae really your Bae?
        </Text>
        <Text variant='medium' maxWidth={360} mt={2} mb={2}>
          Verify your Bae. Read and Share juicy infidelity stories with the world.
        </Text>
        <Link useButton to={routes.REGISTER}>Get Started</Link>
        <BaerifierImageIcon mt ={4} style={{transform: 'scale(1)'}} />
      </BannerMessage>
    </BannerWrapper>
  )
})

export default LandingPageBanner
