import styled from "styled-components";
import { colors, spacing } from "../../styles/_var";
import Avatar from "@mui/material/Avatar"
import Button from "../Button";

export const Wrapper = styled.div`
  width: 100%;
  max-width: 37rem;
  min-width: 37rem;
  margin-bottom: -3rem;
`

export const ProfileHeader = styled.div`
  background: linear-gradient(180deg, #D77A61 0%, #381D2A 100%);
  padding: ${spacing.xs};
  height: 11rem;
`

export const UserAvatar = styled(Avatar)`
  position: relative;
  transform: translateY(-50%);
  border: solid 4px ${colors.white};
  margin-left: auto;
  margin-right: auto;
`

export const ProfileBody = styled.div`
  position: relative;
  top: -6rem;
  height: 32rem;
`

export const EditProfileButton = styled(Button)`
  position: relative;
`

export const FormWrapper = styled.div`
  padding: ${spacing.xs};
  position: relative;
  top: -4rem;
`