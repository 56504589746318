import React, { FunctionComponent } from 'react'
import Popover from '@mui/material/Popover';
import { LikeButton, StoryInfoSection } from './Stories.styles';
import TextWithIcon from '../../../components/TextWithIcon'
import { faCommentDots, faHeart as EmptyHeart} from '@fortawesome/free-regular-svg-icons'
import { ReactComponent as Share } from '../../../assets/images/share.svg'
import ShareButtons from '../../../components/ShareButtons';
import { faHeart } from '@fortawesome/free-solid-svg-icons';

type StoryInfoProps = {
  comments: number,
  likes: number,
  liked: boolean,
  likeStory: () => void,
}

const StoryInfo: FunctionComponent<StoryInfoProps> = React.memo(({
  comments,
  likes,
  liked,
  likeStory
}) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLParagraphElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLParagraphElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  

  return (
    <StoryInfoSection>
      <LikeButton
        iconSize='2x'
        variant='regular'
        onClick={likeStory}
        icon={liked ? faHeart : EmptyHeart}
      >
        {likes} Likes
      </LikeButton>
      <TextWithIcon icon={faCommentDots} variant='regular' iconSize='2x'>
        {comments} comments
      </TextWithIcon>
      <TextWithIcon onClick={handleClick} icon={<Share />} variant='regular' iconSize='2x'>
        Share
      </TextWithIcon>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <ShareButtons />
      </Popover>
    </StoryInfoSection>
  )
})

export default StoryInfo
