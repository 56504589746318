import styled from "styled-components";

export const Wrapper = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
`

export const Container = styled.div`
  position: relative;
  text-align: center;
  top: 50%;
  transform: translateY(-50%);
`