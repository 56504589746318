import { action, makeObservable, observable } from "mobx";
import { IBaerification } from "../components/RightSideComponents/RightSideComponents";
import { apiRoutes, AWS_BASE_URL, methods, PARTNER_EVIDENCE_FOLDER } from "../utils/constants";
import { uploadToS3 } from "../utils/helpers";
import { BlockedUserType, IConflict, PartnerEvidenceType, PartnerType } from "../utils/interfaces";
import EntityStore, { Config as EntityConfig, defaultConfig as entityDefaultConfig } from "./entityStore";
import FieldStore, { Config, defaultConfig } from "./fieldStore";
import uiStore from "./uiStore";
import ImageCropper from "../utils/imageCropper";

class BaerificationStore extends FieldStore<any> {
  config: Config<any> = {
    ...defaultConfig,
    url: apiRoutes.BAERIFY,
    method: methods.POST
  }
}

class RecentBaerifications extends FieldStore<IBaerification[]> {
  config: Config<any> = {
    ...defaultConfig,
    url: apiRoutes.RECENT_BAERIFICATION,
    method: methods.GET
  }
}

class PartnerStore extends EntityStore<PartnerType> {
  config: EntityConfig = {
    ...entityDefaultConfig,
    url: apiRoutes.PARTNERS,
    idKey: 'uuid'
  }

  deletePartner(partnerId: string, onComplete?: () => void) {
    uiStore.openConfirmDialog({
      dialogTitle: 'Delete confirm',
      dialogBody: 'Are you sure you want to delete this partner',
      onConfirmAction: () => {
        this.deleteEntity({
          itemId: partnerId,
          onDeletedItem: () => {
            if (onComplete) onComplete()
          }
        })
      }
    })
  }

  addOrUpdate(partnerPayload: PartnerType, partnerId: string | undefined, onComplete: (data: any) => void) {
    if (partnerId) {
      partnerStore.updateEntity(partnerPayload, {
        shouldUseToast: false,
        url: `${apiRoutes.PARTNERS}/${partnerId}`,
        onUpdatedItem: (data) => {
          uiStore.showSuccessMessage('Your partner has been updated successfully')
          onComplete(data)
        }
      })
    } else {
      partnerStore.createEntity(partnerPayload, {
        onCreatedItem: (data) => {
          uiStore.showSuccessMessage('Your partner has been added successfully')
          onComplete(data)
        }
      })
    }
  }
}

class PictureEvidenceStore extends EntityStore<PartnerEvidenceType> {
  config: EntityConfig = {
    ...entityDefaultConfig,
    url: apiRoutes.PARTNERS
  }

  async saveEvidence(partnerId: string, picture: string, caption?: string) {
    uiStore.closeDialog()
    const tempEvidence: PartnerEvidenceType = {picture, caption, uuid: Date.now()}
    this.addToList(tempEvidence)

    const fileKey = `${PARTNER_EVIDENCE_FOLDER}${partnerId}_${Math.floor(Date.now() / 1000)}`
    const filename = `${fileKey}.jpg`

    const thumbnailImage = await ImageCropper.createImage(picture)
    const thumbnailUrl = await ImageCropper.resizeImage(thumbnailImage, 250)
    const thumbnailFilename = `${fileKey}-small.jpg`

    await Promise.all([
      uploadToS3({ filename, fileUrl: picture }),
      thumbnailUrl ? uploadToS3({ filename: thumbnailFilename, fileUrl: URL.createObjectURL(thumbnailUrl) }) : undefined
    ])

    const pictureUrl = `${AWS_BASE_URL}/${filename}`
    this.createEntity({ picture: pictureUrl, caption }, {
      url: `${apiRoutes.PARTNERS}/${partnerId}/picture_evidence`,
      itemId: partnerId,
      shouldAddToList: false,
      onCreatedItem: data => {
        this.updateInListBy('uuid', tempEvidence.uuid, data)
        uiStore.showSuccessMessage('Your photo has been added.')
        const partner = partnerStore.getFromList('uuid', partnerId)

        if (partner?.image) return
        const updatedPartner = {...partner, image: data.picture}
        partnerStore.updateInList(updatedPartner)
      }
    })
  }

  async removeEvidence(partnerId: string, evidence: PartnerEvidenceType) {
    uiStore.openConfirmDialog({
      dialogBody: 'Are you sure you want to delete this evidence?',
      dialogTitle: 'Delete?',
      onConfirmAction: () => {
        const newEvidence = {...evidence, id: undefined, uuid: Date.now()}
        this.updateInListBy('id', evidence.id, newEvidence)
    
        this.deleteEntity({
          url: `${apiRoutes.PARTNERS}/${partnerId}/picture_evidence/${evidence.id}`,
          shouldRemoveFromList: false,
          onDeletedItem: () => {
            this.deleteFromListBy('uuid', newEvidence.uuid)
          }
        })
      }
    })
  }
}

class PartnerConflictStore extends FieldStore<IConflict[]> {
  selectedConflict: IConflict | null = null
  config: Config<any> = {
    ...defaultConfig,
    method: methods.GET
  }
  
  constructor() {
    super()
    makeObservable(this, {
      selectedConflict: observable,
      unsetSelectedConflict: action,
      setSelectedConflict: action
    })
  }

  unsetSelectedConflict() {
    this.selectedConflict = null
  }

  setSelectedConflict(conflict: IConflict) {
    this.selectedConflict = conflict
  }
}

class BlockListStore extends EntityStore<BlockedUserType, {username: string}> {
  addToBlockList(partnerId: string, blockedUser: string, conflictId: number) {
    uiStore.openConfirmDialog({
      dialogTitle: 'Are you sure?',
      dialogBody: 'Are you sure you\'d like to ignore this user?',
      onConfirmAction: () => {
        this.createEntity({username: blockedUser}, {
          url: `${apiRoutes.PARTNERS}/${partnerId}/blocklist`,
          onCreatedItem: _ => {
            uiStore.closeDialog()
            partnerConflictStore.removeItem(conflictId, 'id')
            uiStore.showSuccessMessage('User has been added to the block list')
          }
        })
      }
    })
  }

  removeFromBlockList(partnerId: string, blockedInstanceId: number, onComplete?: any) {
    this.deleteEntity({
      url: `${apiRoutes.PARTNERS}/${partnerId}/blocklist/${blockedInstanceId}`,
      onDeletedItem: onComplete()
    })
  }
}

export const baerificationStore = new BaerificationStore()
export const recentBaerifications = new RecentBaerifications()
export const partnerStore = new PartnerStore()
export const pictureEvidenceStore = new PictureEvidenceStore()
export const partnerConflictStore = new PartnerConflictStore()
export const blocklistStore = new BlockListStore()