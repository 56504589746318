import React, { FunctionComponent } from 'react'
import { faEnvelope } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import MenuItems from '../MenuItems'
import UserAvatarButton from '../UserAvatarButton'
import { MenuItemsWrapper, ActionSection } from './Navbar.styles'
import useIsMobile from '../../hooks/useIsMobile'
import NavbarShell from '../NavbarShell'
import Box from '@mui/material/Box'
import { Link } from 'react-router-dom'
import { routes } from '../../utils/constants'
import { colors } from '../../styles/_var'


const Navbar: FunctionComponent = React.memo(() => {
  const isMobile = useIsMobile()

  return (
    <NavbarShell>
      <ActionSection>
        {!isMobile && (
          <MenuItemsWrapper>
            <MenuItems showMessages={!isMobile} />
          </MenuItemsWrapper>
        )}
        <Box display="flex" alignItems="center">
          <UserAvatarButton />
          {isMobile && (
            <Link to={routes.MESSAGES}>
              <Box ml={2}>
                <FontAwesomeIcon icon={faEnvelope} size="3x" color={colors.grey} />
              </Box>
            </Link>
          )}
        </Box>
      </ActionSection>
    </NavbarShell>
  )
})


export default Navbar

