import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import Navbar from '../../components/Navbar'
import NavFooter from '../../components/NavFooter'
import { observer } from 'mobx-react-lite'
import uiStore from '../../stores/uiStore'

type MainDashboardLayoutProps = {
  children: React.ReactNode
}

const MainDashboardLayout: FunctionComponent<MainDashboardLayoutProps> = ({ children }) => {
  return (
    <DashboardWrapper>
      {uiStore.showNav && <Navbar />}
      {children}
      {uiStore.showFooter && <NavFooter />}
    </DashboardWrapper>
  )
}

export default observer(MainDashboardLayout)

export const DashboardWrapper = styled.div`
  min-height: 100vh;
  background: linear-gradient(180deg, #EAE9E9 -8.97%, #FFFFFF 10.5%, #FFFFFF 67.26%, #DBCABA 132.22%);
`
