import React, { FunctionComponent, useState } from "react"
import Avatar from "@mui/material/Avatar"
import { Wrapper } from "./CommentReplyForm.styles"
import { userStore } from "../../stores/userStore"
import Input from "../Input"

type CommentReplyFormProps = {
  onReply?: (reply: string) => void
}

const CommentReplyForm: FunctionComponent<CommentReplyFormProps> = ({
  onReply
}) => {
  const [reply, setReply] = useState('')
  const onKeypress = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.which === 13 || e.key === 'Enter') {
      if (!reply.trim()) return
      onReply && onReply(reply)
      setReply('')
    }
  }

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setReply(e.target.value)
  }

  return (
    <Wrapper>
      <Avatar
        src={userStore.avatar}
        alt='comment-author'
        sx={{width: 25, height: 25, marginRight: 1}}
      >
        {userStore.username[0]}
      </Avatar>
      <Input
        name='reply'
        placeholder="Add a reply"
        value={reply}
        onKeyDown={onKeypress}
        onChange={onInputChange}
      />
    </Wrapper>
  )
}

export default CommentReplyForm
  