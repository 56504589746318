import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { ComponentSpacing } from '../../utils/interfaces';
import { getStyleSpacing } from '../../utils/helpers';
import { AdornedButton, StyledButton } from './Button.styles';
import { IconProps } from '@mui/material/Icon';

export type ButtonVariant = 'primary' | 'secondary' | 'danger'
export type ElementType = 'a' | 'button'
export type AdornmentPosition = 'start' | 'end' | 'after-text'

export interface IButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement>, ComponentSpacing {
  variant?: ButtonVariant;
  invertOnHover?: boolean;
  loading?: boolean;
  block?: boolean;
  alignCenter?: boolean;
  adornmentPosition?: AdornmentPosition;
  adornment?: React.ReactNode | IconProps;
}

const Button = React.forwardRef<HTMLButtonElement | null, IButtonProps>(({
  variant,
  invertOnHover,
  loading,
  block,
  children,
  alignCenter,
  mb,
  mr,
  ml,
  mt,
  adornment,
  adornmentPosition="start",
  style={},
  ...props
}, ref) => {
  const spacingProperties = { mb, mr, ml, mt }
  const spacingStyle = getStyleSpacing(spacingProperties)
  const buttonStyle = {...spacingStyle, ...style}

  const getButtonContent = () => { 
    if (loading) return <FontAwesomeIcon icon={faSpinner} spin size="1x" />
    if (!loading && !adornment) return children

    return (
      <AdornedButton position={adornmentPosition}>
        {adornment} {children}
      </AdornedButton>
    )
  }

  return (
    <StyledButton
      ref={ref}
      role="button"
      variant={variant}
      invertOnHover={invertOnHover}
      block={block}
      style={buttonStyle}
      alignCenter={alignCenter}
      {...props}
    >
      {getButtonContent()}
    </StyledButton>
  )
})


Button.defaultProps = {
  variant: 'primary',
  invertOnHover: false,
  loading: false,
}

export default Button
