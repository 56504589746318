import { faPlus } from '@fortawesome/free-solid-svg-icons'
import React from 'react'
import Link from '../../../components/Link'
import Text from '../../../components/Text'
import TextWithIcon from '../../../components/TextWithIcon'
import { colors } from '../../../styles/_var'
import { routes } from '../../../utils/constants'
import { MainHeader } from './Partners.styles'

const PartnersHeader = React.memo(() => {
  return (
    <MainHeader>
      <Text variant='medium' weight='bold'>
        My Partners
      </Text>
      <Link to={routes.CREATE_PARTNER}>
        <TextWithIcon
          icon={faPlus}
          link 
          color={colors.primary}
          variant='regular'
        >
          Add a Partner
        </TextWithIcon>
      </Link>
    </MainHeader>
  )
})

export default PartnersHeader