import React, { FunctionComponent, useEffect, useState } from 'react'
import Grid from '@mui/material/Grid'
import { PartnerType } from '../../../utils/interfaces'
import { ActionItems, PartnerItem, PartnerItemFooter, PartnerItemHeader } from './Partners.styles'
import Avatar from '@mui/material/Avatar'
import Text from '../../../components/Text'
import Label from '../../../components/Label'
import Box from '../../../components/Box'
import { colors } from '../../../styles/_var'
import { RelationshipLabel, routes } from '../../../utils/constants'
import { humanize } from '../../../utils/helpers'
import TextWithIcon from '../../../components/TextWithIcon'
import { faClock } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt, faEdit } from '@fortawesome/free-regular-svg-icons'
import GoButton from '../../../components/GoButton'
import Link from '../../../components/Link'

type PartnerListType = {
  partners: PartnerType[],
  deleting: boolean,
  deletePartner: (partnerId: string) => void,
}

const PartnerList: FunctionComponent<PartnerListType> = React.memo(({ 
  partners,
  deleting,
  deletePartner
}) => {
  useEffect(() => {
    if (!deleting) {
      setPartnerToDelete('')
    }
  }, [deleting])

  const [partnerToDelete, setPartnerToDelete] = useState('')
  const onDeletePartner = (partnerId?: string) => {
    if (!partnerId) return
    setPartnerToDelete(partnerId)
    deletePartner(partnerId)
  }

  return (
    <Grid container spacing={3}>
      {partners.map((partner, i) => (
        <Grid item xs={12} sm={6} md={12} lg={6}  key={partner.uuid || i}>
            <PartnerItem 
              hasConflict={partner.has_conflict}
              deleting={deleting && partnerToDelete === partner.uuid}
            >
              <Link to={routes.VIEW_PARTNER.replace(':partnerId', partner.uuid || '')}>
                <PartnerItemHeader>
                  <Avatar src={partner.image} sx={{width: 50, height: 50}}>
                    <Text variant='medium'>{partner.first_name[0]}</Text>
                  </Avatar>
                  {partner.has_conflict && <Label text='Matched' />}
                </PartnerItemHeader>
                <Text variant='regular' mt={1} color={colors.black}>
                  {partner.first_name} {partner.last_name}
                </Text>
                {<Box style={{display: 'flex'}}>
                  <TextWithIcon icon={faClock} variant='small' color={colors.primary}>
                    {RelationshipLabel[partner.relationship]} since {humanize(partner.relationship_start_date)}
                  </TextWithIcon>
                </Box>}
              </Link>
              <PartnerItemFooter>
                <ActionItems>
                  <FontAwesomeIcon
                    icon={faTrashAlt}
                    color={colors.primary}
                    onClick={() => onDeletePartner(partner.uuid)} 
                  />
                  <Link to={routes.EDIT_PARTNER.replace(':partnerId', partner.uuid || '')}>
                    <FontAwesomeIcon icon={faEdit} color={colors.primary} />
                  </Link>
                </ActionItems>
                <Link to={routes.VIEW_PARTNER.replace(':partnerId', partner.uuid || '')}>
                  <GoButton style={{cursor: 'pointer'}} />
                </Link>
              </PartnerItemFooter>
            </PartnerItem>
        </Grid>
      ))}
    </Grid>
  )
})

export default PartnerList
