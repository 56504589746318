import React, { FunctionComponent } from "react"
import ImageList, { ImageType } from '../../components/ImageList/ImageList'
import { observer } from "mobx-react-lite"
import { blocklistStore, partnerConflictStore } from "../../stores/partnerStore"
import { colors } from "../../styles/_var"
import Text from "../Text"
import { Wrapper } from './ConflictView.styles'
import AvatarWithLabel from "../AvatarWithLabel"
import TextWithIcon from "../TextWithIcon"
import { faClock } from "@fortawesome/free-regular-svg-icons"
import Button from "../Button"
import Divider from "@mui/material/Divider"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCancel, faEnvelope } from "@fortawesome/free-solid-svg-icons"
import { humanize } from "../../utils/helpers"
import { RelationshipLabel2, routes } from "../../utils/constants"
import { chatRoomStore } from "../../stores/chatStore"
import { useNavigate } from "react-router-dom"
import uiStore from "../../stores/uiStore"
import { userStore } from "../../stores/userStore"
import Link from "../Link"

type ConflictViewProp = {
  subject?: string,
  partnerId?: string
}

const ConflictView: FunctionComponent<ConflictViewProp> = ({
  subject,
  partnerId
}) => {
  const navigate = useNavigate()
  const conflict = partnerConflictStore.selectedConflict
  if (!conflict) return null
  
  const fullName = `${conflict.user.first_name} ${conflict.user.last_name}`
  const onBlock = () => {
    if (!partnerId) return
    if (userStore.hasRegularSubscription) {
      uiStore.closeDialog()
      return navigate(routes.UPGRADE_NOW)
    }

    blocklistStore.addToBlockList(partnerId, conflict.user.username, conflict.id)
  }

  const onStartMessage = () => {
    if (userStore.hasRegularSubscription) {
      uiStore.closeDialog()
      return navigate(routes.UPGRADE_NOW)
    }

    chatRoomStore.startNewChat(conflict.user.username, () => {
      navigate(routes.MESSAGES)
      uiStore.closeDialog()
    })
  }

  return (
    <Wrapper>
      <AvatarWithLabel
        label={fullName}
        src={conflict.user.avatar}
        sx={{width: 90, height: 90}}
        labelProps={{color: colors.black, variant: 'medium', mt: 1}}
        column
        border
      />
      <Text variant='regular' color={colors.primary} weight='bold'>
        {RelationshipLabel2[conflict.relationship]} {subject}
      </Text>
      <TextWithIcon
        center
        weight='bold'
        mt={1}
        icon={faClock}
        color={colors.grey}
        style={{cursor: 'pointer'}}
      >
        Since {humanize(conflict.relationship_start_date)}
      </TextWithIcon>
      <div>
        <Button
          mt={1}
          mb={1}
          variant='primary'
          adornment={<FontAwesomeIcon icon={faEnvelope} />}
          loading={chatRoomStore.creating}
          onClick={onStartMessage}
        >
          Message
        </Button>
        {!!partnerId && <Button
          mt={1}
          mb={2}
          ml={1}
          variant='secondary'
          loading={blocklistStore.creating}
          adornment={<FontAwesomeIcon icon={faCancel} />}
          onClick={onBlock}
        >
          Block
        </Button>}
      </div>
      <Divider />
      <Text variant='regular' color={colors.primary} left mt={1}>
        {conflict.evidence.length} photos
      </Text>
      {conflict.concealed && (
        <>
          <Text variant="regular" color={colors.grey} weight='bold'>
            Photos are unavailble on this subscription plan
          </Text>
          <Link to={routes.UPGRADE_NOW} onClick={() => uiStore.closeDialog()}>
            <Text
              link
              inline
              underline
              variant='regular'
              color={colors.primary}
            >
              Upgrade Now
            </Text>
          </Link>
        </>
      )}
      {!conflict.concealed && (
        <ImageList 
          variant='masonry'
          isLoadingIfNoId
          photos={conflict.evidence as ImageType[]} 
        />
      )}
    </Wrapper>
  )
}

export default observer(ConflictView)
  