import React, { FunctionComponent } from 'react'
import { CheckboxProps } from '@mui/material/Checkbox'
import { exclude } from '../../utils/helpers'
import { StyledCheckbox } from './Checkbox.styles'


const Checkbox: FunctionComponent<CheckboxProps> = ({ error, ...rest}: any) => {
  const checkBoxProps = exclude(rest, ['helperText'])
  return <StyledCheckbox {...checkBoxProps} color={error ? "secondary" : "default"} />
}

export default Checkbox

