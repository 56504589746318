import { chatRoomStore } from "../stores/chatStore"

const websocketHandler = ({ data }: any) => {
  const { type, payload } = JSON.parse(data)
  const handler = handlerMap[type]
  handler(payload)
}

const newChatMessageHandler = (payload: any) => {
  const { roomID } = payload
  if (!roomID) return 
  
  chatRoomStore.addMessageToRoom(roomID, payload)

  // update chatroom data
  chatRoomStore.updateRoomThumbnailInfo(roomID, payload)

  // this basically refreshes the chat box with the latest 
  if (chatRoomStore?.selectedRoom?.uuid !== roomID) {
    const room = chatRoomStore.getFromList(roomID, 'uuid')
    chatRoomStore.incrementUnread(room)
  } else if (chatRoomStore?.selectedRoom?.uuid === roomID) {
    const room = chatRoomStore.getFromList(roomID, 'uuid')
    if (!room) return
    chatRoomStore.selectActiveRoom(room)
    chatRoomStore.markAsRead(room, true)
  }
}

const deleteChatMessageHandler = (payload: any) => {
  console.log('delete handler fired...')
  const { roomID } = payload
  chatRoomStore.removeChatRoom(roomID)
}

export const dataTypes = {
  NEW_CHAT_MESSAGE: 'new_chat_message',
  DELETE_CHAT_MESSAGE: 'delete_chat_message'
}

export const handlerMap: any = {
  [dataTypes.NEW_CHAT_MESSAGE]: newChatMessageHandler,
  [dataTypes.DELETE_CHAT_MESSAGE]: deleteChatMessageHandler
}

export default websocketHandler