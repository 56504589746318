import FieldStore, { defaultConfig as fieldDefaultConfig, Config as FieldStoreConfig } from "./fieldStore";
import { apiRoutes, methods } from "../utils/constants";

class FetchNotificationStore extends FieldStore<any> {
  config: FieldStoreConfig<any> = { 
    ...fieldDefaultConfig,
    url: apiRoutes.NOTIFICATION_SETTINGS,
    method: methods.GET
  };
}

class SetNotificationStore extends FieldStore<any> {
  config: FieldStoreConfig<any> = { 
    ...fieldDefaultConfig,
    url: apiRoutes.NOTIFICATION_SETTINGS,
    method: methods.POST,
    useToastOnSuccess: true,
    toastDuration: 2000
  };
}

export const fetchNotificationStore = new FetchNotificationStore()
export const setNotificationStore = new SetNotificationStore()