import React from "react"
import Text from "../Text"
import { Wrapper, SectionHeader, SectionBody } from "./UserAccount.styles"
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import ChangePassword from "../ChangePassword";
import NotificationSettings from "../NotificationSettings";
import SubscriptionSettings from "../SubscriptionSettings";
import CloseAccount from "../CloseAccount";
import BillingInformation from "../BillingInformation";
import uiStore from "../../stores/uiStore";
import { observer } from "mobx-react-lite";

const ExpandMoreIcon = () => {
  return (
    <FontAwesomeIcon icon={faAngleDown} />
  )
}


const UserAccount = observer(() => {
  const expanded = uiStore.userAccountExpanded
  const handleChange = (panel: string) => () => {
    uiStore.setExpanded(panel === expanded ? '' : panel)
  }

  return (
    <Wrapper>
      <SectionHeader>
        <Text variant="large" weight="bold" color="white">
          Account
        </Text>
      </SectionHeader>
      <SectionBody>
        <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <Text variant="regular">Change Password</Text>
          </AccordionSummary>
          <AccordionDetails><ChangePassword /></AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2bh-content"
            id="panel2bh-header"
          >
            <Text variant="regular">Notifications</Text>
          </AccordionSummary>
          <AccordionDetails><NotificationSettings /></AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2bh-content"
            id="panel2bh-header"
          >
            <Text variant="regular">Subscriptions</Text>
          </AccordionSummary>
          <AccordionDetails><SubscriptionSettings /></AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2bh-content"
            id="panel2bh-header"
          >
            <Text variant="regular">Billing Information</Text>
          </AccordionSummary>
          <AccordionDetails><BillingInformation /></AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2bh-content"
            id="panel2bh-header"
          >
            <Text variant="regular">Close or Suspend Account</Text>
          </AccordionSummary>
          <AccordionDetails><CloseAccount /></AccordionDetails>
        </Accordion>
      </SectionBody>
    </Wrapper>
  )
})

export default UserAccount
