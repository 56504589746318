import React, { FunctionComponent } from 'react'
import logo from '../../assets/images/logo1.png'
import logo2 from '../../assets/images/logo2.png'
import authImg from '../../assets/images/auth-image.png'
import { colors } from '../../styles/_var'
import Text from '../../components/Text'
import { routes } from '../../utils/constants'
import Link from '../../components/Link'
import { 
  RightWrapper,
  Wrapper,
  Content,
  RightContent,
  Logo,
  AuthImage,
  Left,
  Right,
  AuthFooter
} from './MainAuthLayout.styles'
import useIsMobile from '../../hooks/useIsMobile';
import Dot from '../../components/Dot'


const MainAuthLayout: FunctionComponent<{ children: React.ReactNode }> = ({ children }) => {
  const isMobile = useIsMobile()

  return (
    <Wrapper>
      <Left>
        <Content>
          <Link to={routes.DASHBOARD}>
            <Logo src={logo} alt="site-logo"  />
          </Link>
          <AuthImage src={authImg} alt="auth-img" />
          <Text variant='medium' weight={600} color={colors.white}>
            Is your bae 🥰 really your bae? 🤨
          </Text>
        </Content>
      </Left>
      <Right>
        <RightContent>
          {isMobile && (
            <Link to={routes.DASHBOARD}>
              <Logo src={logo2} alt="site-logo" />
            </Link>
          )}
          <RightWrapper>
            {children}
          </RightWrapper>
          <AuthFooter>
            <Link to={routes.PRIVACY_POLICY}>
              <Text variant='small' weight='bold' color={colors.primary} inline>Privacy Policy</Text>
            </Link>
            <Dot ml={1} mr={1} />
            <Link to={routes.TERMS_AND_CONDITIONS}>
              <Text variant='small' weight='bold' color={colors.primary} inline>Terms of use</Text>
            </Link>
            <Dot ml={1} mr={1} />
            <Link to={routes.CONTACT_US}>
              <Text variant='small' weight='bold' color={colors.primary} inline>Contact Us</Text>
            </Link>
            <Dot ml={1} mr={1} />
            <Link to={routes.ABOUT_US}>
              <Text variant='small' weight='bold' color={colors.primary} inline>About Us</Text>
            </Link>
          </AuthFooter>
        </RightContent>
      </Right>
    </Wrapper>
  )
}


export default MainAuthLayout