import styled from "styled-components";
import { device, spacing } from "../../styles/_var";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: ${spacing.sm};

  @media ${device.sm} {
    width: 400px;
  }
`
