import styled from "styled-components";
import { colors, spacing } from "../../../styles/_var";

export const Wrapper = styled.div``

export const Features = styled.div`
  background-color: ${colors.yellow};
  padding: ${spacing.sm};
  border-radius: 20px;
  margin-top: ${spacing.sm};

  & ul {
    padding-left: 0;
  }
`