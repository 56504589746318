import React, { FunctionComponent } from 'react'
import { colors, spacing } from '../../styles/_var'
import GoButton from '../GoButton'
import Text from '../Text'
import { ReactComponent as NoConflict } from '../../assets/images/no-conflict.svg'
import { ReactComponent as Conflict } from '../../assets/images/conflict.svg'
import { Card, NameAndLocation, Section, Wrapper } from './RightSideComponents.styles'
import TextWithIcon from '../TextWithIcon'
import { faClock } from '@fortawesome/free-solid-svg-icons'
import { humanize } from '../../utils/helpers'
import { observer } from 'mobx-react-lite'
import { partnerStore, recentBaerifications } from '../../stores/partnerStore'
import { PartnerType } from '../../utils/interfaces'
import { RelationshipLabel, routes } from '../../utils/constants'
import Link from '../Link'
import Button from '../Button'
import { faFrown } from '@fortawesome/free-regular-svg-icons'


export interface IBaerification {
  search_term: string,
  search_key: string,
  has_conflict: boolean,
  created_at: string
}

const Partner: FunctionComponent<PartnerType> = React.memo(({ 
  uuid='',
  first_name,
  last_name,
  relationship,
  relationship_start_date
}) => (
  <Card>
    <NameAndLocation>
      <Text variant='regular'>{first_name} {last_name}</Text>
      <TextWithIcon icon={faClock} variant='small' color={colors.primary}>
        {RelationshipLabel[relationship]} since {humanize(relationship_start_date)}
      </TextWithIcon>
    </NameAndLocation>
    <Link to={routes.VIEW_PARTNER.replace(':partnerId', uuid)}>
      <GoButton />
    </Link>
  </Card>
))

const Baerification: FunctionComponent<IBaerification> = React.memo(({ 
  search_term, 
  has_conflict,
  created_at
}) => (
  <Card justifyContent='flex-start'>
    {has_conflict ? <Conflict /> : <NoConflict />}
    <NameAndLocation style={{ marginLeft: spacing.xxs}}>
      <Text variant='regular'>{search_term}</Text>
      <TextWithIcon color={colors.darkgrey} icon={faClock}>{humanize(created_at)}</TextWithIcon>
    </NameAndLocation>
  </Card>
))

export const RecentPartners = observer(() => {
  if (!partnerStore.hasData) return null
  const recentPartners = partnerStore.list.slice(0, 2)
  return (
    <Section>
      <Text variant='regular' weight='bold' color={colors.grey} mb={1}>
        Your recent Partners
      </Text>
      {recentPartners.map((partner, i) => <Partner {...partner} key={i} />)}
    </Section>
  )
})

export const RecentBaerifications = observer(() => {
  if (!recentBaerifications.data || !recentBaerifications.data.length) return null

  return (
    <Section>
      <Text variant='regular' weight='bold' color={colors.grey} mb={1}>
        Some recent baerifications
      </Text>
      {recentBaerifications.data?.map((partner, i) => <Baerification {...partner} key={i} />)}
    </Section>
  )
})

export const AddPartners = observer(() => {
  if (partnerStore.hasData) return null
  return (
    <Section>
      <Text variant='regular' weight='bold' color={colors.grey} mb={1}>
        Your recent Partners
      </Text>
      <TextWithIcon color={colors.primary} icon={faFrown} variant='regular'>
        You have no partners yet
      </TextWithIcon>
      <Link to={routes.CREATE_PARTNER}>
        <Button variant='primary' block mt={1}>Add Partner</Button>
      </Link>
    </Section>
  )
})


const RightSideComponents = () => {
  return (
    <Wrapper>
      <RecentPartners />
      <RecentBaerifications />
      <AddPartners />
    </Wrapper>
  )
}

export default RightSideComponents
