import React, { FunctionComponent } from 'react'
import LeftSidebarComponents from '../../components/LeftSidebarComponents'
import RightSideComponents from '../../components/RightSideComponents'
import useIsMobile from '../../hooks/useIsMobile'
import { LeftSidebar, RightSidebar, Wrapper, MainContent } from './DashboardWithSidebar.styles'


const DashboardWithSidebar: FunctionComponent<{children: React.ReactNode}> = ({
  children
}) => {
  const isMobile = useIsMobile()

  return (
    <Wrapper isMobile={isMobile}>
      <LeftSidebar isMobile={isMobile}>
        <LeftSidebarComponents />
      </LeftSidebar>
      <MainContent isMobile={isMobile}>
        {children}
      </MainContent>
      <RightSidebar isMobile={isMobile}>
        <RightSideComponents />
      </RightSidebar>
    </Wrapper>
  )
}

export default DashboardWithSidebar
