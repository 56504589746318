import { NavLink } from "react-router-dom"
import styled from "styled-components"
import { colors } from "../../styles/_var"
import Text from "../Text"


export const Wrapper = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  height: 3.5rem;
  flex: 1;
`

export const MenuItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  & svg {
    height: 1.7rem;
    width: auto;
  }
`

export const IconLabel = styled(Text)`
  font-size: 12px;
  line-height: 5px;
`

export const StyledNavLink = styled(NavLink)`
  text-decoration: none;
`

export const UnreadMarker = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: ${colors.red};
  position: absolute;
  top: -4px;
  right: -4px;
`