import React, { FunctionComponent } from 'react'
import { INotification } from '../../../utils/interfaces'
import { appendQueryString, humanize } from '../../../utils/helpers'
import Avatar from '@mui/material/Avatar'
import { NotificationItem } from './Notifications.styles'
import Text from '../../../components/Text'
import Box from '../../../components/Box'
import { colors } from '../../../styles/_var'
import { useNavigate } from 'react-router-dom'
import { NotificationTypes, routes } from '../../../utils/constants'


const CommentView: FunctionComponent<Partial<INotification>> = React.memo(({
  actor,
  created_at,
  notification_object,
  read,
  verb
}) => {
  const navigate = useNavigate()
  const { story, id, replying, content } = notification_object
  const gotoComment = () => {
    const url = `${routes.VIEW_STORY.replace(':storyId', story)}`
    const route = appendQueryString(url, {commentId: id, replyingTo: replying})
    navigate(route)
  }

  return (
    <NotificationItem read={read} onClick={gotoComment}>
      <Avatar src={actor?.avatar}>
        <Text variant='medium' weight='bold'>
          {actor?.username[0]?.toUpperCase()}
        </Text>
      </Avatar>
      <Box ml={2}>
        <Text variant='small' color={colors.grey}>
          {created_at && humanize(created_at)}
        </Text>
        <Text variant='regular' weight='bold' mb={1}>
          @{actor?.username} {!!verb && NotificationLabel[verb]}
        </Text>
        <Text variant='regular' color={colors.grey}>
          "{content}"
        </Text>
      </Box>
    </NotificationItem>
  )
})

export default CommentView

const NotificationLabel = {
  [NotificationTypes.COMMENT_ADDED]: 'posted a comment',
  [NotificationTypes.LIKED_YOUR_COMMENT]: 'liked your comment',
  [NotificationTypes.REPLIED_YOUR_COMMENT]: 'replied to your comment'
}