import React from 'react'
import Text from '../../components/Text'
import { Wrapper, Logo, Section } from './PrivacyPolicy.styles'
import logo from '../../assets/images/logo2.png'
import Link from '../../components/Link'
import { routes } from '../../utils/constants'

const PrivacyPolicy = React.memo(() => {
  return (
    <Wrapper>
      <Link to={routes.DASHBOARD}>
        <Logo src={logo} />
      </Link>
      <Section>
        <Text weight='bold' variant='medium' center mb={1}>Privacy Policy</Text>
        <Text>
          Welcome to the Baerify Privacy Policy (“Policy”)! This explains how we collect, store, protect, and share your information, and with whom we share it. We suggest you read this in conjunction with our Terms and Conditions of Use.
        </Text>
        <Text mt={1}>
          Whilst you're enjoying the Baerify mobile application (including the desktop version)
          (“App”), our websites and microsites (such as Baerify.com) or using our digital products and
          services (such as our competitions or surveys) (together, referred to in this Privacy Policy as
          our “Sites”), we collect some information about you. In addition, you may choose to use the
          App or Sites to share information with other users, including your friends and contacts
          (“Users”). We may also need to share your information sometimes.
        </Text>
        <Text mt={1}>
          The App and Sites are global, and your information will be sent to and used in the United
          States and the UK regardless of the country you reside in. This Policy explains how we
          protect your personal data when we transfer it overseas, so please read very carefully!
        </Text>
        <Text mt={1} weight='bold'>Who we are?</Text>
        <Text mt={1}>
          The App and Sites are operated by the “Baerify Company” (also referred to in this policy as
          “we” or “us”) which means Matuski Limited.
        </Text>
      </Section>
      <Section>
        <Text weight='bold' variant='medium'>1. COLLECTION OF INFORMATION.</Text>
        <Text weight='bold'>Registration Information</Text>
        <Text mt={1}>
          When you download the App and create an account (“Account”), we may collect certain information (“Registration Information”) about you, such as:
        </Text>
        <ul>
          <li><Text>Name;</Text></li>
          <li><Text>Username;</Text></li>
          <li><Text>Email address;</Text></li>
          <li><Text>Mobile number;</Text></li>
          <li><Text>Gender identity;</Text></li>
          <li><Text>Date of birth;</Text></li>
          <li><Text>Sexual preference;</Text></li>
          <li><Text>Photographs;</Text></li>
          <li><Text>Location; and</Text></li>
          <li>
            <Text>
              Login information for social media accounts that you connect to your Baerify Account (this could include, for example, your Facebook and Instagram accounts). For more information about this, see “Linking other Accounts to Baerify” in section 7 below.
            </Text>
          </li>
        </ul>
        <Text>
          Once you register, you will be able to review and change this information at any time just by logging in to Baerify (other than your date of birth and location (which, if you have given Baerify access to your location in your device settings, is automatically updated based on the location of your device)). It is your responsibility to ensure that your account details are kept up to date. If your phone number changes, please ensure that you update this in your account.
        </Text>
        <Text mt={1}>
          The information we collect helps to enhance the App and verify our Users (robots are not welcome!). Registration Information such as your sexual preference, name and username may be visible to other Users who view your profile page.
        </Text>
      </Section>
      <Section>
        <Text variant='medium' weight='bold'>Profile Information</Text>
        <Text>
          We recommend and encourage you (and all our members) to think carefully about the information you disclose about yourself. We also do not recommend that you put email addresses, URLs, instant messaging details, phone numbers, full names or addresses, credit card details, national identity numbers, drivers' licence details and other sensitive information which is open to abuse and misuse on your profile.
        </Text>
        <Text mt={1}>
          When you post information about yourself or use the messaging function to communicate
          with other Users, the amount of personal information you share is at your own risk. Please
          see Section 4 below for more information on who can access what you post on Baerify.
        </Text>
        <Text variant='medium' weight='bold'>
          Profile Verification Information (Including Biometric Information)
        </Text>
        <Text>
          For safety and security and to ensure you have the best possible user experience, we require
          Users to verify their accounts and might ask for your phone number and, in some instances,
          we might also ask that you carry out photo verification. We want to make sure you are not a
          robot! And we also want to avoid fake Baerify accounts being created which can be used for
          malicious activities and cybercrime - they threaten the Baerify network and spoil things for
          everyone. This verification might be required by us for the prevention of fraud.
        </Text>
        <Text mt={1}>
          If you choose to verify your profile photo, we will scan each photo that you submit. The scan
          may include the use of facial recognition technology so that we can compare the photo you
          submit to your profile photo, to help ensure that you are who you say you are. We do not add
          the verification photos to your profile. We retain the scans so that we can verify you in the
          future and for our record-keeping purposes until we no longer need them for such purposes or
          for three years after your last interaction with us, whichever occurs first. After the applicable retention period expires, we take commercially reasonable steps to permanently and securely delete the scans from our systems.
        </Text>
      </Section>
      <Section>
        <Text variant='medium' weight='bold'>Purchases Information</Text>
        <Text>
          If you decide to purchase any of our premium services, we will process your payment
          information and retain this securely for the prevention of fraud and for audit/tax purposes.
        </Text>
        <Text mt={1}>
          Baerify uses automated decisions to prevent fraudulent payment transactions being processed
          as part of its anti-fraud procedures. In order to do this, our systems check payment
          transactions for behaviour that indicates breaches of our Terms and Conditions of Use. If a
          transaction meets certain criteria that demonstrate that the Terms and Conditions of Use are
          likely to have been breached and the transaction is likely to be fraudulent, the relevant
          transaction may automatically be blocked. Where a transaction is blocked, the user will be
          notified that their transaction cannot be processed and affected Users can contact Baerify to
          contest the decision.
        </Text>
        <Text>
          Please note that if your account is blocked for any reason, any account(s) that you have on
          other Baerify Company platforms/applications, such as Badoo, may also be blocked as part of
          our anti-spam and anti-fraud procedures.
        </Text>
      </Section>
      <Section>
        <Text weight='bold' variant='medium'>Geolocation Information</Text>
        <Text>
          If you have given Baerify access to your location in your device settings, when you use your mobile, we will collect information about WiFi access points as well as other location information about your longitude and latitude and may save your device's coordinates to offer certain features to you. This information helps us identify your physical location and we use it to personalise the App and make it easier for you to interact with other Users, by enabling the general locality information to be displayed to Users seeing your profile and showing you the profiles of other Users who are near you.
        </Text>
        <Text mt={1}>
          If you have given Baerify access to your location, but wish to turn this off, you can do so by
          the following methods:
        </Text>
        <Text mt={1}>1. iPhone app — settings, privacy, location services, Baerify</Text>
        <Text mt={1}>2. Android — settings, location, Baerify, permissions, location</Text>
      </Section>
      <Section>
        <Text weight='bold' variant='medium'>Device Information</Text>
        <Text>
          We may collect information about your device when you use the App including the unique device identifier, device model, and operating system, for a number of purposes, as set out in this policy. In addition, if you permit us to do so, the App may access your device's address book solely in order to add someone to your contacts.
        </Text>
      </Section>
      <Section>
        <Text weight='bold' variant='medium'>Links</Text>
        <Text>
          We may keep track of how you interact with links available on Baerify including third party services by redirecting clicks or through other means. We may share aggregate click statistics such as how many times a particular link was clicked on.
        </Text>
      </Section>
      <Section>
        <Text weight='bold' variant='medium'>
          When you Contact Customer Support
        </Text>
        <Text>
          If you contact our Customer Support team via our feedback page, we will receive your email address, and may track your IP address, as well as the information you send to us to help resolve your query. We will keep records of our communications with you, including any complaints that we receive from you about other Users (and from other Users about you) for 6 years after deletion of your account.
        </Text>
      </Section>
      <Section>
        <Text weight='bold' variant='medium'>
          Cookies and similar technologies
        </Text>
        <Text>
          When you visit our Sites or when you use our App, we may collect personal data from you automatically by using cookies or similar technologies. A cookie is a small file that can be placed on your device or browser that allows us to recognise and remember you. If you would like to find out more about cookies, including how we use them and what choices are available to you.
        </Text>
      </Section>
      <Section>
        <Text weight='bold' variant='medium'>2. USE OF YOUR INFORMATION.</Text>
        <Text>
          Our main goal is to ensure your experience on Baerify is an enjoyable one and you don't end
          up getting stung! In order to deliver an enjoyable experience to you, we may use your
          Registration and other information to:
        </Text>
        <ul>
          <li><Text>offer you our services and features;</Text></li>
          <li><Text>contact you with information about the App (e.g., updates and new features);</Text></li>
          <li><Text>personalise the App/Sites and the content we deliver to you;</Text></li>
          <li><Text>conduct research and analytics about how you use and interact with the App/Sites; </Text></li>
          <li><Text>resolve disputes, troubleshoot problems and to enforce our Terms &amp; Conditions;</Text></li>
          <li><Text>investigate fraud, protect our legal rights, and to enforce our Terms &amp; Conditions.</Text></li>
          <li>
            <Text>
              to send you information about the promotions and offers we have available - if you've signed up for our communications or otherwise told us it's OK (you can withdraw from marketing at any time via Settings in the App or by using the opt-out mechanisms and links provided in each message); and
            </Text>
          </li>
          <li><Text>protect our Users and third parties from harm.</Text></li>
        </ul>
        <Text variant='medium' weight='bold'>Moderation Practices</Text>
        <Text>
          We use a combination of automated systems and a team of moderators to monitor and review accounts (including photos and any other information uploaded onto user profiles) and messages for content that indicates breaches of our Terms and Conditions of Use. If an account or message meets certain criteria that demonstrate that the Terms and Conditions of Use are likely to have been breached, the relevant account will be subject to a warning and the user's access restricted and/or blocked. Affected Users can contact Baerify to contest the decision.
        </Text>
        <Text mt={1} weight='bold'>
          If you post anything that is inconsistent with our Terms and Conditions of Use, we reserve the right to terminate or restrict access to your Account.
        </Text>
        <Text variant='medium' weight='bold'>
          Processing we perform to improve services for students
        </Text>
        <Text>
          We process personal data to understand which of our users may be in college or university. To do this, we collect information regarding whether you have used the App somewhere in a broad geographic area around a university. We keep a simple “student yes/no” indication based on our findings. No other information is collected and nothing is shared outside Baerify. Please note that we do not record your precise location as part of this processing and we permanently erase the usage data shortly after collection. We'll combine this information with other relevant information we hold for the following purposes: so we can send the right offers, events and features to the right users; to understand which Baerify events are popular with the student demographic and which aren't working so well; and to improve the design of the App to meet the needs of students, based on the way they like to use the App. The legal basis for this processing is our legitimate interests. We have a legitimate interest in providing a more tailored app experience for student users and in optimising their chances of finding the right connections. Please note this processing only affects Baerify users located in the United States.
        </Text>
      </Section>
      <Section>
        <Text weight='bold' variant='medium'>
          3. DISCLOSURE OF INFORMATION.
        </Text>
        <Text>
          Our policy is to not disclose your Registration Information or personal data, except in the limited circumstances described here:
        </Text>
        <Text variant='medium' weight='bold' mt={1}>
          Circumstances where data may be disclosed
        </Text>
        <Text>
          <strong>Service Providers</strong> - We engage certain trusted third parties to perform functions and provide services to us. We may share your Registration Information or personal data with these third parties, but only for the purposes of performing these functions and providing such services. More information about this is available directly below.
        </Text>
        <Text mt={1}><strong>Moderators</strong> - To monitor activity on the App and approve content.</Text>
        <Text mt={1}>
          <strong>Payment Processing and Telecommunications Companies</strong> - To facilitate payments for our premium services.
        </Text>
        <Text mt={1}>
          <strong>Law and Harm</strong> - As we mentioned in the Terms &amp; Conditions, we think it is very important that all Users behave whilst using the App. We will cooperate with all third parties to enforce their intellectual property or other rights. We will also cooperate with law enforcement enquiries from within or outside your country of residence where we are required to by law, where there is an investigation into alleged criminal behaviour or to protect the vital interests of a person. This may include preserving or disclosing any of your information, including your Registration Information, if we believe in good faith that it is necessary to comply with a law or regulation, or when we believe that disclosure is necessary to comply with a judicial proceeding, court order, or legal request; to protect the safety of any person; to address fraud, security or technical issues e.g. through anti-spam providers to protect the service from criminal activity or to protect our rights or property or those of third parties. In such cases we may raise or waive any legal objection or right available to us.
        </Text>
        <Text mt={1}>
          <strong>Business Transfers</strong> - In the event that a Baerify Company entity or any of its affiliates undergoes a business transition or change of ownership, such as a merger, acquisition by another company, re-organisation, or sale of all or a portion of its assets, or in the event of insolvency or administration, we may be required to disclose your personal data.
        </Text>
        <Text mt={1}>
          <strong>Marketing Services Providers</strong> - To help us serve marketing and advertising on third party websites and applications and measure the effectiveness of our advertising campaigns. More information on this is available below
        </Text>
        <Text mt={1}>
          <strong>Anti-Spam and Anti-Fraud</strong> - Your data may be shared with other Baerify Company companies, for example, to block accounts and suspected fraudulent payment transactions as part of our anti-spam and anti-fraud procedures.
        </Text>
        <Text mt={1}>
          Aggregated Information - We may share aggregated information with third parties that includes your personal data (but which doesn't identify you directly) together with other information including log data for industry analysis and demographic profiling.
        </Text>
        <Text mt={1}>
          Baerify does not sell your data and has not sold your personal data in the previous 12 months.
        </Text>
      </Section>
      <Section>
        <Text variant='medium' weight='bold'>
          MORE INFORMATION ABOUT DISCLOSURES
        </Text>
        <Text mt={1} weight='bold'>Service Providers</Text>
        <Text mt={1}>
          We engage certain trusted third parties to perform functions and provide services to us (“Service Providers”). The suppliers with which Baerify shares User personal data vary depending on a variety of factors, such as which of our App, Sites and services a User engages with. For example, to provide our services to Users, we typically use the following suppliers:
        </Text>
        <ul>
          <li>
            <Text>
              <strong>Billing services</strong> - to allow customers to purchase paid features of our App (for example, Google Play)
            </Text>
          </li>
          <li>
            <Text>
              <strong>Authentication services</strong> - to allow customers to authenticate their account (for example, Twilio)
            </Text>
          </li>
          <li>
            <Text>
              <strong>Social media providers</strong> - to allow customers to create/connect their Baerify account with their account(s) on such platforms (for example Facebook, Instagram or Spotify)
            </Text>
          </li>
          <li>
            <Text>
              <strong>Product improvement and market research</strong> - we use third party platforms (such as Typeform) and agencies (such as Kantar) to carry out customer surveys and market research to improve our products and services
            </Text>
          </li>
          <li>
            <Text>
              <strong>IT services</strong> - some of the third-party software providers used in the operation of our business may process Users' personal data (for example, if a User contacts us via social media with a support inquiry, their inquiry is processed by our community
              management software provider Khoros)
            </Text>
          </li>
        </ul>
        <Text>
          We carry out due diligence on all Service Providers we engage to ensure they have adequate data protection and information security measures in place and only provide them with the personal data necessary to the service they are providing. Measures are taken to ensure that the data shared is non-attributable to the greatest extent possible and our suppliers are also subject to extensive obligations under our contractual arrangements, including strict data retention limits.
        </Text>
        <Text mt={1} mb={1} weight='bold'>
          Marketing Services Providers
        </Text>
        <Text mb={1}>
          We partner with providers of marketing services (such as Facebook for example) ('Marketing Services Providers') to help us market and advertise our App and services on third party websites and applications and measure the effectiveness of our advertising campaigns. For example:
        </Text>
        <ul>
          <li>
            <Text>
              to exclude you from advertising campaigns aimed at finding new users, if you already have a Baerify account;
            </Text>
          </li>
          <li>
            <Text>
              to show Baerify adverts to users who have visited the Baerify App/Sites but haven't yet created a Baerify account;
            </Text>
          </li>
          <li>
            <Text>
              to create an audience for our advertisements of other potential users who have similar characteristics to you based on the information the Marketing Service Providers holds about you (also known as a Lookalike Audience); or
            </Text>
          </li>
          <li>
            <Text>
              to include you in a 'custom audience' that will receive Baerify advertising content (a custom audience is essentially a list of people who we think are most likely to be interested in a particular advertisement). We share a limited amount of your personal data with these Marketing Services Providers, such as:
            </Text>
          </li>
          <li>
            <Text>
              the advertising identifier associated with your device (this is a random number assigned by your mobile device manufacturer (for example Apple or Google) to your device to help advertisers (including the manufacturer) know when an ad has been viewed or clicked in an app, and when an ad causes a 'conversion' (for example, downloading the app advertised to you))
            </Text>
          </li>
          <li>
            <Text>your estimated location (based on your IP address)</Text>
          </li>
          <li>
            <Text>age and gender</Text>
          </li>
          <li>
            <Text>
              data about your visit to our Sites or App and action taken on those (for example if you downloaded our App or created an account with our App)
            </Text>
          </li>
          <li>
            <Text>
              a hashed* version of your email address (to create 'custom audiences').
            </Text>
          </li>
        </ul>
        <Text mt={1} mb={1}>
          *Hashing is a way of encrypting information by turning it into a combination of random numbers and letters - this code cannot be traced back to the email address. When hashed email addresses are sent to a Marketing Service Provider, they're then matched against the Provider's own existing list of their own users' hashed information and our ads are served to those of our users who have successfully been matched with the Provider's. Matched and unmatched hashes are then deleted by the Provider.
        </Text>
        <Text>
          For more information about how we use cookies and other tracking technologies, including how you can set and manage your preferences with regards to such technologies, please see our Cookie Policy. In some cases, these third parties will also use the data that they collect for their own purposes, for example they may aggregate your data with other data they hold and use this to inform advertising related services provided to other clients.
        </Text>
      </Section>
      <Section>
        <Text variant='medium' weight='bold'>4. OUR POLICY TOWARDS AGE.</Text>
        <Text mt={1} mb={1}>
          Although we want as many people as possible to enjoy our creation, you have to be at least 18 years old to use the App - sorry kids, we know Baerify is cool, but you'll have to come back when you're old enough!
        </Text>
        <Text>
          Baerify does not knowingly collect any information about or market to children, minors or anyone under the age of 18. If you are less than 18 years old, we request that you do not submit information to us. If we become aware that a child, minor or anyone under the age of 18 has registered with us and provided us with personal information, we will take steps to terminate that person's registration.
        </Text>
      </Section>
      <Section>
        <Text weight='bold' variant='medium'>5. SECURITY.</Text>
        <Text mt={1} mb={1}>
          Here at Baerify, we pride ourselves on taking all appropriate security measures to help protect your information against loss, misuse and unauthorised access, or disclosure. We use reasonable security measures to safeguard the confidentiality of your personal information such as secured servers using firewalls.
        </Text>
        <Text>
          Unfortunately, no website or Internet transmission is ever completely 100% secure and even we cannot guarantee that unauthorised access, hacking, data loss or other breaches will never occur, but here are some handy tips to help keep your data secure:
        </Text>
        <Text>
          1. Please make sure you log out of your Account after use as you never know who may stumble onto your Account!
        </Text>
        <Text>
          2. Please don't share the password you use to access your Baerify Account with anyone else!
        </Text>
        <Text>3. Change your password periodically.</Text>
        <Text mt={1}>
          If you ever think someone has had access to your password or Account, please follow the steps set out here. We cannot guarantee the security of your personal data while it is being transmitted to our site and any transmission is at your own risk.
        </Text>
      </Section>
      <Section>
        <Text variant='medium' weight='bold'>6. YOUR RIGHTS.</Text>
        <Text mt={1} mb={1}>
          Privacy laws applicable in your country may give you the following rights:
        </Text>
        <Text>
          1. Right to be informed: what personal data an organisation is processing and why (we provide this information to you in this Privacy Policy).
        </Text>
        <Text>
          2. Right of access: you can request a copy of your data.
        </Text>
        <Text>
          3. Right of rectification: if the data held is inaccurate, you have the right to have it corrected.
        </Text>
        <Text>
          4. Right to erasure: you have the right to have your data deleted in certain circumstances.
        </Text>
        <Text>
          5. Right to restrict processing: in limited circumstances, you have the right to request that processing is stopped but the data retained.
        </Text>
        <Text>
          6. Right to data portability: you can request a copy of certain data in a machine-readable form that can be transferred to another provider.
        </Text>
        <Text>
          7. Right to object: in certain circumstances (including where data is processed on the basis of legitimate interests or for the purposes of marketing) you may object to that processing.
        </Text>
        <Text>
          8. Rights related to automated decision-making including profiling: there are several rights in this area where processing carried out on a solely automated basis results in a decision which has legal or significant effects for the individual. In these circumstances your rights include the right to ensure that there is human intervention in the decision-making process.
        </Text>
        <Text mt={1}>
          The particular rights which are applicable to you (which might include other rights not listed above) may vary depending on your country. You should make yourself aware of the rights you have under applicable privacy laws in your country.
        </Text>
      </Section>
      <Section>
        <Text variant='medium' weight='bold'>7. DATA LOCATIONS.</Text>
        <Text mt={1} mb={1}>
          We want you to be able to access Baerify wherever you happen to be in the world. To enable us to provide that service, we operate a global network of servers including in the US, UK, EU, and (for Users located in Russia) Russia. The hardware is located in third-party data centres but is owned by the Baerify Company. Data collected by Advertising Partners and other Service Providers may also be held outside the UK and the European Economic Area.
        </Text>
        <Text>
          We ensure that the data is adequately protected by ensuring that valid, legal mechanisms are in place such as: EU approved model clauses (which can be found here), and implementing robust contractual standards. If you want more information relating to the nature of the safeguards we have in place, please email DPO@team.Baerify.com.
        </Text>
      </Section>
      <Section>
        <Text weight='bold' variant='medium'>
          8. DATA RETENTION AND DELETION.
        </Text>
        <Text mt={1} mb={1}>
          We keep your personal information only as long as we need it for the legal basis relied upon (as set out in Section 2 above) and as permitted by applicable law. Please see “Profile Verification Information (Including Biometric Information)” in Section 1, above, to learn about our retention policy with respect to biometric information.
        </Text>
        <Text>
          When your Account is deleted, we make sure it is no longer viewable in the App. For up to 28 days, it is still possible to restore your Account if it was accidentally deleted. After 28 days, we begin the process of deleting your personal information from our systems, unless:
        </Text>
        <Text>
          1. we must keep it to comply with applicable law (for instance, if you make purchases within the App, some personal data may be kept for tax and accounting purposes);
        </Text>
        <Text>
          2. we must keep it to evidence our compliance with applicable law (for example, if an account is blocked, we keep some account information and a record of the behaviour that led to the block - this information is retained for evidential purposes in case of queries or legal claims concerning the block);
        </Text>
        <Text>
          3. there is an outstanding issue, claim or dispute requiring us to keep the relevant information until it is resolved; or
        </Text>
        <Text>
          4. the information must be kept for our legitimate business interests, such as fraud prevention and enhancing Users' safety and security (for example, information may need to be kept to prevent a user who was banned for unsafe behaviour or security incidents from opening a new account).
        </Text>
        <Text mt={1} mb={1}>
          Warning: Even after you remove information from your profile or delete your Account, copies of that information may still be viewable and/or accessed to the extent such information has been previously shared with others, or copied or stored by others. We cannot control this, nor do we accept any liability for this. If you have given third party applications or websites access to your personal information, they may retain such information to the extent permitted under their terms of service or privacy policies.
        </Text>
        <Text>Thanks for reading, we hope we didn't drone on for too long!</Text>
      </Section>
      <Section>
        <Text weight='bold' variant='medium'>
          9. CHANGES TO THIS POLICY.
        </Text>
        <Text mt={1} mb={1}>
          As Baerify evolves, we may revise this Privacy Policy from time to time. The most current version of the policy will govern our use of your information. If we make a change to this policy that, in our sole discretion, is material, we will notify you, for example, via an email to
          the email associated with your Account or by posting a notice within Baerify.
        </Text>
        <Text weight='bold'>Effective date</Text>
        <Text>This Privacy Policy was last updated 31st October 2022.</Text>
      </Section>
    </Wrapper>
  )
})

export default PrivacyPolicy