import React from 'react'
import CenteredView from '../../components/CenteredView'
import { ErrorLogo } from './ErrorPage.styles'
import unauthorized from '../../assets/images/401.png'
import notFound from '../../assets/images/404.png'
import forbidden from '../../assets/images/403.png'
import Text from '../../components/Text'
import { Logo } from '../../pages/PrivacyPolicy/PrivacyPolicy.styles'
import logo from '../../assets/images/logo2.png'
import Link from '../../components/Link'
import { routes } from '../../utils/constants'
import Auth from '../../utils/auth'
import Navbar from '../../components/Navbar'


type ErrorPageProps = {
  errorMessage?: boolean,
  errorCode: string
}

const ErrorPage: React.FC<ErrorPageProps> = ({
  errorMessage,
  errorCode
}) => {
  return (
    <>
      {Auth.isLoggedIn() && <Navbar />}
      <CenteredView>
        <Link to={routes.DASHBOARD}>
          <Logo src={logo} />
        </Link>
        <ErrorLogo src={ErrorImages[errorCode]} />
        <Text variant='large' weight='bold'>{errorCode}</Text>
        <Text variant='medium'>{errorMessage || ErrorMessages[errorCode]}</Text>
      </CenteredView>
    </>
  )
}

export default ErrorPage

const ErrorMessages: any = {
  '404': 'Sorry, Page not found',
  '401': 'Sorry, Page unauthorized',
  '403': 'Sorry, Page forbidden',
  '500': 'Oop! Something went wrong, please refresh page.'
}

const ErrorImages: any = {
  '404': notFound,
  '401':unauthorized,
  '403': forbidden,
  '500': notFound
}
