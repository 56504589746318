import { regexPatterns } from "./constants";

const validators = {
  required(text: string): undefined | string {
    return text ? undefined : 'Required'
  },

  requiredAndNotZero(text: string) {
    if (!text || text === '0' ) {
      return 'Please enter a non-zero value'
    }

    return undefined
  },

  max(limit: number) {
    return (value: string) => value && (value || '').length <= limit ? undefined : `Value is too long (max: ${limit} characters)`
  },

  min(limit: number) {
    return (value: string) => value && (value || '').length >= limit ? undefined : `Value is too short (min: ${limit} characters)`
  },

  isEmail(value: string): string | undefined {
    return regexPatterns.email.test(value)
      ? undefined
      : "This is not a valid email";
  },

  isPhoneNo(phoneNumber: string): string | undefined {
    return /^(\+)*(\d+)(-)*/.test(phoneNumber) ? undefined : 'Must be a phone number';
  },
  
  isPassword(password: string): string | undefined {
    return (password || '').length > 5 ? undefined : 'Password is too short';
  },
  
  isSame(password: string) {
    return (confirmPassword: string) => password === confirmPassword ? undefined : 'Password does not match'
  },

  isUsername(username: string) {
    if (!username) return undefined

    return regexPatterns.notUsername.test(username) ? 'This is not a valid username' : undefined
  }
}

export default validators
