import React, { FunctionComponent, useState } from "react"
import { observer } from "mobx-react-lite"
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { userStore } from "../../stores/userStore"
import Text from "../Text"
import {
  Wrapper, 
  UserAvatar,
  ProfileBody,
  ProfileHeader,
  EditProfileButton,
  FormWrapper
} from "./UserProfile.styles"
import { User } from "../../utils/interfaces";
import { colors } from "../../styles/_var";
import SimpleForm from "../SimpleForm";
import Input from "../Input";
import PhoneNoInput from "../PhoneNoInput";
import { Subscriptions } from "../../utils/constants";
import { isEmpty } from "lodash";
import ImageUpload from "../ImageUpload";


const UserDetails: FunctionComponent<User & { onEdit: () => void }> = React.memo(({
  first_name,
  last_name,
  phone_number,
  email,
  info,
  onEdit
}) => {
  const subscription: any = info?.subscription || ''

  const fields = [
    { key: 'Full Name', value: `${first_name} ${last_name}`},
    { key: 'Phone Number', value: phone_number },
    { key: 'Email', value: email },
    { key: 'Subscription', value: Subscriptions[subscription] },
  ]

  return (
    <>
      <Table aria-label="simple table">
        <TableBody>
          {fields.map((field, i) => (
            <TableRow key={i} sx={{ padding: '20px 0'}}>
              <TableCell component="th" scope="row">
                <Text variant="regular" color={colors.grey}>
                  {field.key}
                </Text>
              </TableCell>
              <TableCell component="th" scope="row">
                <Text variant="regular" weight="bold" right>
                  {field.value}
                </Text>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <EditProfileButton
        alignCenter 
        variant="secondary"
        onClick={onEdit}
        mt={3}
      >
        <Text variant="small">EDIT PROFILE</Text>
      </EditProfileButton>
    </>
  )
})

type UserFormProps = {
  onToggleEditMode: () => void,
  selectedAvatar?: string
}

const UserForm: FunctionComponent<UserFormProps> = observer(({
  onToggleEditMode,
  selectedAvatar
}) => {
  const [phoneExtension, setPhoneExtension] = useState('+1')
  const onSave = async (data: any) => {
    const profilePayload = preparePayload(data)
    if (selectedAvatar) {
      profilePayload['avatar'] = selectedAvatar
    }

    if (!isEmpty(profilePayload)) {
      await userStore.updateUserDetails(profilePayload)
    }
    onToggleEditMode()
  }


  const preparePayload = (data: any) => {
    const profilePayload: any = {}
    for (const key of Object.keys(data)) {
      if (data) {
        profilePayload[key] = key === 'phone_number' ? `${phoneExtension}${data[key]}` : data[key]
      }
    }
    return profilePayload
  }

  const profileFormFields = [
    {
      name: 'first_name',
      label: 'First name',
      component: Input
    },
    {
      name: 'last_name',
      label: 'Last name',
      component: Input
    },
    {
      name: 'username',
      label: 'Username',
      component: Input
    },
    {
      name: 'phone_number',
      label: ' Phone Number',
      component: PhoneNoInput,
      extension: phoneExtension,
      onExtensionChange: (e: any) => {
        setPhoneExtension(e.target.value)
      }
    }
  ]

  return (
    <FormWrapper>
      <SimpleForm 
        formFields={profileFormFields}
        actionButtons={{
          label: 'SAVE PROFILE',
          isSubmit: true,
          loading: userStore.updating,
          variant: 'secondary'
        }}
        submitForm={onSave}
      />
    </FormWrapper>
  )
})


const UserProfile = () => {
  const [editMode, setEditMode] = useState(false)
  const [selectedAvatar, setSelectedAvatar] = useState('')

  const toggleEditMode = () => {
    setEditMode(!editMode)
  }

  const onAvatarSelected = (image: string) => {
    setSelectedAvatar(image)
  }

  const { userInfo, avatarText } = userStore
  return (
    <Wrapper>
      <ProfileHeader>
        <Text variant="large" weight="bold" color="white">
          Profile
        </Text>
      </ProfileHeader>
      <ImageUpload 
        onImageChange={onAvatarSelected}
        contentArea={({ openFileChooser }) => (
        <UserAvatar 
          sx={{width: 120, height: 120}}
          src={selectedAvatar || userInfo.info?.avatar} 
          onClick={editMode ? openFileChooser : undefined}
          style={{cursor: editMode ? 'pointer' : 'unset'}}
        >
          <Text variant="large">{avatarText}</Text>
        </UserAvatar>
        )}
      />
      <ProfileBody>
        {!editMode && <Text
          variant="regular"
          weight="bold"
          center
          mb={1}
        >
          @{userInfo.username}
        </Text>}
        {editMode ? <UserForm selectedAvatar={selectedAvatar} onToggleEditMode={toggleEditMode} /> : <UserDetails {...userInfo} onEdit={toggleEditMode} />}
      </ProfileBody>
    </Wrapper>
  )
}

export default observer(UserProfile)
