import React, { FunctionComponent } from 'react'
import { faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Text from '../../../components/Text'
import { colors } from '../../../styles/_var'
import { InfoItem, PartnerInfoWrapper } from './Partners.styles'
import { faInstagram, faTwitter } from '@fortawesome/free-brands-svg-icons'

type PartnerInfoProps = {
  partner: any
}

type FieldType = {
  icon: any,
  label: string
}

const DISPLAY_FIELDS: FieldType[]  = [
  {label: 'phone', icon: faPhone},
  {label: 'email', icon: faEnvelope},
  {label: 'instagram', icon: faInstagram},
  {label: 'twitter', icon: faTwitter}
]

const PartnerInfo: FunctionComponent<PartnerInfoProps> = ({
  partner 
}) => {
  return (
    <PartnerInfoWrapper>
      <Text variant='regular' color={colors.primary} mb={1}>
        Personal Details
      </Text>
      {DISPLAY_FIELDS.map(({label, icon}) => {
        if (!(label in partner) || !partner[label]) return null
        return (
          <InfoItem key={label}>
            <FontAwesomeIcon icon={icon} size="2x" color={colors.grey} />
            <Text variant='regular' color={colors.grey} weight='bold'>
              {partner[label]}
            </Text>
          </InfoItem>
        )
      })}
    </PartnerInfoWrapper>
  )
}

export default PartnerInfo