import React from "react"
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import { Transition } from "../Dialog";
import uiStore from "../../stores/uiStore";
import { observer } from "mobx-react-lite";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "../Button";
import Text from "../Text";


const ConfirmationDialog = () => {
  const {
    confirmationDialogPayload: {
      dialogBody,
      dialogTitle,
      onCancelAction,
      onConfirmAction,
      cancelButtonText,
      confirmButtonText
    }
  } = uiStore

  const handleClose = () => {
    if (onCancelAction) onCancelAction()
    uiStore.closeConfirmDialog()
  }

  const handleProceed = () => {
    if (onConfirmAction) onConfirmAction()
    uiStore.closeConfirmDialog()
  }

  return (
    <Dialog
      open={uiStore.showConfirmationDialog}
      TransitionComponent={Transition}
      onClose={() => null}
    >
      {!!dialogTitle && (
        <DialogTitle>
          <Text variant="medium" weight='bold'>{dialogTitle}</Text>
        </DialogTitle>
      )}
      <DialogContent>
        {!!dialogBody && <Text variant="regular">{dialogBody}</Text>}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} variant="secondary">
          {cancelButtonText || 'Cancel'}
        </Button>
        <Button onClick={handleProceed} variant="primary">
          {confirmButtonText || 'Proceed'}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default observer(ConfirmationDialog)
  