import React, { FunctionComponent } from 'react'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import Link from '../../../components/Link'
import Text from '../../../components/Text'
import TextWithIcon from '../../../components/TextWithIcon'
import DashboardWithSidebar from '../../../layouts/DashboardWithSidebar'
import { colors, spacing } from '../../../styles/_var'
import { routes } from '../../../utils/constants'
import { PartnerItemHeader } from './Partners.styles'
import { observer } from 'mobx-react-lite'
import PartnerForm from './PartnerForm'

const CreatePartner: FunctionComponent = () => {
  return (
    <DashboardWithSidebar>
      <PartnerItemHeader style={{marginBottom: spacing.xs}}>
        <Text variant='medium' weight='bold'>
          Add a Partner
        </Text>
        <Link to={routes.MY_PARTNERS}>
          <TextWithIcon
            variant='regular'
            icon={faArrowLeft}
            link 
            color={colors.primary}
          >
            Back to partners
          </TextWithIcon>
        </Link>
      </PartnerItemHeader>
      <PartnerForm />
    </DashboardWithSidebar>
  )
}

export default observer(CreatePartner)
