import styled from "styled-components";
import { colors, spacing } from "../../styles/_var";

export const Wrapper = styled(({ deleting, notifying, ...rest }) => <div {...rest} />)`
  display: flex;
  flex-direction: row;
  border-top: solid 1px ${colors.lightgrey};
  padding-top: ${spacing.xs};
  ${({ notifying }) => notifying && `
    background-color: ${colors.lightyellow};
  `}
  ${({ deleting }) => deleting && `
    opacity: 0.4;
  `}

  svg {
    cursor: pointer;
    transition: all ease .2s;
    &:hover {
      opacity: .5;
    }
  }
`

export const CommentBody = styled.div`
  margin-bottom: ${spacing.xs};
  flex: 1;
`

export const CommentInfo = styled.div`
  display: flex;
  p {
    margin-right: ${spacing.xs};
  }
`

export const CommentContent = styled.div`
  margin-left: ${spacing.xxs};
  margin-right: ${spacing.xxs};
  flex: 1;
`

export const CommentWrapper = styled.div`
  display: flex;
`

export const ReplyWrapper = styled.div``