import { observer } from "mobx-react-lite"
import React, { useState } from "react"
import uiStore from "../../stores/uiStore"
import { deleteAccountStore } from "../../stores/userStore"
import { colors } from "../../styles/_var"
import validators from "../../utils/validators"
import Input from "../Input"
import SimpleForm from "../SimpleForm"
import Text from "../Text"
import { Wrapper } from "./CloseAccount.styles"


const CloseAccount = () => {
  const [showDeleteForm, setShowDeleteForm] = useState(false)
  const onSubmitForm = (data: any) => {
    uiStore.openConfirmDialog({
      dialogTitle: 'Confirmation Request.',
      dialogBody: 'Are you sure you want to do this? This action is irreversible',
      onConfirmAction: () => deleteAccountStore.makeRequest(data)
    })
  }

  return (
    <Wrapper>
      <Text
        link
        underline
        mb={2}
        variant="regular"
        color={colors.primary}
        onClick={() => setShowDeleteForm(!showDeleteForm)}
      >
        Close Account (This is not reversible)
      </Text>
      {showDeleteForm && <SimpleForm
        formFields={[
          {
            name: 'password',
            placeholder: 'Enter password to confirm',
            component: Input,
            type: 'password',
            validate: validators.isPassword
          }
        ]}
        actionButtons={{
          label: 'DELETE ACCOUNT',
          isSubmit: true,
          loading: deleteAccountStore.loading,
          style: {width: '20rem'}
        }}
        submitForm={onSubmitForm}
      />}
    </Wrapper>
  )
}

export default observer(CloseAccount)
  