import React, { FunctionComponent } from 'react'
import { useNavigate } from 'react-router-dom'
import BackButton from '../../../components/BackButton'
import Text from '../../../components/Text'
import DashboardWithSidebar from '../../../layouts/DashboardWithSidebar'
import { myStoryStore } from '../../../stores/storyStore'
import uiStore from '../../../stores/uiStore'
import { routes, StoryType } from '../../../utils/constants'
import { Story } from '../../../utils/interfaces'
import CreateStoryForm from './CreateStoryForm'


const CreateStory: FunctionComponent = () => {
  const navigate = useNavigate()
  const onCreateStory = (story: Story) => {
    let successMessage: string

    if (story.type === StoryType.COMPLETE) {
      successMessage = 'Story successfully created.'
    } else {
      successMessage = 'Story saved to draft.'
    }
    uiStore.showSuccessMessage(successMessage)
    navigate(routes.STORIES)
    myStoryStore.makeRequest(undefined, { page: 1 })
  }

  return (
    <DashboardWithSidebar>
      <BackButton to={routes.STORIES} />
      <Text variant='medium' weight='bold' mt={2} mb={2}>
        SHARE YOUR STORY
      </Text>
      <CreateStoryForm onComplete={onCreateStory} />
    </DashboardWithSidebar>
  )
}

export default CreateStory
