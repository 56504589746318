import React, { FunctionComponent } from 'react'
import { LinkProps } from 'react-router-dom'
import { getStyleSpacing } from '../../utils/helpers'
import { ComponentSpacing } from '../../utils/interfaces'
import { ButtonVariant } from '../Button/'
import { StyledButtonLink, StyledLink } from './Link.styles'


export interface ILinkprops extends LinkProps, ComponentSpacing {
  useButton?: boolean;
  variant?: ButtonVariant;
  invertOnHover?: boolean;
  boldLink?: boolean;
  block?: boolean;
  style?: React.CSSProperties
}

export const Link: FunctionComponent<ILinkprops> = React.memo(({
  useButton,
  variant='primary',
  invertOnHover=false,
  block,
  mt,
  mb,
  mr,
  ml,
  style={},
  ...props
}) => {
  const spacingStyle = getStyleSpacing({mt, mb, mr, ml})
  if (useButton) {
    return (
      <StyledButtonLink
        role="button"
        variant={variant}
        invertOnHover={invertOnHover}
        style={{ ...spacingStyle, ...style }}
        block={block}
        {...props}
      />
    )
  } else {
    return <StyledLink {...props} style={spacingStyle} />
  }
})

export default Link
