import { Status, ServerErrorMessage, RequestType, IPagination } from '../utils/interfaces'
import { appendQueryString, fetch, retrieveErrorMessage } from "../utils/helpers";
import { getPaginationDefaults, status } from "../utils/constants";
import { makeObservable, computed, observable, action } from 'mobx';
import uiStore from './uiStore';

export type Config<T> = {
  url: string,
  page?: number,
  isPaginated?: boolean,
  responseKey: string,
  method: RequestType,
  onRequestComplete: (serverData: T | string) => void,
  onRequestFailed: (errorData: ServerErrorMessage<T>) => void,
  useToastOnError?: boolean,
  useToastOnSuccess?: boolean,
  toastDuration?: number,
  idKey?: string,
  itemId?: string
}

export const defaultConfig: Config<any> = {
  url: '',
  responseKey: '',
  method: 'GET',
  isPaginated: false,
  onRequestComplete: () => null,
  onRequestFailed: () => null,
  useToastOnError: true,
  useToastOnSuccess: false,
  toastDuration: 10000,
  idKey: 'id'
}

export default class FieldStore <DataType, RequestType=DataType> {
  status: Status = null
  errorMessage: ServerErrorMessage<DataType> = ''
  data: DataType | null = null
  config = defaultConfig
  paginationInfo: IPagination = getPaginationDefaults()

  constructor() {
    makeObservable(this, {
      data: observable,
      errorMessage: observable,
      status: observable,
      setData: action,
      setStatus: action,
      setErrorMessage: action,
      clear: action,
      makeRequest: action,
      loading: computed,
      complete: computed,
      failed: computed,
      notFetchedYet: computed,
      setPaginationInfo: action,
      removeItem: action,
      getItem: action,
      updateItem: action
    })
  }

  setData(data: any) {
    const { isPaginated } = this.config

    if (isPaginated && this.currentPage > 1) {
      this.data = [...(this.data || [] as any), ...data] as any
    } else {
      this.data = data
    }
  }

  appendData(data: any) {
    this.data = [data, ...(this.data as any)] as any
  }

  setStatus(status: Status) {
    this.status = status
  }

  setErrorMessage(message: ServerErrorMessage<DataType>) {
    this.errorMessage = message
  }

  clear() {
    this.setStatus(null)
    this.data = null
    this.errorMessage = ''
    this.paginationInfo = getPaginationDefaults()
  }

  removeItem(itemId: string | number, idKey: string='uuid') {
    if (!Array.isArray(this.data)) return
    this.data = this.data.filter(item => item[idKey] !== itemId) as DataType
  }

  updateItem(data: any, key?: string) {
    const idKey = key || this.config.idKey || ''
    if (!this.config.isPaginated || !Array.isArray(this.data)) return

    this.data = this.data.map((item: any) => item[idKey] === data[idKey] ? data : item) as DataType
  }

  setPaginationInfo(data: IPagination) {
    this.paginationInfo = data
  }

  getItem(itemId: string, itemKey: string ='id') {
    if (Array.isArray(this.data)) {
      return this.data.find(e => e[itemKey] === itemId)
    }

    return null
  }

  async makeRequest(payload?: RequestType, configOverride: Partial<Config<any>> = {}) {
    if (this.loading) return
    this.setStatus(status.LOADING)
    const {
      page,
      isPaginated,
      url,
      method,
      onRequestComplete,
      useToastOnSuccess,
      toastDuration
    } = this.getConfig(configOverride)
    
    try {
      let requestURL;
      // if (!this.hasNext) return 
      if (isPaginated) {
        requestURL = appendQueryString(url, { page: page ? page : this.currentPage + 1})
      } else {
        requestURL = url
      }
      const response = await fetch({ url: requestURL, method, body: payload })

      this.setStatus(status.COMPLETE)
      this.setPaginationInfo(response.data.pagination_info || getPaginationDefaults())
      this.setData(response.data.results)

      onRequestComplete(response.data)
      if (useToastOnSuccess) {
        uiStore.showToastMessage({
          message: response.data.message || 'Success',
          severity: 'success',
          duration: toastDuration 
        })
      }
    } catch (e) {
      this.processError(e)
    }
  }

  processError(e: any) {
    const {onRequestFailed, useToastOnError, toastDuration} = this.config
    const errorMessage = retrieveErrorMessage(e)
    this.setStatus(status.FAILED)
    this.setErrorMessage(errorMessage)
    onRequestFailed(errorMessage)

    if (useToastOnError) {
      uiStore.showToastMessage({
        message: errorMessage,
        severity: 'error',
        duration: toastDuration 
      })
    }
  }

  getConfig(configOverride: Partial<Config<any>>) {
    return {...this.config, ...configOverride}
  }

  get loading() {
    return this.status === status.LOADING
  }

  get complete() {
    return this.status === status.COMPLETE
  }

  get failed() {
    return this.status === status.FAILED
  }

  get hasData() {
    if (Array.isArray(this.data)) return !!this.data.length
    return !!this.data
  }

  get currentPage() {
    return this.paginationInfo.current_page
  }

  get hasNext() {
    return this.paginationInfo.has_next
  }

  get showNextButton() {
    return this.hasNext && this.currentPage !== 0
  }

  get notFetchedYet() {
    return this.status === null
  }
}
