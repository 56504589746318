import styled from "styled-components";
import Box from "../../../components/Box";
import Button from "../../../components/Button";
import TextWithIcon from "../../../components/TextWithIcon";
import { colors, fontSize, spacing } from "../../../styles/_var";

export const Wrapper = styled.div`
  @media (max-width: 400px) {
    .MuiTab-root {
      min-width: 40px;
      p {
        font-size: 1.2rem;
      }
    } 
  }


`

export const PostStoryButton = styled(Button)`
  width: 30rem;
  font-weight: 500;
`

export const ActionButtons = styled.div`
  display: flex;
  justify-content: end;
  margin-bottom: ${spacing.sm};
`

export const AddStoryPhoto = styled(TextWithIcon)`
  width: 18rem;
  & p {
    text-decoration: underline;
  }
`

export const StoryImageThumbnail = styled(({ uploading, alt, ...rest }) => <img {...rest} alt={alt} />)`
  width: 50%;
  max-width: 20rem;
  height: auto;
  margin-bottom: ${spacing.xs};
  ${({ uploading }) => uploading && `
    opacity: 0.5;  
  `}
`

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: ${spacing.rg};
`

export const Content = styled(Box)`
  font-size: ${fontSize.rg};
  line-height: 2.5rem;
`

export const StoryThumbnail = styled(({ src, ...rest }) => <div {...rest} />)`
  width: 90%;
  height: 30rem;
  margin-right: auto;
  margin-left: auto;
  background-size: cover;
  ${({ src }) => src && `
    background-image: url(${src});
  `}
`

export const ThumbnailWrapper = styled.div`
  background-color: ${colors.black};
  width: 100%;
  margin-bottom: ${spacing.xs};
  margin-top: ${spacing.xxs};
`

export const StoryInfoSection = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: ${spacing.sm};
  margin-bottom: ${spacing.sm};
`

export const LikeButton = styled(TextWithIcon)`
  & > svg path {
    fill: ${colors.primary};
  }
`
