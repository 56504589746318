import React, { FunctionComponent } from "react"
import { colors } from "../../styles/_var"
import { Wrapper, HeaderSection, StoryInfoSection, Title, SectionOne, SectionTwo, Thumbnail } from "./StoryThumbnail.styles"
import TextWithIcon from "../TextWithIcon"
import { faClock, faComment, faHeart, faPen, faTrash } from "@fortawesome/free-solid-svg-icons"
import { ComponentSpacing, Story } from "../../utils/interfaces"
import { capitalize, getStyleSpacing, humanize } from "../../utils/helpers"
import Dot from "../Dot"
import { routes, StoryType } from "../../utils/constants"
import { userStore } from "../../stores/userStore"
import Link from "../Link"
import AvatarWithLabel from "../AvatarWithLabel"

export type StoryThumbnailProps = {
  uuid: string,
  title: string,
  story: string,
  storyImage?: string,
  likes: number,
  likers?: string[],
  comments: any[],
  author: {
    avatar: string,
    username: string
  }
}

export type StoryActions = {
  onDelete?: (storyId: string) => void,
  onEdit?: (storyId: string) => void,
}

const StoryThumbnail: FunctionComponent<Story & StoryActions & ComponentSpacing> = React.memo(({
  title,
  uuid='',
  owner={
    username: ''
  },
  type,
  likes,
  comments,
  thumbnail_image,
  published,
  created_at='',
  mb,
  ml,
  mt,
  mr,
  onDelete=() => {},
  onEdit=() => {}
}) => {
  const spacingStyle = getStyleSpacing({ mt, mb, mr, ml })
  const belongsToYou = owner.username === userStore.username
  const isDraft = type === StoryType.DRAFT
  const isComplete = type === StoryType.COMPLETE

  const deleteStory = () => {
    if (uuid) onDelete(uuid)
  } 
  
  const editStory = () => {
    if (uuid) onEdit(uuid)
  }

  return (
    <Wrapper style={spacingStyle}>
      <SectionOne>
        <HeaderSection>
          <AvatarWithLabel
            label={owner.username}
            src={owner.info?.avatar} 
            labelProps={{variant: 'small'}}
          />
        </HeaderSection>
        <Link to={routes.VIEW_STORY.replace(':storyId', uuid)}>
          <Title link variant="medium" weight='bold' mb={1} mt={1} color={colors.darkgrey}>
            {capitalize(title)}
          </Title>
        </Link>
        <StoryInfoSection>
          <TextWithIcon variant='small' icon={faClock} color={colors.darkgrey}>
            {capitalize(humanize(created_at))}
          </TextWithIcon>
          <Dot ml={1} mr={1} />
          <TextWithIcon variant='small' icon={faComment} color={colors.darkgrey}>
            {comments || 0}
          </TextWithIcon>
          <Dot ml={1} mr={1} />
          <TextWithIcon variant='small' icon={faHeart} color={colors.darkgrey}>
            {likes || 0}
          </TextWithIcon>
          {(isDraft || isComplete) && belongsToYou && !published && (
            <>
              <Dot ml={1} mr={1} />
              <TextWithIcon
                icon={faTrash}
                color={colors.darkgrey}
                onClick={deleteStory}
              >
                delete
              </TextWithIcon>
            </>
          )}
          {isDraft && belongsToYou && !published && (
            <>
              <Dot ml={1} mr={1} />
              <TextWithIcon
                icon={faPen}
                color={colors.darkgrey}
                onClick={editStory}
              >
                edit
              </TextWithIcon>
            </>
          )}
        </StoryInfoSection>
      </SectionOne>
      <SectionTwo>
        <Thumbnail src={thumbnail_image} />
      </SectionTwo>
    </Wrapper>
  )
})


export default StoryThumbnail
