import React from 'react'
import { Wrapper, Container } from './CenteredView.styles'

const CenteredView: React.FC<{children: React.ReactNode}> = React.memo(({
  children
}) => {
  return (
    <Wrapper>
      <Container>
        {children}
      </Container>
    </Wrapper>
  )
})

export default CenteredView