import React from 'react'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Text from '../../../components/Text'
import DashboardWithSidebar from '../../../layouts/DashboardWithSidebar'
import { Wrapper, PostStoryButton } from './Stories.styles'
import Tabs from '../../../components/Tabs'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { routes } from '../../../utils/constants'
import RecentStories from './RecentStories'
import MyStories from './MyStories'
import MyPublishedStories from './MyPublishedStories'
import Drafts from './Drafts'

const RightArrow = () => <FontAwesomeIcon icon={faArrowRight} />

const Stories = () => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const tab = searchParams.get('tab') || 'stories'

  return (
    <DashboardWithSidebar>
      <Wrapper>
        <PostStoryButton
          block
          alignCenter
          mb={3}
          adornmentPosition='end'
          adornment={<RightArrow />}
          onClick={() => navigate(routes.CREATE_STORY)}
        >
          <Text variant='regular'>CREATE A STORY</Text>
        </PostStoryButton>
        <Tabs
          initialTab={TABS[tab]}
          tabData={{
            recent: {
              node: <RecentStories />,
              label: 'Stories'
            },
            myStories: {
              node: <MyStories />,
              label: 'My Stories'
            },
            published: {
              node: <MyPublishedStories />,
              label: 'Published'
            },
            drafts: {
              node: <Drafts />,
              label: 'Drafts'
            }
          }}
        />
      </Wrapper>
    </DashboardWithSidebar>
  )
}

export default Stories

const TABS: any = {
  stories: 0,
  mine: 1,
  published: 2,
  drafts: 3,
}