import React, { FunctionComponent } from 'react'
import { PaymentFormWrapper } from './AddCreditCard.styles'
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { PaymentMethod } from '@stripe/stripe-js'
import Text from '../Text'
import Button from '../Button'
import uiStore from '../../stores/uiStore'

type AddCreditCardProps = {
  onSaveCard: (paymentMethod: PaymentMethod) => void,
  saving?: boolean
}

const AddCreditCard: FunctionComponent<AddCreditCardProps> = ({
  onSaveCard,
  saving
}) => {
  const elements = useElements()
  const stripe = useStripe()

  const onSubmitForm = async () => {
    const cardElement = elements?.getElement("card")
    if (!cardElement) return

    const paymentMethodResult = await stripe?.createPaymentMethod({
      card: cardElement,
      type: 'card'
    })

    if (paymentMethodResult?.error) {
      uiStore.showToastMessage({message: paymentMethodResult.error.message || 'something went wrong', severity: 'error'})
      return
    }

    if (paymentMethodResult) {
      onSaveCard(paymentMethodResult.paymentMethod)
    }
  }

  return (
    <PaymentFormWrapper>
      <Text weight="bold" center mb={1}>Please add your card details</Text>
      <CardElement />
      <Button loading={saving} variant="secondary" alignCenter mt={1} onClick={onSubmitForm}>
        Save Card
      </Button>
    </PaymentFormWrapper>
  )
}

export default AddCreditCard