import React from 'react'
import { NavbarContent } from '../NavbarShell/NavbarShell.styles'
import MenuItems from '../MenuItems'
import { NavbarFooterWrapper } from './NavFooter.styles'
import useIsMobile from '../../hooks/useIsMobile'


const NavFooter = React.memo(() => {
  const isMobile = useIsMobile()

  if (!isMobile) return null

  return (
    <NavbarFooterWrapper>
      <NavbarContent>
        <MenuItems />
      </NavbarContent>
    </NavbarFooterWrapper>
  )
})

export default NavFooter

