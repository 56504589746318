import { useEffect } from "react"
import { partnerStore, pictureEvidenceStore } from "../stores/partnerStore"
import { PartnerType } from "../utils/interfaces"

const useGetPartner = (partnerId?: string) => {
  useEffect(() => {
    if (!partnerId) return

    // clear data
    partnerStore.setItem(null)
    const partnerFromList = partnerStore.getFromList(partnerId, 'uuid')

    if (partnerFromList) {
      partnerStore.setItem(partnerFromList)
      pictureEvidenceStore.setListData(partnerFromList.evidence || [])
    }

    partnerStore.retrieveEntity({
      itemId: partnerId,
      shouldUseToast: false,
      onRetrieveItem: (data) => {
        pictureEvidenceStore.setListData(data.evidence || [])

        if (partnerFromList) {
          const updatedPartner: PartnerType = {...partnerFromList, evidence: data.evidence || []}
          partnerStore.updateInListBy('uuid', partnerId, updatedPartner)
        }
      },
    })

    return () => {
      partnerStore.setItem(null)
      pictureEvidenceStore.clearData()
    }
  }, [partnerId])
}

export default useGetPartner