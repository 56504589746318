import styled from "styled-components"
import MAvatar from "@mui/material/Avatar"
import { colors, spacing } from "../../styles/_var"


export const Menu = styled(({ showMenu, ...rest }) => <div {...rest} />)`
  position: absolute;
  background: ${colors.white};
  box-shadow: 0 0 3px 3px ${colors.lightgrey};
  top: 5.6rem;
  right: 1rem;
  width: 15rem;
  border-radius: 1rem;
  transition: all ease .1s;

  ${({ showMenu }) => `
    opacity: ${showMenu ? 1 : 0 };
    transform: scale(${showMenu ? 1 : 0});
  `}
`


export const MenuItem = styled.div`
  display: flex;
  padding: ${spacing.xxs} ${spacing.sm};
  cursor: pointer;
  transition: background-color ease .2s;
  align-items: center;
  color: ${colors.grey};
  text-decoration: none;

  svg > path {
    fill: ${colors.grey};
  }

  &:hover {
    background-color: ${colors.lightgrey};
    color: ${colors.primary};

    svg > path {
      fill: ${colors.primary};
    }
  }
`

export const Wrapper = styled.div``

export const AvatarWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  transition: all ease .2s;

  &:hover {
    opacity: .8;
  }
`

export const Avatar = styled(MAvatar)`
  display: block;
  border-radius: 50%;
  box-sizing: border-box;
  border: solid 2px ${colors.grey};
  margin-right: 5px;
`