import React, { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import DashboardWithSidebar from '../../../layouts/DashboardWithSidebar'
import { colors } from '../../../styles/_var'
import { Wrapper } from './BaerificationResults.styles'
import Text from '../../../components/Text'
import BaerificationLoader from './BaertificationLoader'
import { routes } from '../../../utils/constants'
import uiStore from '../../../stores/uiStore'
import { baerificationStore } from '../../../stores/partnerStore'
import ConflictResults from '../../../components/ConflictResults'
import BackButton from '../../../components/BackButton'
import EmptyState from '../../../components/EmptyState'

const BaerificationResults = () => {
  const [loadingPageSimulation, setLoadingPageSimulation] = useState(true)
  const [shouldRenderPage, setShouldRenderPage] = useState(false)
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const searchBy = searchParams.get('searchby')
  const rawValue = searchParams.get('value')
  const value = searchBy === 'phone' ? `+${rawValue}` : rawValue 

  useEffect(() => {
    if (!searchBy || !value) {
      navigate(routes.DASHBOARD)
      uiStore.showErrorMessage('Search params missing')
    } else {
      setShouldRenderPage(true)
    }
  }, [searchBy, value, navigate])

  useEffect(() => {
    let timer: NodeJS.Timeout

    if (shouldRenderPage && searchBy) {
      baerificationStore.makeRequest({
        [searchBy]: value?.replace(/ /g , ''),
      })

      timer = setTimeout(() => {
        setLoadingPageSimulation(false)
      }, 100)
    }

    return () => {
      baerificationStore.clear()
      if (timer) clearTimeout(timer)
    }
  }, [shouldRenderPage, searchBy, value])

  if (!shouldRenderPage) return null

  const renderContent = () => {
    return (
      <>
        <Text
          color={colors.darkgrey}
          variant='small'
          weight='bold'
          mb={3}
        >
          Showing Results for: {value}
        </Text>
        {baerificationStore.hasData ? (
          <ConflictResults results={baerificationStore.data} subject={value || ''} />
        ) : (
          <EmptyState message='No Baes found for this person.' />
        )}
      </>
    )
  }

  return (
    <DashboardWithSidebar>
      <Wrapper>
        <BackButton />
        {baerificationStore.loading || loadingPageSimulation ? <BaerificationLoader /> : renderContent()}
      </Wrapper>
    </DashboardWithSidebar>
  )
}

export default observer(BaerificationResults)


