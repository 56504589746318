import React, { useState } from "react"
import { observer } from "mobx-react-lite"
import { Wrapper } from './BillingInformation.styles'
import { creditCardStore, defaultCreditCardStore } from "../../stores/paymentStore"
import ListOptions from "../ListOptions"
import { capitalize, formatToCurrency } from "../../utils/helpers"
import Text from "../Text"
import { colors } from "../../styles/_var"
import TextWithIcon from "../TextWithIcon"
import { faCreditCard, faPlus, faTimes } from "@fortawesome/free-solid-svg-icons"
import uiStore from "../../stores/uiStore"
import { apiRoutes } from "../../utils/constants"
import { userStore } from "../../stores/userStore"
import AddCreditCard from "../StripePayment/AddCreditCard"
import { PaymentMethod } from "@stripe/stripe-js"


const BillingInformation = () => {
  const [showForm, setShowForm] = useState(false)
  const deleteCC = (cardID: string) => () => {
    uiStore.openConfirmDialog({
      dialogTitle: 'Confirmation request',
      dialogBody: 'Are you sure you want to delete this credit card?',
      onConfirmAction: () => {
        creditCardStore.deleteEntity({ itemId: cardID })
      }
    })
  }

  const makeDefault = (cardID: string) => () => {
    defaultCreditCardStore.makeRequest(null, { 
      url: apiRoutes.MAKE_DEFAULT_CREDIT_CARD(cardID),
      onRequestComplete: () => {
        creditCardStore.makeDefault(cardID)
      }
    })
  }

  const list = (creditCardStore.list.map(cc => ({
    value: cc.uuid,
    label: (
      <TextWithIcon weight='bold' icon={faCreditCard} color={colors.darkgrey}>
        {capitalize(cc.card_type)} - ***{cc.last4} ({cc.exp_month}/{cc.exp_year})
        </TextWithIcon>
    ),
    Action: (
      <div>
        <Text
          variant="small"
          color={colors.primary}
          onClick={makeDefault(cc.uuid)}
          link
          inline
          underline
        >
          Make Default
        </Text>
        <Text 
          variant="small" 
          color={colors.primary} 
          ml={1} 
          onClick={deleteCC(cc.uuid)}
          link 
          inline 
          underline
        >
          Delete
        </Text>
      </div>
    )
  })))

  const defaultCard = creditCardStore.list.find(cc => cc.is_default)

  const saveCard = async (paymentMethod: PaymentMethod) => {
    creditCardStore.createEntity({payment_method: paymentMethod}, {onCreatedItem: async () => {
      await Promise.all([
        creditCardStore.listEntity(),
        userStore.fetchUserDetails(userStore.username)
      ])
      setShowForm(false)
    }})
  }

  return (
    <Wrapper>
      <ListOptions
        lists={list}
        selectItemLabel='Make Default | Delete'
        value={defaultCard?.uuid}
      />
      {!showForm && <>
        {list.length === 0 && <Text variant="regular" center color={colors.danger}>You have not added a credit card yet</Text>}
        <Text variant="regular" weight='bold' color={colors.darkgrey} right>Credit: {formatToCurrency(`${userStore.credit}`)}.00</Text>
      </>}
      { showForm && <AddCreditCard onSaveCard={saveCard} saving={creditCardStore.creating} />}
      <TextWithIcon
        mt={2}
        icon={showForm ? faTimes : faPlus}
        variant="regular"
        link
        center
        color={colors.primary}
        onClick={() => setShowForm(!showForm)}
      >
        {showForm ? 'Cancel' : (defaultCard ? 'Replace Card' : 'Add New Card')}
      </TextWithIcon>
    </Wrapper>
  )
}


export default observer(BillingInformation)
  