import styled from "styled-components";
import { colors } from "../../styles/_var";

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

export const BaeImage = styled(({ src, ...rest }) => <div {...rest} />)`
  width: 10rem;
  height: 10rem;
  border-radius: 50%;
  border: solid 4px ${colors.primary};

  ${({ src }) => src && `
    background-image: url('${src}');
  `}
  background-size: cover;
`

export const HeartIcon = styled.div`
  border-radius: 50%;
  width: 6rem;
  height: 6rem;
  background-color: ${colors.pink};
  text-align: center;
  margin-left: -2rem;
  margin-right: -2rem;
  position: relative;
  z-index: 4;

  svg {
    width: 2.7rem;
    height: 2.7rem;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
  }

  path {
    fill: white;
  }
`