import styled from "styled-components";
import { spacing } from "../../styles/_var";

export const Wrapper = styled.div`
  padding: ${spacing.xxs};
  svg {
    border-radius: 50%;
    margin-right: 10px;
    width: 30px;
    height: 30px;
  }
`
