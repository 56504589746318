import React from 'react'
import EmptyState from '../../../components/EmptyState'
import DashboardWithSidebar from '../../../layouts/DashboardWithSidebar'
import { notificationsStore } from '../../../stores/notificationStore'
import { colors } from '../../../styles/_var'
import NotificationView from './NotificationView'
import { Wrapper, NotificationWrapper } from './Notifications.styles'
import { observer } from 'mobx-react-lite'
import TextWithIcon from '../../../components/TextWithIcon'
import { faBell } from '@fortawesome/free-solid-svg-icons'
import Text from '../../../components/Text'
import { INotification } from '../../../utils/interfaces'


const Notifications = () => {
  const markAsRead = (notification: INotification) => () => {
    if (notification.read) return
    notificationsStore.markAsRead(notification)
  }

  return (
    <DashboardWithSidebar>
      <Wrapper>
        <TextWithIcon icon={faBell} variant="medium" uppercase iconSize='2x' color={colors.grey}>
          Notifications
        </TextWithIcon>
        {notificationsStore.complete && !notificationsStore.hasData && (
          <EmptyState message='You have no notifications' mt={4} />
        )}
        <NotificationWrapper>
          {notificationsStore.hasData && notificationsStore.data?.map((notification, i) => (
            <NotificationView key={i} {...notification} onClick={markAsRead(notification)} />
          ))}
        </NotificationWrapper>
        {notificationsStore.hasNext && (
          <Text 
            variant='small'
            color={colors.primary}
            link
            italic
            onClick={() => notificationsStore.makeRequest()}
          >
            Load More...
          </Text>
        )}
      </Wrapper>
    </DashboardWithSidebar>
  )
}

export default observer(Notifications)
