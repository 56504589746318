import React, { FunctionComponent } from "react"
import { getStyleSpacing } from "../../utils/helpers"
import { ComponentSpacing } from "../../utils/interfaces"
import { Wrapper  } from "./Dot.styles"

const Dot: FunctionComponent<ComponentSpacing> = (props) => {
  const style = getStyleSpacing(props)

  return (
    <Wrapper style={style}>·</Wrapper>
  )
}

export default Dot
  