import { faInfoCircle, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { FunctionComponent, useState } from 'react'
import Box from '../../../components/Box'
import Button from '../../../components/Button'
import ImageList, { ImageType } from '../../../components/ImageList'
import ImageUpload from '../../../components/ImageUpload'
import Input from '../../../components/Input'
import Text from '../../../components/Text'
import uiStore from '../../../stores/uiStore'
import { colors } from '../../../styles/_var'
import { PartnerEvidenceType } from '../../../utils/interfaces'
import { EvidenceActionButtons, ImageCaptionWrapper } from './Partners.styles'

type PartnerEvidenceProps = {
  evidence: PartnerEvidenceType[],
  onAdd: (picture: string, caption?: string) => void,
  onRemove: (evidence: PartnerEvidenceType) => void,
}

const PartnerEvidence: FunctionComponent<PartnerEvidenceProps> = ({
  evidence,
  onAdd,
  onRemove
}) => {
  const numOfPhotos = evidence.length
  const evidenceSelected = (picture: string) => {
    uiStore.openDialog({ dialogBody: <ImageCaption picture={picture} onComplete={(picture, caption) => onAdd(picture, caption)} /> })
  }

  
  return (
    <>
      <Text variant='regular' color={colors.primary} mt={4}>
        {numOfPhotos} photos
      </Text>
      {numOfPhotos === 0 && <NoPhotoWarning />}
      {numOfPhotos > 0 && (
        <ImageList 
          variant='masonry'
          isLoadingIfNoId
          photos={evidence as ImageType[]} 
          actionIcon={<FontAwesomeIcon icon={faTrash} color={colors.white} />}
          onActionClick={item => onRemove(item)}
        />
      )}
      <ImageUpload
        noPreview
        maxWidth={600}
        onImageChange={evidenceSelected}
        contentArea={({ openFileChooser }) => (
          <Button 
            variant='secondary'
            alignCenter 
            mt={2}
            mb={2}
            onClick={openFileChooser}
            adornment={<FontAwesomeIcon icon={faPlus} />}
            adornmentPosition='start'
          >
            Add Photo
          </Button>
        )}
      />
    </>
  )
}

export default PartnerEvidence

const NoPhotoWarning = React.memo(() => (
  <Box style={{display: 'flex', justifyContent: 'center', flexDirection: 'column'}}>
    <FontAwesomeIcon icon={faInfoCircle} size="5x" color={colors.danger} />
    <Text
      variant='regular'
      weight='bold'
      color={colors.danger}
      center 
      maxWidth={300}
      mt={1}
    >
      Is this bae truly your bae? Add photos of you with your bae to complete the verification process of this partner.
    </Text>
  </Box>
))

type ImageCaptionProps = {
  picture: string,
  onComplete: (picture: string, caption?: string) => void,
}

const ImageCaption: FunctionComponent<ImageCaptionProps> = React.memo(({
  picture,
  onComplete
}) => {
  const [caption, setCaption] = useState('')
  const captionChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setCaption(e.target.value)
  }

  return (
    <ImageCaptionWrapper>
      <Text variant='medium' weight='bold' center mb={1}>PREVIEW</Text>
      <img src={picture} width={400} height='auto' alt='parner evidence' />
      <Input
        onChange={captionChange}
        value={caption}
        variant='filled'
        placeholder='Add caption (optional)' 
      />
      <EvidenceActionButtons>
        <Button
          variant='secondary'
          mr={2}
          onClick={() => uiStore.closeDialog()}
        >
          Cancel
        </Button>
        <Button onClick={() => onComplete(picture, caption)}>
          Save
        </Button>
      </EvidenceActionButtons>
    </ImageCaptionWrapper>
  )
})