import React from 'react';
import Link from '../../components/Link';
import NavbarShell from '../../components/NavbarShell';
import { routes } from '../../utils/constants';
import { LandingPageNavButtons } from './LandingPage.styles';

const LandingPageNav = React.memo(() => {
  return (
    <NavbarShell>
      <LandingPageNavButtons>
        <Link useButton to={routes.LOGIN} mr={1}>
          Login
        </Link>
        <Link useButton to={routes.REGISTER} variant='secondary'>
          Sign Up
        </Link>
      </LandingPageNavButtons>
    </NavbarShell>
  )
})

export default LandingPageNav