import React from 'react'
import { action, makeObservable, observable } from "mobx";
import { apiRoutes, methods } from "../utils/constants";
import { SubscriptionType } from "../utils/interfaces";
import EntityStore, { defaultConfig, Config } from "./entityStore";
import FieldStore, { defaultConfig as fieldStoreDefault, Config as FieldStoreConfig } from "./fieldStore";
import uiStore from "./uiStore";
import { userStore } from "./userStore";
import UserAccount from '../components/UserAccount';


class CreditCardStore extends EntityStore<any> {
  config: Config = {
    ...defaultConfig,
    url: apiRoutes.CREDIT_CARDS,
    shouldAddToList: false,
    idKey: 'uuid'
  }

  savingCard = true

  constructor() {
    super()
    makeObservable(this, { 
      makeDefault: action,
      savingCard: observable
    })
  }

  makeDefault(cardID: string) {
    const cards = this.list.map(card => {
      card.is_default = card.uuid === cardID
      return card
    })

    this.setListData(cards)
  }

  getDefault() {
    return this.list.find(card => card.is_default)
  }

  hasDefault() {
    return !!this.getDefault()
  }
}

class DefaultCreditCardStore extends FieldStore<any> {
  config: FieldStoreConfig<any> = {
    ...fieldStoreDefault,
    method: methods.POST,
    useToastOnError: true,
    useToastOnSuccess: true,
  }
}

class UpgradeSubscriptionStore extends FieldStore<any> {
  config: FieldStoreConfig<any> = {
    ...fieldStoreDefault,
    url: apiRoutes.SUBSCRIPTION,
    method: methods.POST,
    useToastOnError: true,
    useToastOnSuccess: true,
  }

  async upgrade(type: SubscriptionType, ref?: string) {
    uiStore.setLoadingScreenMessage('Upgrading your subscription')
    try {
      await upgradeSubscriptionStore.makeRequest({ type })
  
      uiStore.setLoadingScreenMessage('Refreshing Baerify...')
      userStore.setSubscription(type)
      uiStore.setLoadingScreenMessage('')
      userStore.fetchUserDetails(userStore.username)
    } catch (e) {
      this.processError(e)
    }
  }

  onSubscriptionSelected(selected: any) {
    if (userStore.subscription === selected) {
      return uiStore.showInfoMessage('This is your current subscription')
    }

    if (!creditCardStore.hasDefault()) {
      if (!uiStore.showDialog) uiStore.openDialog({dialogBody: <UserAccount />})
      uiStore.setExpanded('panel4')
      return uiStore.showInfoMessage('Please add a card under "billing information" then upgrade your subscription')
    }

    uiStore.openConfirmDialog({
      dialogTitle: 'Are you sure?',
      dialogBody: 'Are you sure you\'d like to proceed with changing your subscription?',
      onConfirmAction: async () => this.upgrade(selected)
    })
  }
}

export const creditCardStore = new CreditCardStore()
export const defaultCreditCardStore = new DefaultCreditCardStore()
export const upgradeSubscriptionStore = new UpgradeSubscriptionStore()
