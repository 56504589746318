import { observer } from "mobx-react-lite"
import React from "react"
import uiStore from "../../stores/uiStore"
import { changePasswordStore } from "../../stores/userStore"
import validators from "../../utils/validators"
import Input from "../Input"
import SimpleForm from "../SimpleForm"
import { Wrapper } from "./ChangePassword.styles"

const ChangePassword = () => {
  const formFields = [
    {
      name: 'old_password',
      label: 'Old password',
      type: 'password',
      component: Input,
      validate: validators.isPassword,
    },
    {
      name: 'new_password',
      label: 'New password',
      type: 'password',
      component: Input,
      validate: validators.isPassword
    }
  ]

  const onSubmitForm = async (data: any) => {
    changePasswordStore.clear()
    await changePasswordStore.makeRequest(data)
    if (!changePasswordStore.errorMessage) {
      uiStore.closeDialog()
    }
  }

  return (
    <Wrapper>
      <SimpleForm 
        formFields={formFields}
        submitForm={onSubmitForm}
        actionButtons={{
          label: 'Change Password',
          isSubmit: true,
          loading: changePasswordStore.loading
        }}
      />
    </Wrapper>
  )
}

export default observer(ChangePassword)
  