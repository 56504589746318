import { faArrowRight } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { observer } from "mobx-react-lite"
import React, { FunctionComponent, useState } from "react"
import uiStore from "../../stores/uiStore"
import { colors } from "../../styles/_var"
import Box from "../Box"
import Comment, { CommentType } from "../Comment/Comment"
import Text from "../Text"
import { CommentInputField, CommentTextBox, PostCommentButton, Wrapper } from "./CommentSection.styles"

type CommentSectionProps = {
  comments: CommentType[],
  postComment: (comment: string, originalComment?: CommentType) => void,
  hasMoreComments: boolean,
  loadMoreComments: () => void,
  fetching: boolean,
  likeComment: (comment: CommentType, original?: CommentType) => void,
  deleteComment: (commentId: number) => void,
  loadReplies: (comment: CommentType) => void,
  deleteReply: (Comment: CommentType, replyId: number) => void
}

const UNSET = 0

const CommentSection: FunctionComponent<CommentSectionProps> = observer(({
  comments,
  hasMoreComments,
  fetching,
  loadMoreComments,
  postComment,
  deleteComment,
  likeComment,
  deleteReply,
  loadReplies
}) => {
  const [showReplyForm, setShowReplyForm] = useState(UNSET)
  const [commentToDelete, setCommentToDelete] = useState(UNSET)
  const [comment, setComment] = useState('')
  const [postingComment, setPostingComment] = useState(false)

  const showReplyClicked = (commentId: number) => {
    const payload = commentId === showReplyForm ? UNSET : commentId
    setShowReplyForm(payload)
  }

  const submitComment = async () => {
    if (!comment.trim()) return
    setPostingComment(true)
    await postComment(comment)

    setPostingComment(false)
    setComment('')
  }

  const onDeleteComment = async (commentId: number) => {
    uiStore.openConfirmDialog({
      dialogBody: 'Are you sure you want to delete?',
      dialogTitle: 'Delete confirmation',
      onConfirmAction: async () => {
        setCommentToDelete(commentId)
        await deleteComment(commentId)
        setCommentToDelete(UNSET)
      }
    })
  }

  const onDeleteReply = (comment: CommentType, replyId: number) => {
    uiStore.openConfirmDialog({
      dialogBody: 'Are you sure you want to delete?',
      dialogTitle: 'Delete confirmation',
      onConfirmAction: async () => {
        deleteReply(comment, replyId)
      }
    })
  }

  const onKeypress = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.which === 13 || e.key === 'Enter') submitComment()
  }

  return (
    <Wrapper>
      <CommentTextBox>
        <CommentInputField 
          multiline
          onKeyDown={onKeypress}
          disabled={postingComment}
          name='comment'
          variant="outlined"
          placeholder="Add your comment"
          value={comment}
          onChange={e => setComment(e.target.value)}
        />
        <PostCommentButton onClick={submitComment}>
          <FontAwesomeIcon icon={faArrowRight} />
        </PostCommentButton>
      </CommentTextBox> 
      {comments.map((comment) => (
        <Comment
          {...comment}
          like={() => likeComment(comment)}
          key={comment.id}
          postReply={reply => postComment(reply, comment)}
          showReplyForm={showReplyForm === comment.id} 
          deleting={commentToDelete === comment.id}
          onClickShowReplyForm={showReplyClicked}
          onClickDelete={commentId => onDeleteComment(commentId)}
          loadReplies={() => loadReplies(comment)}
          deleteReply={replyId => onDeleteReply(comment, replyId)}
          likeReply={reply => likeComment(reply, comment)}
        />
      ))}
      {hasMoreComments && (
        <Box style={{textAlign: 'right'}}>
          <Text
            link
            right
            italic
            variant="regular"
            color={colors.primary}
            onClick={loadMoreComments}
            opacity={fetching ? .8 : 1}
            inline
          >
            {fetching ? 'Loading...' : 'Load More...'}
          </Text>
        </Box>
      )}
    </Wrapper>
  )
})

export default CommentSection
