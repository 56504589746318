import styled from "styled-components";
import { colors, fontSize } from "../../styles/_var";


export const TextWrapper = styled(({ inline, ...rest }) => <div {...rest} />)`
  position: relative;
  ${({ inline }) => inline && 'display: inline-block;'}
`

export const StyledText = styled(({
  center,
  left,
  right,
  justify,
  variant,
  inline,
  nowrap,
  truncate,
  hasTooltip,
  link,
  lowercase,
  uppercase,
  italic,
  underline,
  ...rest
}) => <p {...rest} />)`
  margin: 0;
  ${({ center }) => center && 'text-align: center;'}
  ${({ left }) => left && 'text-align: left;'}
  ${({ right }) => right && 'text-align: right;'}
  ${({ justify }) => justify && 'text-align: justify;'}
  ${({ underline }) => underline && 'text-decoration: underline;'}
  ${({ variant }) => {
    if (variant === 'title') {
      return `
        font-size: ${fontSize.xl};
        line-height: 3.6rem;
        letter-spacing: 0.03rem;
      `
    } else if (variant === 'small') {
      return `
        font-size: ${fontSize.xs};
        line-height: 1.8rem;
      `
    } else if (variant === 'medium') {
      return `
        font-size: ${fontSize.md};
        line-height: 3.5rem;  
      `
    } else if (variant === 'subtext') {
      return `
        font-size: ${fontSize.sm};
        line-height: 2.9rem;
        letter-spacing: 0.01rem;
        color: ${colors.grey};
      `
    } else if (variant === 'regular') {
      return `
        font-size: ${fontSize.rg};
        line-height: 3rem;
      `
    } else if (variant === 'large') {
      return `
        font-size: ${fontSize.lg};
        line-height: 4.5rem;
        letter-spacing: 0.1rem;      
      `
    } else if (variant === 'semi-large') {
      return `
        font-size: 2.4rem;  
        line-height: 2.5rem;
      `
    } else {
      return `
        font-size: ${fontSize.rg};
        line-height: 2.2rem;
      `
    }
  }}
  ${({ nowrap }) => nowrap && `
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  `}
  ${({ truncate }) => truncate && `
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `}
  ${({ inline }) => inline && `
    display: inline-block;
  `}
  ${({ italic }) => italic && `
    font-style: italic;
  `}
  ${({ uppercase }) => uppercase && `
    text-transform: uppercase;
  `}
  ${({ lowercase }) => lowercase && `
    text-transform: lowercase;
  `}
  ${({ hasTooltip }) => hasTooltip && `
    cursor: pointer;
    text-decoration: underline;
    text-decoration-style: dotted;
  `}

  ${({ link }) => link && `
    cursor: pointer;
    transition: opacity ease .2s;
    &:hover {
      opacity: .8;
    }
  `}
`;


export const Tooltip = styled(({ showToolTip, ...rest }) => <div {...rest} />)`
  background-color: ${colors.green};
  border: solid 1.2px ${colors.darkGreen};
  color: ${colors.white};
  position: absolute;
  border-radius: 5px;
  padding: 1rem;
  line-height: 2rem;
  transition: all ease .4s;
  font-size: 14px;

  ${({ showToolTip }) => showToolTip ? `
    opacity: 1;
    z-index: 3;
  ` : `
    z-index: 0;
    opacity: 0;
  `}
`