import styled from "styled-components"
import { colors, spacing } from "../../../styles/_var"

export const Wrapper = styled.div``

export const MainHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const MainBody = styled.div`
  margin-top: ${spacing.xs};
`

export const NoPartnersWrapper = styled.div`
  margin-top: ${spacing.rg};
  display: flex;
  align-items: center;
  flex-direction: column;
`

export const PartnerItem = styled(({ hasConflict, deleting, ...rest }) => <div {...rest} />)`
  padding: ${spacing.xs};
  border: solid 1px ${colors.mediumgrey};
  border-radius: 20px;
  background-color: ${colors.lightgrey};

  ${({ hasConflict }) => hasConflict && `
    background-color: ${colors.lightyellow};
    border: solid 1px ${colors.danger};
  `}

  ${({ deleting }) => deleting && `
    opacity: .4;
  `}
`

export const PartnerItemHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const PartnerItemFooter = styled.div`
  display: flex;
  justify-content: space-between;

  & div:last-child {
    opacity: 1;
    transition: opacity ease .2s;
    &:hover {
      opacity: .7;
    }
  }
`

export const ActionItems = styled.div`
  display: flex;
  margin-top: ${spacing.xxs};

  svg {
    height: 1.4rem;
    margin-right: ${spacing.xxs};
    cursor: pointer;
    transition: opacity ease .2s;

    &:hover {
      opacity: 0.7;
    }
  }
`

export const CreatePartnerForm = styled.div`
  .MuiInputBase-input.MuiInput-input {
    padding-bottom: 5px;
  }
`

export const StepTwoWrapper = styled.div`
  
`

export const PartnerInfoWrapper = styled.div``

export const InfoItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${spacing.xxs};

  svg {
    margin-right: 30px;
  }
`

export const ImageCaptionWrapper = styled.div`
  padding: ${spacing.xs};
  text-align: center;
  img {
    margin-right: auto;
    margin-left: auto;
    margin-bottom: ${spacing.xxs};
  }

  input.MuiFilledInput-input {
    padding-top: 10px;
    padding-bottom: 10px;
  }
`

export const EvidenceActionButtons = styled.div`
  text-align: center;
  margin-top: ${spacing.sm};
`