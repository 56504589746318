import React, { useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown, faCog, faPowerOff, faUser } from '@fortawesome/free-solid-svg-icons'
import { colors } from '../../styles/_var'
import Text from '../Text'
import { 
  Wrapper,
  AvatarWrapper,
  Avatar,
  Menu,
  MenuItem
} from './UserAvatarButton.styles'
import { userStore } from '../../stores/userStore'
import { routes } from '../../utils/constants'
import uiStore from '../../stores/uiStore'
import UserProfile from '../UserProfile'
import UserAccount from '../UserAccount'
import { observer } from 'mobx-react-lite'


const UserAvatarButton = observer(() => {
  const closeMenu = () => setShowMenu(false)

  useEffect(() => {
    document.addEventListener('click', closeMenu)
    return () => {
      document.removeEventListener('click', closeMenu)
    }
  }, [])

  const [showMenu, setShowMenu] = useState(false)
  const toggleMenu = (e: React.MouseEvent<HTMLImageElement, MouseEvent>) => {
    e.stopPropagation()
    setShowMenu(!showMenu)
  }
  const logout = () => {
    userStore.logout(() => window.location.href = routes.LOGIN)
  }

  const showUserProfile = () => {
    uiStore.openDialog({
      dialogBody: <UserProfile />
    })
  }

  const showUserAccount = () => {
    uiStore.openDialog({
      dialogBody: <UserAccount />
    })
  }

  return (
    <Wrapper>
      <AvatarWrapper onClick={toggleMenu}>
        <Avatar 
          src={userStore.avatar}
          alt="person"
          sx={{width: 35, height: 35}}
        >
          {userStore.avatarText}
        </Avatar>
        <FontAwesomeIcon icon={faAngleDown} color={colors.grey} />
      </AvatarWrapper>
      <Menu showMenu={showMenu}>
        <MenuItem onClick={showUserProfile}>
          <FontAwesomeIcon icon={faUser} size="1x" />
          <Text variant="regular" ml={1}>Profile</Text>
        </MenuItem>
        <MenuItem onClick={showUserAccount}>
          <FontAwesomeIcon icon={faCog} size="1x" />
          <Text variant="regular" ml={1}>Account</Text>
        </MenuItem>
        <MenuItem onClick={logout}>
          <FontAwesomeIcon icon={faPowerOff} size="1x" />
          <Text variant="regular" ml={1}>Logout</Text>
        </MenuItem>
      </Menu>
    </Wrapper>
  )
})

export default UserAvatarButton