import React, { FunctionComponent, useState } from "react"
import Text from "../Text"
import { SectionTabs } from "./Tabs.styles"
import Tab from '@mui/material/Tab'

type TabsProps = {
  initialTab: number,
  tabData: {
    [key: string]: {
      node: React.ReactNode,
      label: string
    }
  }
}

const Tabs: FunctionComponent<TabsProps> = ({ tabData, initialTab }) => {
  const [selectedTab, setTab] = useState(initialTab)
  const values = Object.values(tabData)

  const handleChange = (event: React.SyntheticEvent, value: number) => {
    setTab(value)
  }

  const a11yProps = (index: number) => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  return (
    <>
      <SectionTabs
        value={selectedTab}
        onChange={handleChange}
        aria-label="story tabs"
        variant="scrollable"
        scrollButtons="auto"
      >
        {Object.values(values).map(({ label }, i) => (
          <Tab
            label={<Text variant="regular">{label}</Text>}
            {...a11yProps(i)}
            key={i} 
          />
        ))}
      </SectionTabs>
      {Object.values(values).map(({ node }, i) => (
        <div
          key={i}
          role="tabpanel"
          hidden={i !== selectedTab}
          id={`simple-tabpanel-${i}`}
          aria-labelledby={`simple-tab-${i}`}
        >
          {selectedTab === i && node}
        </div>
      ))}
    </>
  )
}

export default Tabs
