import React from "react"
import { observer } from "mobx-react-lite"
import { upgradeSubscriptionStore } from "../../stores/paymentStore"
import { userStore } from "../../stores/userStore"
import { SubscriptionsList } from "../../utils/constants"
import ListOptions from "../ListOptions"
import { Wrapper } from "./SubscriptionSettings.styles"

const SubscriptionSettings = () => {
  const onChange = (selected: any) => {
    upgradeSubscriptionStore.onSubscriptionSelected(selected)
  }
  

  return (
    <Wrapper>
      <ListOptions 
        lists={SubscriptionsList}
        value={userStore.subscription}
        onChange={onChange}
        selectItemLabel={userStore.subscription === 'BR' ? 'Upgrade now' : 'Cancel Subscription'}
      />
    </Wrapper>
  )
}

export default observer(SubscriptionSettings)
  