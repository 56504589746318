import React from "react"
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Wrapper } from "./NotificationSettings.styles"
import Switch from "../Switch";
import Text from "../Text";
import { observer } from "mobx-react-lite";
import { fetchNotificationStore, setNotificationStore } from "../../stores/notificationSettingsStore";


const NotificationSettings = () => {
  const settings = fetchNotificationStore.data || {}
  const onSwitchChange = (notificationType: string) => (e: any) => {
    const newSettings = {
      ...settings,
      [notificationType]: {
        label: settings[notificationType].label,
        value: e.target.checked
      }
    }

    fetchNotificationStore.setData(newSettings)
    setNotificationStore.makeRequest({
      notification_type: notificationType, 
      state: e.target.checked
    })
  }

  return (
    <Wrapper>
      <FormGroup>
        {Object.keys(settings).map((key, i) => (
          <FormControlLabel
            key={i}
            control={<Switch checked={settings[key].value} onChange={onSwitchChange(key)} />}
            label={<Text variant="regular">{settings[key].label}</Text>}
          />
        ))}
      </FormGroup>
    </Wrapper>
  )
}

export default observer(NotificationSettings)
  