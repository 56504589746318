import React from 'react'
import CircularProgress from '@mui/material/CircularProgress';
import { observer } from 'mobx-react-lite'
import DashboardWithSidebar from '../../../layouts/DashboardWithSidebar'
import { partnerStore } from '../../../stores/partnerStore'
import { colors } from '../../../styles/_var'
import { MainBody, Wrapper } from './Partners.styles'
import Box from '../../../components/Box';
import NoPartners from './NoPartners';
import PartnerList from './PartnerList';
import PartnersHeader from './PartnersHeader';

const Partners = () => {
  const isLoading = partnerStore.notFetchedYet && partnerStore.fetchingList && !partnerStore.hasData
  const hasNoPartners = !partnerStore.hasData && (partnerStore.fetchedList || partnerStore.failedToFetchList)

  return (
    <DashboardWithSidebar>
      <Wrapper>
        <PartnersHeader />
        <MainBody>
          {isLoading && (
            <Box alignCenter mt={4}>
              <CircularProgress style={{color: colors.primary}} />
            </Box>
          )}
          {hasNoPartners && <NoPartners />}
          {partnerStore.hasData && (
            <PartnerList
              deletePartner={partnerId => partnerStore.deletePartner(partnerId)}
              partners={partnerStore.list} 
              deleting={partnerStore.deleting}
            />
          )}
        </MainBody>
      </Wrapper>
    </DashboardWithSidebar>
  )
}

export default observer(Partners)
