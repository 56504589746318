import { action, makeObservable } from "mobx";
import { CommentType } from "../components/Comment";
import { apiRoutes, getPaginationDefaults } from "../utils/constants";
import { fetch, retrieveErrorMessage } from "../utils/helpers";
import EntityStore, { Config, defaultConfig } from "./entityStore";

class StoryCommentStore extends EntityStore<CommentType> {
  config: Config = {
    ...defaultConfig,
    url: apiRoutes.STORY_COMMENTS,
    shouldAddToList: true
  }

  constructor() {
    super()
    makeObservable(this, {
      toggleLike: action,
      fetchReplies: action,
      toggleCommentLike: action
    })
  }

  async toggleLike(storyId: string, comment: CommentType) {
    const configUrl = this.config.url.replace(':storyId', storyId)
    const url = `${configUrl}/${comment.id}/toggle_like`
    fetch({ url, method: 'POST' })
  }

  async toggleCommentLike(storyId: string, comment: CommentType, originalComment?: CommentType) {
    this.toggleLike(storyId, comment)

    if (originalComment) {
      const newReplies = {
        pagination_info: originalComment.replies?.pagination_info || getPaginationDefaults(),
        comments: originalComment.replies?.comments.map(e => e.id === comment.id ? comment : e) || []
      }
      originalComment.replies = newReplies
      this.updateInList(originalComment)
    } else {
      this.updateInList(comment)
    }
  }

  async fetchReplies(storyId: string, comment: CommentType, page?: number) {
    const paginationInfo = comment.replies?.pagination_info
    const currentPage = paginationInfo?.current_page || 0
    const nextPage = page === undefined ? currentPage + 1 : page
    const hasNext = paginationInfo?.has_next === undefined ? true : paginationInfo.has_next
    const totalPages = paginationInfo?.num_of_pages || 1

    if (!hasNext || (page || 0) > totalPages) return
    const configUrl = this.config.url.replace(':storyId', storyId)
    const url = `${configUrl}/${comment.id}/replies?page=${nextPage}`

    try {
      const response = await fetch({ url })
      const { results, pagination_info } = response.data

      this.updateInList({
        ...comment,
        replies: {
          comments: nextPage === 1 ? results : [...(comment.replies?.comments || []), ...results],
          pagination_info: pagination_info
        }
      })
    } catch (e) {
      console.log(retrieveErrorMessage(e))
    }
  }
}

export const storyCommentStore = new StoryCommentStore()