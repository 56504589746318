import React, { FunctionComponent, useState } from 'react'
import { CreatePartnerForm } from './Partners.styles'
import CreatePartnerStepOne from './CreatePartnerStepOne'
import CreatePartnerStepTwo from './CreatePartnerStepTwo'
import { partnerStore } from '../../../stores/partnerStore'
import { useNavigate } from 'react-router-dom'
import { PartnerType } from '../../../utils/interfaces'
import { routes } from '../../../utils/constants'

const STEP_ONE = 0
const STEP_TWO = 1

type PartnerFormProps = {
  partner?: PartnerType,
  extension?: string
}

const PartnerForm: FunctionComponent<PartnerFormProps> = React.memo(({
  partner,
  extension
}) => {
  const [currentStep, setCurrentStep] = useState(STEP_ONE)
  const [personalInfo, setPersonalInfo] = useState(partner || {})
  const [phoneExtension, setPhoneExtension] = useState(extension || '+1')

  const navigate = useNavigate()
  const gotoPreviousStep = () => setCurrentStep(currentStep - 1)
  const gotoNextStep = () => setCurrentStep(currentStep + 1)

  const onStepOneComplete = (personalInfoData: any) => {
    setPersonalInfo(personalInfoData)
    gotoNextStep()
  }

  const onStepTwoComplete = (relationship: number, relationshipStart: string) => {
    const partnerPayload: PartnerType = {...personalInfo, relationship, relationship_start_date: relationshipStart} as PartnerType
    partnerStore.addOrUpdate(partnerPayload, partner?.uuid, data => {
      const viewPartnerRoute = routes.VIEW_PARTNER.replace(':partnerId', data.uuid)
      navigate(viewPartnerRoute)
    })
  }

  return (
    <CreatePartnerForm>
      {currentStep === STEP_ONE && (
        <CreatePartnerStepOne
          onExtensionChange={setPhoneExtension}
          ext={phoneExtension}
          onFormComplete={onStepOneComplete}
          data={personalInfo} 
        />
      )}
      {currentStep === STEP_TWO && (
        <CreatePartnerStepTwo
          initialRelationship={partner?.relationship}
          initialRelationshipStartDate={partner?.relationship_start_date}
          gotoPreviousStep={gotoPreviousStep}
          onComplete={onStepTwoComplete}
          creatingPartner={partnerStore.creating}
        />
      )}
  </CreatePartnerForm>
  )
})

export default PartnerForm