import styled from "styled-components"


export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  text-align: center;

  svg {
    align-self: center;
  }

  p {
    align-self: center;
  }

  p:first-child {
    margin-top: -3rem;
    margin-bottom: 1rem;
  }
`
