import { useCallback, useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { storyStore } from "../stores/storyStore"
import { Story } from '../utils/interfaces'

const useGetStory = () => {
  const { storyId } = useParams()
  const [story, setStory] = useState<Story | null>(null)
  const [loading, setLoading] = useState(false)
  
  const loadStory = useCallback(async (id: string) => {
    setLoading(true)
    const currentStory = await storyStore.retrieve(id)
    setStory(currentStory)
    setLoading(false)
  }, [])

  useEffect(() => {
    if (!storyId) return
    loadStory(storyId)
  }, [loadStory, storyId])

  return { story, loading, loadStory }
}


export default useGetStory