import React, { FunctionComponent } from "react"
import { ReactComponent as NoConflict } from '../../assets/images/conflict.svg'
import { getStyleSpacing } from "../../utils/helpers"
import { ComponentSpacing } from "../../utils/interfaces"
import Text from "../Text"
import { NoResultsWrapper } from "./EmptyState.styles"

type EmptyStateProps = {
  message: string,
  style?: React.CSSProperties
}

const EmptyState: FunctionComponent<EmptyStateProps & ComponentSpacing> = React.memo(({
  message = 'No resource found',
  style={},
  ...spacingProps
}) => {
  const spacingStyle = getStyleSpacing(spacingProps)

  return (
    <NoResultsWrapper style={{...style, ...spacingStyle}}>
      <NoConflict fontSize={100} />
      <Text variant='medium' mt={2} center>
        {message}
      </Text>
    </NoResultsWrapper>
  )
})

export default EmptyState
  