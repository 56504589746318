import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons'
import Avatar from '@mui/material/Avatar'
import React, { FunctionComponent } from 'react'
import Text from '../../../components/Text'
import TextWithIcon from '../../../components/TextWithIcon'
import { colors } from '../../../styles/_var'
import { getThumbnailUrl, humanizeAbbr } from '../../../utils/helpers'
import { MessageType } from '../../../utils/constants'
import { ChatMessageType, ParticipantType } from '../../../utils/interfaces'
import { Bubble, ImageWrapper, MessageBubbleWrapper, MessageSection } from './Messages.styles'

type MessageBubbleProps = {
  chatMessage: ChatMessageType,
  participant?: ParticipantType,
  onOpenImage?: (img: string) => void,
}

const MessageBubble: FunctionComponent<MessageBubbleProps> = React.memo(({
  participant,
  onOpenImage,
  chatMessage: {
    id,
    message,
    created_at,
    succeeded,
    type=1
  }
}) => {
  const isSending = !id && succeeded === undefined
  const isImage = type === MessageType.IMAGE
  const ContentView: React.FunctionComponent<ChatContentProps> = ContentComponentMap[type]

  return (
    <>
      <MessageBubbleWrapper
        isMe={!participant}
        sending={isSending}
      >
        <MessageSection>
          {!!participant && (
            <Avatar src={participant.user.avatar} sx={{width: 25, height: 25, alignSelf: 'flex-end'}}>
              <Text variant='regular' weight='bold'>
                {participant.user.username[0].toUpperCase()}
              </Text>
            </Avatar>
          )}
          <ContentView
            onClick={isImage && onOpenImage ? () => onOpenImage(message) : undefined}
            isMe={!participant}
            message={isImage ? getThumbnailUrl(message) : message}
            failed={succeeded === false}
          />
        </MessageSection>
        {!!created_at && (
          <Text variant='small' color={colors.primary} style={{alignSelf: 'flex-end'}}>
            {!!participant ? 'Recieved' : 'Sent'} {humanizeAbbr(created_at)}
          </Text>
        )}
        {succeeded === false && (
          <TextWithIcon icon={faExclamationCircle} variant='small' color={colors.red} style={{alignSelf: 'flex-end'}}>
            failed!
          </TextWithIcon>
        )}
      </MessageBubbleWrapper>
    </>
  )
})


type ChatContentProps = {
  failed?: boolean,
  onClick?: () => void,
  message: string,
  isMe: boolean
}

const TextChatType: FunctionComponent<ChatContentProps> = React.memo(({
  failed,
  message,
  isMe
}) => (
  <Bubble
    isMe={isMe}
    variant='regular'
    ml={1}
    failed={failed}
  >
    {message}
  </Bubble>
))

const ImageChatType: FunctionComponent<ChatContentProps> = React.memo(({
  failed,
  message: imgSrc,
  onClick,
}) => (
  <ImageWrapper src={imgSrc} onClick={onClick} failed={failed} />
))

type ContentComponentMapType = {
  [key: number]: React.FunctionComponent<ChatContentProps>
}

const ContentComponentMap: ContentComponentMapType = {
  1: TextChatType,
  2: ImageChatType
}

export default MessageBubble
