import React, { FunctionComponent } from "react"
import { StoryType } from "../../utils/constants"
import { Wrapper } from './StoryLabel.styles'
import { ReactComponent as Dot } from '../../assets/images/dot.svg'
import Text from "../Text"
import { colors } from "../../styles/_var"

type StoryLabelProps = {
  type: number | undefined
}

const StoryLabel: FunctionComponent<StoryLabelProps> = ({ type }) => {
  let category: string;
  
  switch (type) {
    case StoryType.DRAFT:
      category = 'Draft';
      break;
    case StoryType.COMPLETE:
      category = 'Complete'
      break;
    default:
      category = 'Published'
  }

  return (
    <Wrapper>
      <Dot />
      <Text variant="small" color={colors.darkgrey}>
        {category}
      </Text>
    </Wrapper>
  )
}

export default StoryLabel
  