import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import ActivationCodeInput from '../../../components/ActivationCodeInput'
import AuthLayoutHeader from '../../../components/AuthLayoutHeader'
import SimpleForm from '../../../components/SimpleForm'
import Text from '../../../components/Text'
import MainAuthLayout from '../../../layouts/MainAuthLayout'
import { activateUserStore, userStore, sendActivationCodeStore } from '../../../stores/userStore'
import { colors } from '../../../styles/_var'
import Auth from '../../../utils/auth'
import { routes } from '../../../utils/constants'
import validators from '../../../utils/validators'



const Activate = () => {
  const [loadComponent, setLoadComponent] = useState(false)
  const navigate = useNavigate()
  const { isActivated, userInfoLoaded } = userStore
  const username = Auth.fetchUsername()

  useEffect(() => {
    if (Auth.isLoggedIn() && username) {
      userStore.fetchUserDetails(username)
    }
  }, [username])

  useEffect(() => {
    if (isActivated) {
      navigate(routes.DASHBOARD)
    } else if (userInfoLoaded) {
      setLoadComponent(true)
    }
  }, [navigate, isActivated, userInfoLoaded])

  const activationFields = [
    {
      name: 'code',
      component: ActivationCodeInput,
      max_length: 4,
      validate: validators.required
    }
  ]

  const onSubmit = (data: any) => {
    const { code } = data
    activateUserStore.makeRequest({ code: code.substring(0, 4) }, {
      onRequestComplete: () => {
        navigate(routes.DASHBOARD)
      }
    })
  }

  if (!loadComponent) return null

  return (
    <MainAuthLayout>
      <AuthLayoutHeader 
        title='Enter Code'
        subtitle={<span>Enter the 4 digit code we sent to your email. <br /><strong>Note:</strong> Check your spam folders if you cant find it in your inbox.</span>}
      />
      <SimpleForm 
        formFields={activationFields}
        submitForm={onSubmit}
        actionButtons={{
          label: 'VERIFY',
          loading: activateUserStore.loading,
          isSubmit: true
        }}
      />
      <Text
        variant='small'
        weight='bold'
        color={colors.primary}
        mt={2}
        center
        link
        onClick={() => sendActivationCodeStore.makeRequest()}
      >
        {sendActivationCodeStore.loading ? 'Resending...' : 'Resend Code'}
      </Text>
    </MainAuthLayout>
  )
}



export default observer(Activate)
