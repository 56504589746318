import styled from "styled-components";
import Tabs from '@mui/material/Tabs'
import { colors, spacing } from "../../styles/_var";

export const Wrapper = styled.div``

export const SectionTabs = styled(Tabs)`
  margin-bottom: ${spacing.sm};
  &.MuiTabs-root {
    min-height: 40px;
  }

  & .MuiTabs-flexContainer {
    justify-content: space-between;

    button {
      padding: 8px 0;
      min-height: 40px;
    }
  }

  & .MuiTabs-indicator {
    background-color: ${colors.primary};
  }

  & button.MuiButtonBase-root.Mui-selected {
    color: ${colors.primary};
  }
`