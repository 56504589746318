import { AxiosResponse } from "axios";
import { fetch } from "./helpers";
import { IHelpRequest } from "./interfaces";

export const getPresignedUrlRequest = async (filename: string): Promise<AxiosResponse<any>> => {
  const url = '/users/presigned_url'
  return fetch({ url, method: 'POST', body: { filename } })
}

export const googleAuthRequest = async (id_token: string): Promise<AxiosResponse<any>> => {
  return fetch({ url: '/users/social_auth', method: 'POST', body: { id_token } })
}

export const sendHelpRequest = async (payload: IHelpRequest): Promise<AxiosResponse<any>> => {
  return fetch({url: '/contact_us', method: 'POST', body: payload})
}

export const sendUIErrorReport = (payload: any) => {
  return new Promise((resolve, reject) => {})
}