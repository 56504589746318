import styled from 'styled-components'
import { colors } from '../../styles/_var'
import Input from '../Input'


export const Wrapper = styled.div``

export const CodeInput = styled.div`
  border-radius: 50%;
  background-color: ${colors.lightgrey};
  padding: 14px 26px;
  margin-right: 11px;
  width: 60px;
  height: 60px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const HiddenInputField = styled(Input)`
  position: absolute;
  opacity: 0;
  top: 5px;
`

export const CodeInputWrapper = styled.div`
  display: flex;
  justify-content: center;
`