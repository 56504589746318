import styled from 'styled-components'

export const FormInputWrapper = styled(({ isCheckbox, ...rest}) => <div {...rest} />)`
  display: block;
  margin-bottom: 2.5rem;
  position: relative;
  text-align: left;

  ${({ isCheckbox }) => isCheckbox && `
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    align-items: center;
    input {
      margin-right: 1rem;
    }
  `}
`
