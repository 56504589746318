import React, { useCallback } from 'react'
import { observer } from 'mobx-react-lite'
import { myPublishedStoryStore } from '../../../stores/storyStore'
import StoryList from './StoryList'

const MyStories = () => {
  const makeRequest = useCallback((page?: number) => {
    myPublishedStoryStore.makeRequest(undefined, { page })
  }, [])

  const loading = myPublishedStoryStore.loading && !myPublishedStoryStore.hasData

  return (
    <StoryList 
      loading={loading}
      makeRequest={makeRequest}
      showNextButton={myPublishedStoryStore.showNextButton}
      data={myPublishedStoryStore.data}
      hasData={myPublishedStoryStore.hasData}
      noDataText="None of your stories have been published yet"
    />
  )
}

export default observer(MyStories)
