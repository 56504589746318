import React from 'react'
import img1 from '../../assets/images/img1.png'
import img2 from '../../assets/images/img2.png'
import img3 from '../../assets/images/img3.png'
import { ScreenshotWrapper } from './LandingPage.styles'


const Screenshots = React.memo(() => {
  return (
    <ScreenshotWrapper>
      <img src={img1} alt='screenshot-1' />
      <img src={img2} alt='screenshot-2' />
      <img src={img3} alt='screenshot-3' />
    </ScreenshotWrapper>
  )
})

export default Screenshots
