import React, { FunctionComponent } from "react"
import Avatar from "@mui/material/Avatar"
import { Wrapper } from "./AvatarWithLabel.styles"
import Text, { ITextProps } from "../Text"
import { colors } from "../../styles/_var"


type AvatarWithLabelProps = {
  label: string,
  src?: string,
  labelProps?: ITextProps,
  sx?: React.CSSProperties,
  column?: boolean,
  border?: boolean,
}

const AvatarWithLabel: FunctionComponent<AvatarWithLabelProps> = ({
  label,
  src,
  labelProps,
  column,
  border,
  sx={}
}) => {
  const textProps: ITextProps = {
    variant: 'regular',
    color: colors.grey,
    weight: 'bold',
    ml: 1,
    ...labelProps
  }

  const defaultStyle={width: 25, height: 25, ...sx}
  if (border) {
    defaultStyle.border = `solid 2px ${colors.grey}`
  }

  return (
    <Wrapper style={{flexDirection: column ? 'column' : 'row'}}>
      <Avatar src={src} sx={defaultStyle}>
        <Text variant="regular">
          {label[0]?.toUpperCase()}
        </Text>
      </Avatar>
      <Text {...textProps}>
        {label}
      </Text>
    </Wrapper>
  )
}

export default AvatarWithLabel
