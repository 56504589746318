import React, { FunctionComponent, useEffect, useState } from "react"
import { BaeImage, HeartIcon, Wrapper } from "./BaerifierImageIcon.styles"
import { ReactComponent as Conflict } from '../../assets/images/conflict.svg'
import { ComponentSpacing } from "../../utils/interfaces"
import { getStyleSpacing } from "../../utils/helpers"

const images = [
  'https://www.peerspace.com/resources/wp-content/uploads/best-vancouver-portrait-photographers-754x600.png',
  'https://images.unsplash.com/photo-1534528741775-53994a69daeb?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8cG9ydHJhaXR8ZW58MHx8MHx8&w=1000&q=80',
  'https://images.unsplash.com/photo-1624711517157-25991163e537?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NHx8cG9ydHJhaXQlMjBwaG90b2dyYXBoeXxlbnwwfHwwfHw%3D&w=1000&q=80',
  'https://img.freepik.com/premium-photo/front-view-person-with-short-hair_23-2148749599.jpg?w=2000',
  'https://cherrydeck-blog.b-cdn.net/blog/wp-content/uploads/2020/11/Klara-Waldberg.png',
  'https://miro.medium.com/max/1080/1*jWx9suY2k3Ifq4B8A_vz9g.jpeg',
  'https://img.freepik.com/free-photo/portrait-white-man-isolated_53876-40306.jpg?w=2000'
]

interface BaerifierImageIconType extends ComponentSpacing {
  animate?: boolean,
  duration?: number,
  style?: React.CSSProperties
}


const getRandomIndex = () => Math.floor(Math.random() * images.length)
const getRandomImage = () => images[getRandomIndex()]

const BaerifierImageIcon: FunctionComponent<BaerifierImageIconType> = React.memo(({
  mt, mb, mr, ml, animate, duration=300, style={}
}) => {
  
  useEffect(() => {

    if (!animate) return
    const interval = setInterval(() => {
      setImage1(getRandomImage())
      setImage2(getRandomImage())
    }, duration)

    return () => {
      clearInterval(interval)
    }
  }, [animate, duration])
  const [image1, setImage1] = useState(getRandomImage())
  const [image2, setImage2] = useState(getRandomImage())
  const spacingStyle = getStyleSpacing({ mt, mb, mr, ml })

  return (
    <Wrapper style={{...spacingStyle, ...style}}>
      <BaeImage src={image1}/>
      <HeartIcon>
        <Conflict fill="white" />
      </HeartIcon>
      <BaeImage src={image2}/>
    </Wrapper>
  )
})

export default BaerifierImageIcon
  