import styled from "styled-components";
import { colors, spacing } from "../../styles/_var";
import PhoneNoInput from "../PhoneNoInput";

export const FormWrapper = styled(({searchByPhone, ...rest}) => <div {...rest} />)`
  display: flex;

  ${({ searchByPhone }) => searchByPhone && `
    margin-top: 4px;
  `}

  ${({ searchByPhone }) => !searchByPhone && `
    & .MuiInputBase-root {
      border-radius: 20px;
      background-color: ${colors.lightgrey};

      & input::placeholder {
        font-style: italic;
      }
    }
  `}
`

export const Wrapper = styled(({searchByPhone, ...rest}) => <div {...rest} />)`
  & .MuiFormGroup-root {
    flex-direction: row;
    margin-left: ${spacing.xxs};

    span {
      font-family: 'Mulish', sans-serif, -apple-system;
    }
  }

  margin-bottom: ${spacing.sm};
`

export const StyledPhoneInput = styled(PhoneNoInput)`
  flex: 1;
`