import { makeObservable, computed, observable, action } from "mobx"
import { getPaginationDefaults, status } from "../utils/constants"
import { appendQueryString, fetch, retrieveErrorMessage } from "../utils/helpers"
import { IPagination, RequestType, Status } from "../utils/interfaces"
import { EntityLoadingType, ErrorMessagesType, ServerError } from './types'
import uiStore from "./uiStore"


export type Config = {
  url: string,
  shouldUseAuth?: boolean,
  shouldAddToList?: boolean,
  shouldRemoveFromList?: boolean,
  shouldUpdateInList?: boolean,
  shouldUseToast?: boolean,
  page?: number,
  idKey: string,
  itemId?: string | number,
  method?: RequestType,
  onFetchedList: (data: any, paginationInfo?: any) => void,
  onCreatedItem: (data: any) => void,
  onUpdatedItem: (data: any) => void,
  onDeletedItem: (data: any) => void,
  onRetrieveItem: (data: any) => void,
  onFailedToCreate: (errorMessage: string) => void,
  onFailedToFetch: (errorMessage: string) => void,
  onFailedToDelete: (errorMessage: string) => void,
  onFailedToUpdate: (errorMessage: string) => void,
  onFailedToRetrieve: (errorMessage: string) => void,
}

export const defaultLoadingStates: EntityLoadingType = {
  create: null,
  delete: null,
  update: null,
  get: null,
  list: null
}

export const defaultErrorMessages: ErrorMessagesType = {
  create: '',
  delete: '',
  update: '',
  get: '',
  list: ''
}

export const defaultConfig: Config = {
  url: '',
  shouldUseAuth: true,
  shouldAddToList: true,
  shouldRemoveFromList: true,
  shouldUpdateInList: true,
  shouldUseToast: true,
  page: 0,
  itemId: '',
  idKey: 'id',
  onFetchedList: () => null,
  onCreatedItem: () => null,
  onUpdatedItem: () => null,
  onDeletedItem: () => null,
  onRetrieveItem: () => null,
  onFailedToCreate: (errorMessage: string) => null,
  onFailedToFetch: (errorMessage: string) => null,
  onFailedToDelete: (errorMessage: string) => null,
  onFailedToUpdate: (errorMessage: string) => null,
  onFailedToRetrieve: (errorMessage: string) => null,
}

class EntityStore<E, RequestType=E> {
  list: E[] = []
  item: E | null = null

  paginationInfo: IPagination = getPaginationDefaults()
  loadingStates: EntityLoadingType = {...defaultLoadingStates}
  errorMessages: ErrorMessagesType = {...defaultErrorMessages}

  config: Config = defaultConfig


  constructor() {
    makeObservable(this, {
      list: observable,
      item: observable,
      paginationInfo: observable,
      loadingStates: observable,
      errorMessages: observable,
      listEntity: action,
      createEntity: action,
      updateEntity: action,
      retrieveEntity: action,
      deleteEntity: action,
      setItem: action,
      setListData: action,
      addToList: action,
      updateInListBy: action,
      deleteFromList: action,
      deleteFromListBy: action,
      updateInList: action,
      getFromList: action,
      clearData: action,
      clearErrorMessages: action,
      resetLoadingStates: action,
      setPaginationInfo: action,
      setErrorMessages: action,
      setLoadingStatus: action,
      fetchingList: computed,
      failedToFetchList: computed,
      fetchedList: computed,
      creating: computed,
      failedToCreate: computed,
      created: computed,
      updating: computed,
      failedToUpdate: computed,
      updated: computed,
      deleting: computed,
      failedToDelete: computed,
      deleted: computed,
      fetching: computed,
      failedToFetch: computed,
      fetched: computed,
      currentPage: computed,
    })
  }

  async listEntity(configOverride: Partial<Config> = {}) {
    if (this.fetchingList) return

    const { page, url, onFetchedList, onFailedToFetch } = this.getConfig(configOverride)
    this.setLoadingStatus(status.LOADING, 'list')

    try {
      const requestURL = appendQueryString(url, { page: page ? page : this.currentPage + 1})
      const response = await fetch({ url: requestURL, method: 'GET' })

      const results = response.data.results || []
      const paginationInfo = response.data.pagination_info || getPaginationDefaults()
      
      this.setListData(results, page)
      this.setPaginationInfo(paginationInfo)
      this.setLoadingStatus(status.COMPLETE, 'list')
      onFetchedList(results, paginationInfo)
    } catch(e) {
      this.processError(e, 'list', configOverride)
      this.setLoadingStatus(status.FAILED, 'list')
      onFailedToFetch(retrieveErrorMessage(e))
    }
  }

  async createEntity(createPayload: RequestType, configOverride: Partial<Config> = {}) {
    if (this.creating) return 

    const { url, shouldAddToList, onCreatedItem, onFailedToCreate } = this.getConfig(configOverride)
    this.setLoadingStatus(status.LOADING, 'create')

    try {
      const response = await fetch({ url, method: 'POST', body: createPayload })
      const createdEntity = response.data.results

      if (shouldAddToList) this.addToList(createdEntity, '-')
      this.setLoadingStatus(status.COMPLETE, 'create')
      onCreatedItem(createdEntity)
    } catch (e) {
      this.setLoadingStatus(status.FAILED, 'create')
      this.processError(e, 'create', configOverride)
      onFailedToCreate(retrieveErrorMessage(e))
    }
  }

  async updateEntity(updatePayload: E, configOverride: Partial<Config> = {}) {
    if (this.updating) return

    const {
      url,
      method,
      shouldUpdateInList,
      shouldUseToast,
      onUpdatedItem,
      onFailedToUpdate,
      itemId
    } = this.getConfig(configOverride)
    this.setLoadingStatus(status.LOADING, 'update')

    try {
      const requestURL = itemId ? `${url}/${itemId}` : url
      const response = await fetch({ url: requestURL, method: method || 'PUT', body: updatePayload })
      const updatedEntity = response.data.results

      if (shouldUpdateInList) this.updateInList(updatedEntity)
      this.setLoadingStatus(status.COMPLETE, 'update')
      onUpdatedItem(updatedEntity)

      if (shouldUseToast) {
        uiStore.showToastMessage({
          message: 'Success!',
          severity: 'success',
          duration: 10000
        })
      }
    } catch (e) {
      this.setLoadingStatus(status.FAILED, 'update')
      this.processError(e, 'update', configOverride)
      onFailedToUpdate(retrieveErrorMessage(e))
    }
  }

  async retrieveEntity(configOverride: Partial<Config> = {}) {
    if (this.fetching) return 

    const { url, onRetrieveItem, itemId, onFailedToRetrieve } = this.getConfig(configOverride)
    this.setLoadingStatus(status.LOADING, 'get')

    try {
      const requestUrl = itemId ? `${url}/${itemId}` : url
      const response = await fetch({ url: requestUrl, method: 'GET' })
      this.setItem(response.data.results)
      this.setLoadingStatus(status.COMPLETE, 'get')
      onRetrieveItem(response.data.results)
    } catch (e) {
      this.setLoadingStatus(status.FAILED, 'get')
      this.processError(e, 'get', configOverride)
      onFailedToRetrieve(retrieveErrorMessage(e))
    }
  }

  async deleteEntity(configOverride: Partial<Config> = {}) {
    if (this.deleting) return

    let {
      url,
      shouldRemoveFromList,
      itemId,
      onDeletedItem,
      shouldUseToast,
      onFailedToDelete
    } = this.getConfig(configOverride)
    this.setLoadingStatus(status.LOADING, 'delete')

    if (itemId) url = `${url}/${itemId}`

    try {
      const response = await fetch({ url, method: 'DELETE'})

      if (shouldRemoveFromList) this.deleteFromList(itemId)
      this.setLoadingStatus(status.COMPLETE, 'delete')
      onDeletedItem(itemId)

      if (shouldUseToast) {
        uiStore.showToastMessage({
          message: response.data.message,
          severity: 'success',
          duration: 10000
        })
      }
    } catch (e) {
      this.setLoadingStatus(status.FAILED, 'delete')
      this.processError(e, 'delete', configOverride)
      onFailedToDelete(retrieveErrorMessage(e))
    }
  }

  processError(e: any, type: keyof ErrorMessagesType, configOverride: Partial<Config>={}) {
    const { shouldUseToast } = this.getConfig(configOverride)
    const errorMessages = retrieveErrorMessage(e)
    this.setErrorMessages(errorMessages, type)

    if (shouldUseToast) {
      uiStore.showToastMessage({
        message: errorMessages,
        severity: 'error',
        duration: 10000
      })
    }
  }

  setItem(data: E | null) {
    this.item = data
  }

  setListData(data: E[], currentPage?: number) {
    const newPage = currentPage || (this.currentPage + 1)

    if (newPage === 1) {
      this.list = data;
    } else {
      this.list = [...this.list, ...data]
    }
  }

  addToList(data: E, position: '+' | '-' = '+') {
    if (position === '+') {
      this.list = [...this.list, data]
    } else {
      this.list = [data, ...this.list]
    }
  }

  deleteFromList(id: any) {
    this.list = this.list.filter((e: any) => e[this.config.idKey] !== id)
  }

  deleteFromListBy(key: string, value: any) {
    this.list = this.list.filter((e: any) => e[key] !== value)
  }

  updateInList(data: any) {
    this.list = this.list.map((e: any) => e[this.config.idKey] === data[this.config.idKey] ? data : e)
  }

  updateInListBy(key: string, value: any, payload: any) {
    this.list = this.list.map((e: any) => e[key] === value ? payload : e)
  }

  getFromList(value: string | number, key: string) {
    return this.list.find((e: any) => e[key] === value)
  }

  clearData() {
    this.list = []
    this.setItem(null)
    this.paginationInfo = getPaginationDefaults()

    this.clearErrorMessages()
    this.resetLoadingStates()
  }

  clearErrorMessages(type?: keyof ErrorMessagesType) {
    if (type) {
      this.errorMessages[type] = ''
    } else {
      this.errorMessages = defaultErrorMessages
    }
  }

  resetLoadingStates(type?: keyof EntityLoadingType) {
    if (type) {
      this.loadingStates[type] = null
    } else {
      this.loadingStates = defaultLoadingStates
    }
  }

  setPaginationInfo(data: IPagination) {
    this.paginationInfo = data
  }

  setErrorMessages(errorMessage: string | ServerError, type: keyof EntityLoadingType) {
    this.errorMessages = {...this.errorMessages, [type]: errorMessage}
  }

  setLoadingStatus(status: Status, type: keyof EntityLoadingType) {
    this.loadingStates = {...this.loadingStates, [type]: status}
  }

  getConfig(configOverride: Partial<Config>) {
    return {...this.config, ...configOverride}
  }

  get fetchingList() {
    return this.loadingStates.list === status.LOADING
  }

  get failedToFetchList() {
    return this.loadingStates.list === status.FAILED
  }

  get fetchedList() {
    return this.loadingStates.list === status.COMPLETE
  }

  get notFetchedYet() {
    return this.loadingStates.list === null
  }

  get creating() {
    return this.loadingStates.create === status.LOADING
  }

  get failedToCreate() {
    return this.loadingStates.create === status.FAILED
  }

  get created() {
    return this.loadingStates.create === status.COMPLETE
  }

  get updating() {
    return this.loadingStates.update === status.LOADING
  }

  get failedToUpdate() {
    return this.loadingStates.update === status.FAILED
  }

  get updated() {
    return this.loadingStates.update === status.COMPLETE
  }

  get deleting() {
    return this.loadingStates.delete === status.LOADING
  }

  get failedToDelete() {
    return this.loadingStates.delete === status.FAILED
  }

  get deleted() {
    return this.loadingStates.delete === status.COMPLETE
  }

  get fetching() {
    return this.loadingStates.get === status.LOADING
  }

  get failedToFetch() {
    return this.loadingStates.get === status.FAILED
  }

  get fetched() {
    return this.loadingStates.get === status.COMPLETE
  }

  get currentPage() {
    return this.paginationInfo.current_page
  }

  get hasData() {
    return !!this.list.length
  }

  get hasNext() {
    return this.paginationInfo.has_next
  }

  get count() {
    return this.list.length
  }
}

export default EntityStore
