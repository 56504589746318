import TextField from '@mui/material/TextField'
import styled from "styled-components"
import { colors, fontSize, spacing } from "../../styles/_var"

export const InputWrapper = styled.div`
  display: inline-block;
  width: 100%;
  position: relative;
`

export const StyledInput = styled(TextField)`
  width: 100%;
  /* max-width: 50rem; */
  
  & .MuiInputBase-input {
    /* font-size: ${fontSize.rg}; */
    font-size: 16px;
    padding-bottom: ${spacing.xs};
  }

  & label {
    font-size: ${fontSize.md};
  }

  & label.Mui-focused {
    color: ${colors.primary};
  }

  & .MuiInput-underline:before {
    border-bottom-color: ${colors.lightgrey};
    border-bottom-width: 2px;
  }

  & .MuiInput-underline:after {
    border-bottom-color: ${colors.primary};
  }

  & .MuiInput-underline:hover:not(.Mui-disabled):before {
    border-bottom-color: ${colors.grey};
  }

  & .MuiOutlinedInput-root {
    & fieldset {
      border-color: 'red';
    }
    &:hover fieldset {
      border-color: 'yellow';
    }
    &.Mui-focused fieldset {
      border-color: ${colors.primary};
    }
  }

  & .MuiFormHelperText-root {
    font-size: ${fontSize.xxs};
  }
`
