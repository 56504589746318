import styled from "styled-components";
import { NavbarHeight } from "../../components/NavbarShell/NavbarShell.styles";
import PageContent from "../../components/PageContent";
import { colors, device, spacing } from "../../styles/_var";

export const Wrapper = styled.div``;

export const LandingPageNavButtons = styled.div`
  max-width: 30rem;
`

export const BannerWrapper = styled.div`
  margin-top: ${NavbarHeight};
  position: relative;
  width: 100%;
  text-align: center;
  min-height: 42rem;
  padding-top: ${spacing.rg};
  background: linear-gradient(180deg, #EAE9E9 -8.97%, #FFFFFF 10.5%, #FFFFFF 67.26%, #DBCABA 132.22%);
  
  @media ${device.md} {
    min-height: 44rem;
    background-position: center center;
  }
`

export const BannerMessage = styled.div`
  text-align: center;
  padding: 0 ${spacing.rg};
`

export const GetStartedWrapper = styled.div`
  min-height: 25rem;
  background-color: ${colors.verylightPrimary};
  padding: ${spacing.rg} 0;
`

export const GetStartedContainer = styled(PageContent)`
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
  
  @media ${device.md} {
    flex-direction: row;
  }
`

export const SectionOne = styled.div`
  p {
    text-align: center;

    &:nth-child(2) {
      display: block;
    }
  }

  a {
    margin-left: auto;
    margin-right: auto;
  }

  @media ${device.md} {
    & > p {
      text-align: justify;

      &:nth-child(2) {
        display: inline-block;
      }
    }

    a {
      margin-left: unset;
      margin-right: unset;
      text-align: center;
    }
  }
`

export const SectionTwo = styled.div``

export const ScreenshotWrapper = styled.div`
  text-align: center;
  min-height: 320px;

  img {
    width: 180px;
    position: absolute;
    height: 'auto';

    &:nth-child(1) {
      transform: rotate(-2deg);
      left: 10px;
    }

    &:nth-child(2) {
      transform: rotate(10deg);
      left: 100px;
    }
    
    &:nth-child(3) {
      transform: rotate(10deg);
      left: 150px;
    }
  }

  @media ${device.md} {
    text-align: left;
    position: relative;
    margin-bottom: 0;
    min-height: unset;

    img {
      width: 180px;
      height: auto;
      position: relative;
  
      &:nth-child(1) {
        transform: rotate(-10deg);
      }
  
      &:nth-child(2) {
        transform: rotate(-3deg);
        left: -120px;
      }
      
      &:nth-child(3) {
        transform: rotate(10deg);
        position: relative;
        left: -180px;
      }
    }
  }
`

export const FooterWrapper = styled.div`
  height: 5rem;
  display: flex;
  align-items: center;
`

export const FooterContent = styled(PageContent)`
  display: flex;
  align-items: center;
  justify-content: center;

  a {
    color: ${colors.primary};
  }

`