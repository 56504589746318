import React, { FunctionComponent } from 'react'
import { INotification } from '../../../utils/interfaces'
import { humanize } from '../../../utils/helpers'
import Avatar from '@mui/material/Avatar'
import { NotificationItem } from './Notifications.styles'
import Text from '../../../components/Text'
import Box from '../../../components/Box'
import { colors } from '../../../styles/_var'
import { useNavigate } from 'react-router-dom'
import { routes } from '../../../utils/constants'


const StoryLikedView: FunctionComponent<Partial<INotification>> = React.memo(({
  actor,
  created_at,
  notification_object,
  read,
}) => {
  const navigate = useNavigate()
  const gotoStory = () => {
    const route = `${routes.VIEW_STORY.replace(':storyId', notification_object.uuid)}`
    navigate(route)
  }

  return (
    <NotificationItem read={read} onClick={gotoStory}>
      <Avatar src={actor?.avatar}>
        <Text variant='medium' weight='bold'>
          {actor?.username[0]?.toUpperCase()}
        </Text>
      </Avatar>
      <Box ml={2}>
        <Text variant='small' color={colors.grey}>
          {created_at && humanize(created_at)}
        </Text>
        <Text variant='regular' weight='bold' mb={1}>
          @{actor?.username} liked to your story
        </Text>
        <Text variant='regular' color={colors.grey}>
          "{notification_object.title}"
        </Text>
      </Box>
    </NotificationItem>
  )
})

export default StoryLikedView
