import React, { FunctionComponent } from 'react'
import { NotificationItem } from "./Notifications.styles"
import { INotification } from '../../../utils/interfaces'
import { humanize } from '../../../utils/helpers'
import Text from '../../../components/Text'
import { colors } from '../../../styles/_var'
import Link from '../../../components/Link'
import Box from '../../../components/Box'
import { ReactComponent as ConflictIcon } from '../../../assets/images/conflict.svg'
import { useNavigate } from 'react-router-dom'
import { routes } from '../../../utils/constants'

const PartnerConflictView: FunctionComponent<Partial<INotification>> = React.memo(({
  created_at,
  notification_object,
  read
}) => {
  const navigate = useNavigate()
  const fullName = notification_object.first_name + ' ' + notification_object.last_name
  const gotoPartnerView = () => {
    const route = `${routes.MY_PARTNERS}/${notification_object.uuid}`
    navigate(route)
  }

  return (
    <NotificationItem read={read} onClick={gotoPartnerView}>
      <ConflictIcon height="40px" width="40px" />
      <Box ml={2}>
        <Text variant='small' color={colors.grey}>
          {created_at && humanize(created_at)}
        </Text>
        <Text variant='regular' weight='bold' mb={1}>
          Your partner {fullName} has a match.
        </Text>
        <Link to='/'>
          <Text variant='regular' underline color={colors.primary} inline>
            Go to Partner
          </Text>
        </Link>
      </Box>
    </NotificationItem>
  )
})


export default PartnerConflictView
