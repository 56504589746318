import React from 'react'
import Link from '../../components/Link'
import Text from '../../components/Text'
import { colors } from '../../styles/_var'
import { routes } from '../../utils/constants'
import { GetStartedContainer, GetStartedWrapper, SectionOne, SectionTwo } from './LandingPage.styles'
import Screenshots from './Screenshots'

const GetStartedNow = React.memo(() => {
  return (
    <GetStartedWrapper>
      <GetStartedContainer>
        <SectionOne>
          <Text variant='medium' weight='bold' color={colors.primary} justify mt={3}>
            Get Started With Baerify
          </Text>
          <Text
            variant='medium'
            color={colors.darkgrey}
            maxWidth={350}
            inline
            justify
          >
            Join Baerify today for free and enjoy unlimited access to all our amazing baerify features.
          </Text>
          <Link
            useButton
            variant='primary'
            to={routes.REGISTER}
            mt={3}
            style={{ width: 180, display: 'block' }}
          >
            <Text variant='regular' >Get Started</Text>
          </Link>
        </SectionOne>
        <SectionTwo>
          <Screenshots />
        </SectionTwo>
      </GetStartedContainer>
    </GetStartedWrapper>
  )
})

export default GetStartedNow
