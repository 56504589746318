import styled from "styled-components"
import { colors, spacing, size } from "../../styles/_var"
import Button from "../Button"
import Text from "../Text"


export const RemainingFields = styled(({ hidden, ...rest }) => <div {...rest} />)`
  transition: all ease .2s;
  overflow: hidden;

  ${({ hidden }) => hidden ? `
    max-height: 1px;
    opacity: 0;
  ` : `
    max-height: 1000px;
    opacity: 1;
  `}
`

export const ShowMoreText = styled(Text)`
  position: absolute;
  right: 0;
  margin-top: -${spacing.sm} !important;
  color: ${colors.primary};
  transition: opacity ease .2s;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`

export const SimpleFormWrapper = styled(({ disabled, ...rest }) => <div {...rest} />)`
  position: relative;
  ${({ disabled }) => disabled && `
    filter: grayscale(100%);
    pointer-events: none;
  `}
`

export const FormWrapper = styled(({ landscape, ...rest }) => <div {...rest} />)`
  ${({ landscape }) => landscape && `
    @media (min-width: ${size.md}) {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: ${spacing.xs};
    }
  `}
`

export const ActionButtonWrapper = styled.div`
  text-align: center;
`

export const ActionButton = styled(Button)`
  min-width: 30rem;
`