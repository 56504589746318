import React, { CSSProperties, FunctionComponent } from 'react'
import MenuItem from '@mui/material/MenuItem'
import { SelectChangeEvent } from '@mui/material/Select'
import countries from '../../assets/json/countries.json'
import { Wrapper, StyledInput, StyledLabel, StyledSelect } from './PhoneNoInput.styles'



interface PhoneNoInputProps {
  extension: string,
  onChange: any,
  onExtensionChange?: (e: SelectChangeEvent<any>) => void,
  error?: boolean,
  helperText?: string,
  className?: string,
  style?: CSSProperties
}

const PhoneNoInput: FunctionComponent<PhoneNoInputProps> = React.memo(({
  onExtensionChange,
  helperText,
  extension,
  style,
  className,
  ...rest
}) => {


  return (
    <Wrapper className={className} style={style}>
      <div>
        <StyledLabel id='extension'>Ext</StyledLabel>
        <StyledSelect
          variant='standard'
          labelId='extension'
          value={extension}
          onChange={onExtensionChange}
        >
          {countries.map((country, i) => (
            <MenuItem value={country.dialCode} key={i}>
              {country.emoji} {country.dialCode} ({country.code})
            </MenuItem>
          ))}
        </StyledSelect>
      </div>
      <StyledInput label="Phone number" {...rest}  />
    </Wrapper>
  )
})

export default PhoneNoInput