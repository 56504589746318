import styled from 'styled-components'
import { NavbarHeight } from '../../../components/Navbar/Navbar.styles'
import PageContent from '../../../components/PageContent'
import Text from '../../../components/Text'
import { Menu } from '../../../components/UserAvatarButton/UserAvatarButton.styles'
import { colors, device, spacing } from '../../../styles/_var'

export const MainWrapper = styled.div`
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
`
export const Wrapper = styled(PageContent)<{$isMobile?: boolean}>`
  ${({ $isMobile }) => !$isMobile && `
    padding-top: ${NavbarHeight};
    height: calc(100vh - ${NavbarHeight});
  `}
  display: flex;
  padding-left: 0;
  padding-right: 0;
`

export const ChatRoomSections = styled.div`
  width: 100%;
  border-left: solid 1px ${colors.lightgrey};
  border-right: solid 1px ${colors.lightgrey};
  overflow: hidden;
  height: calc(100% - ${NavbarHeight});
  
  @media${device.md} {
    height: 100%;
    width: 40rem;
  }
`

export const ChatMessagesSection = styled.div<{$isMobile?: boolean}>`
  position: fixed;
  width: 100%;
  border-left: solid 1px ${colors.lightgrey};
  border-right: solid 1px ${colors.lightgrey};
  ${({ $isMobile }) => $isMobile ? `
    height: 100%;
  ` : `
    height: calc(100% - ${NavbarHeight} - ${NavbarHeight});
  `}
  overflow: hidden;

  @media${device.sm} {
    width: 100%;
  }
  
  @media${device.md} {
    position: relative;
    height: 100%;
    width: calc(100% - 40rem);
  }
`

export const ChatRoomWrapper = styled.div`
  height: 100%;
  overflow: scroll;
`

export const ChatRoomItem = styled(({ active, ...rest }) => <div {...rest} />)`
  display: flex;
  padding: 2.7rem 2.8rem;
  border-bottom: solid 1px ${colors.lightgrey};
  align-items: center;
  justify-content: space-between;
  transition: opacity ease .2s;
  cursor: pointer;

  &:hover {
    opacity: .7;
  }

  ${({ active }) => active && `
    background: ${colors.lightyellow};
  `}
`

export const Content = styled.div`
  display: flex;
`

export const ChatRoomHeader = styled.div`
  padding: ${spacing.xs} 2.8rem;
  height: 4rem;
  text-align: center;
  border-bottom: solid 1px ${colors.lightgrey};
  display: flex;
  justify-content: space-between;
  align-items: center;
  
  svg {
    transition: opacity ease .2s;
    cursor: pointer;
    &:hover {
      opacity: .5;
    }
  }
`

export const ChatMessageOptionsWrapper = styled(Menu)`
  position: unset;
`

export const MessageInputWrapper = styled.div`
  height: 11.5rem;
  position: absolute;
  bottom: 0;
  border-top: solid 1px ${colors.lightgrey};
  border-bottom: solid 1px ${colors.lightgrey};
  width: 100%;
  padding: ${spacing.xs};
  box-sizing: border-box;
  
  @media${device.md} {
  }
`

export const FormField = styled.div`
  display: flex;
  margin-bottom: 5px;
  justify-content: space-between;
  
  & .MuiInputBase-root {
    background-color: ${colors.lightgrey};
    border-radius: 20px;
    margin-right: 10px;
  }
`

export const ChatMessagesWrapper = styled.div`
  padding: ${spacing.sm};
  display: flex;
  flex-direction: column;
  height: calc(100% - 24.5rem);
  overflow: scroll;
  scroll-behavior: smooth;
`

export const MessageBubbleWrapper = styled(({ isMe, sending, ...rest }) => <div {...rest} />)`
  display: flex;
  max-width: 80%;
  align-items: center;
  margin-bottom: ${spacing.sm};
  align-self: flex-end;
  flex-direction: column;

  ${({ isMe }) => !isMe && `
    align-self: flex-start;
  `}

  ${({ sending }) => sending && `
    opacity: 0.3;
  `}
`

export const Bubble = styled(({ isMe, failed, ...rest }) => <Text {...rest} />)`
  padding: 1.5rem 2rem;
  background: ${colors.lightgrey};
  border-radius: 20px;

  ${({ isMe }) => isMe && `
    background: ${colors.lightPrimary};
  `}

  ${({ failed }) => failed && `
    background: ${colors.danger};
    color: ${colors.white};
  `}
`

export const MessageSection = styled.div`
  display: flex;
`

export const UnreadMarker = styled.div`
  height: 14px;
  width: 14px;
  background: ${colors.red};
  border-radius: 50%;
  position: absolute;
  top: -5px;
  right: -5px;
`

export const ImageWrapper = styled(({ src, failed, ...rest }) => <div {...rest} />)`
  width: 15rem;
  height: 20rem;
  border: solid 2px ${colors.grey};
  border-radius: 10px;
  margin-left: ${spacing.xs};
  cursor: pointer;
  transition: opacity ease .2s;
  opacity: 1;

  &:hover {
    opacity: 0.7;
  }

  ${({ src }) => src && `
    background-size: cover;
    background-position: center;
    background-image: url("${src}");
    background-repeat: no-repeat;
  `}

  ${({ failed }) => failed && `
    border: solid 2px ${colors.red};
  `}
`

export const ChatImage = styled.img`
  margin-left: ${spacing.xs};
`
