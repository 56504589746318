import { faImage } from '@fortawesome/free-regular-svg-icons'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import React, { useState} from 'react'
import Button from '../../../components/Button'
import ImageUpload from '../../../components/ImageUpload'
import Input from '../../../components/Input'
import Text from '../../../components/Text'
import TextWithIcon from '../../../components/TextWithIcon'
import { colors } from '../../../styles/_var'
import { MessageType } from '../../../utils/constants'
import { RightButton } from '../Stories/CreateStoryForm'
import { ChatImage, FormField, MessageInputWrapper } from './Messages.styles'

type MessageInputFieldProps = {
  onSend: (message: string, type?: number) => void
}

const MessageInputField = React.forwardRef<HTMLInputElement, MessageInputFieldProps>(({
  onSend
}, ref) => {
  const [value, setValue] = useState('')
  const [imageUrl, setImageUrl] = useState('')

  const send = () => {
    if (imageUrl) {
      onSend(imageUrl, MessageType.IMAGE)
      setImageUrl('')
    } else if (value.trim()) {
      onSend(value)
      setValue('')
    } 
  }
  const onKeypress = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.which === 13 || e.key === 'Enter') {
      send()
    }
  }

  const onValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value)
  }

  const removeImage = () => setImageUrl('')

  const isImage = !!imageUrl

  return (
    <MessageInputWrapper>
      <FormField>
        {isImage ? (
          <ChatImage 
            src={imageUrl}
            alt='chat message'
            height="40px"
            width="auto"
          />
        ) : (
          <Input
            ref={ref}
            onChange={onValueChange}
            onKeyDown={onKeypress}
            value={value}
            variant="outlined"
            placeholder='Type your message here'
          />
        )}
        <Button
          adornment={<RightButton />}
          adornmentPosition='end'
          onClick={send}
        >
          <Text variant='regular'>Send</Text>
        </Button>
      </FormField>
      {isImage ? (
        <TextWithIcon
          icon={faTimes}
          link
          ml={2}
          variant='regular'
          color={colors.primary}
          onClick={removeImage}
        >
          Remove photo
        </TextWithIcon>
      ): (
        <ImageUpload
          maxWidth={600}
          noPreview
          onImageChange={croppedImage => setImageUrl(croppedImage)}
          contentArea={({ openFileChooser }) => (
            <TextWithIcon
              link
              icon={faImage}
              ml={2}
              variant='regular'
              onClick={openFileChooser}
              color={colors.primary}
            >
              Add Photo
            </TextWithIcon>
          )}
        />
      )}
    </MessageInputWrapper>
  )
})

export default MessageInputField
