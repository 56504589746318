import styled from "styled-components";
import { colors, spacing } from "../../styles/_var";
import Button from "../Button";
import Input from "../Input";

export const Wrapper = styled.div`
  margin-bottom: ${spacing.sm};
`

export const CommentTextBox = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  margin-bottom: ${spacing.sm};
`

export const CommentInputField = styled(Input)`
  & .MuiInputBase-root {
    border-radius: 20px;
    background-color: ${colors.white};
    padding-right: 4.7rem;

    &.Mui-disabled {
      background-color: ${colors.lightgrey};
    }

    & textarea {
      padding-bottom: 0;

      &::placeholder {
        font-style: italic;
      }
    }
  }
`

export const PostCommentButton = styled(Button)`
  border-radius: 50%;
  width: 3.8rem;
  height: 3.5rem;
  min-width: unset;
  right: 8px;
  position: absolute;
`