import React from 'react'
import { DashboardWrapper } from '../../layouts/MainDashboardLayout/MainDashboardLayout'
import GetStartedNow from './GetStartedNow'
import LandingFooter from './LandingFooter'
import LandingPageBanner from './LandingPageBanner'
import LandingPageNav from './LandingPageNav'
import WhatIsBaerify from './WhatIsBaerify'

const LandingPage = () => {
  return (
    <DashboardWrapper style={{background: 'white'}}>
      <LandingPageNav />
      <LandingPageBanner />
      <WhatIsBaerify />
      <GetStartedNow />
      <LandingFooter />
    </DashboardWrapper>
  )
}

export default LandingPage
