import React from "react"
import DialogTitle from '@mui/material/DialogTitle';
import MaterialDialog from '@mui/material/Dialog';
import { observer } from "mobx-react-lite";
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import uiStore from "../../stores/uiStore";


export const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});


const Dialog = () => {
  const { 
    dialogPayload: { 
      dialogTitle,
      dialogBody,
    }
  } = uiStore
  return (
    <MaterialDialog
      open={uiStore.showDialog}
      TransitionComponent={Transition}
      onClose={() => {
        uiStore.closeDialog()
      }}
    >
      {!!dialogTitle && <DialogTitle>{dialogTitle}</DialogTitle>}
      {!!dialogBody && dialogBody}
    </MaterialDialog>
  )
}

export default observer(Dialog)
  