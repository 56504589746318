import React from 'react'
import Grid from '@mui/material/Grid'
import Box from '../../components/Box'
import Text from '../../components/Text'
import { colors } from '../../styles/_var'
import TextWithIcon from '../../components/TextWithIcon'
import { faFileEdit, faHeartBroken, faNoteSticky } from '@fortawesome/free-solid-svg-icons'
import PageContent from '../../components/PageContent'


const WhatIsBaerify = React.memo(() => {
  return (
    <PageContent>
      <Box mt={6} mb={6} alignCenter>
        <Text variant='medium' weight='bold' color={colors.primary} uppercase>
          What is Baerify?
        </Text>
        <Text variant='medium' color={colors.darkgrey} maxWidth={400}>
          <strong>/bay-ree-fy/</strong> <em>Verb</em>: To confirm if your bae is in actual fact your bae.
        </Text>

        <Box mt={4}>
          <Grid container spacing={6}>
            <Grid item xs={12} sm={6} md={4} lg={4}>
              <TextWithIcon
                iconSize='3x'
                icon={faHeartBroken}
                variant='medium'
                weight='bold'
                color={colors.primary}
                center
              >
                BAERIFICATION
              </TextWithIcon>
              <Text
                variant='medium'
                mt={1}
                justify color={colors.darkgrey}
              >
                Use our sophisticated baerification to determine if your Bae is really your Bae. Also add baes to your profile and get notified if your Bae has a conflict.
              </Text>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={4}>
              <TextWithIcon
                iconSize='3x'
                icon={faNoteSticky}
                variant='medium'
                weight='bold'
                color={colors.primary}
                center
              >
                READ STORIES
              </TextWithIcon>
              <Text
                variant='medium'
                mt={1}
                justify color={colors.darkgrey}
              >
                Read amazing and spell binding true stories on relationships and infidelity from hundreds of different writers and everyday people from around the world.
              </Text>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={4}>
              <TextWithIcon
                iconSize='3x'
                icon={faFileEdit}
                variant='medium'
                weight='bold'
                color={colors.primary}
                center
              >
                SHARE STORIES
              </TextWithIcon>
              <Text
                variant='medium'
                mt={1}
                justify color={colors.darkgrey}
              >
                Share your personal stories with the world. Remember that Maya Angelou once said "There is no greater agony than bearing an untold story inside you."
              </Text>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </PageContent>
  )
})

export default WhatIsBaerify