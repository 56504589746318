import styled from "styled-components";
import PageContent from "../../components/PageContent";
import { device, spacing } from "../../styles/_var";

export const Wrapper = styled(PageContent)`
  margin-top: ${spacing.sm};
`

export const Logo = styled.img`
  height: 35px;
  width: auto;
  margin-left: auto;
  margin-right: auto;
  display: block;
  margin-bottom: ${spacing.xxs};

  @media ${device.sm} {
    height: 70px;
  }
`

export const Section = styled.div`
  margin-bottom: ${spacing.xs};
`