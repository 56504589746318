import React from "react"
import { routes } from '../../utils/constants'
import logo from '../../assets/images/logo2.png'
import Link from '../Link'
import { 
  NavbarWrapper,
  NavbarContent,
  SiteLogo,
} from './NavbarShell.styles'

type NavbarShellProps = {
  children: React.ReactNode
}

const NavbarShell: React.FC<NavbarShellProps> = React.memo(({ children }) => {
  return (
    <NavbarWrapper>
      <NavbarContent>
        <Link to={routes.DASHBOARD}>
          <SiteLogo src={logo} />
        </Link>
        {children}
      </NavbarContent>
    </NavbarWrapper>
  )
})

export default NavbarShell
  