import { action, computed, makeObservable, observable } from "mobx";
import { apiRoutes, AWS_BASE_URL, CHAT_MESSAGES_FOLDER, getPaginationDefaults, MessageType, methods } from "../utils/constants";
import { ChatMessageType, ChatRoomType, IPagination, UserSummary } from "../utils/interfaces";
import EntityStore, { Config, defaultConfig } from "./entityStore";
import uiStore from "./uiStore";
import { userStore } from "./userStore";
import { fetch, getThumbnailUrl, uploadToS3 } from '../utils/helpers'
import * as gen from '../utils/generated'
import { dataTypes } from "../utils/websocketHandler";
import Auth from "../utils/auth";
import ImageCropper from "../utils/imageCropper";

type Conversations = {
  [key: string]: ConversationType
}

type ConversationType = {
  pagination_info: IPagination,
  messages: ChatMessageType[]
}

export const CHAT_MESSAGES_LIMIT = 20

const packageMessage = (message: string, participant: number, type?: number) => {
  return {
    id: 0,
    participant,
    message,
    type,
    uuid: Date.now(),
  }
}

const publishMessageCreated = (recipientUsername: string | undefined, createdMessage: ChatMessageType) => {
  if (!recipientUsername) return 
  gen.publish(recipientUsername, JSON.stringify({
    type: dataTypes.NEW_CHAT_MESSAGE,
    payload: {...createdMessage, roomID: chatRoomStore.selectedRoom?.uuid}
  }))
}

const publishChatroomDeleted = (recipientUsername: string | undefined, roomID: string) => {
  if (!recipientUsername) return
  gen.publish(recipientUsername, JSON.stringify({
    type: dataTypes.DELETE_CHAT_MESSAGE,
    payload: { roomID }
  }))
}

const packageCreatedMessage = (sentMessage: ChatMessageType, createdMessage: ChatMessageType) => {
  return {
    ...sentMessage,
    id: createdMessage.id,
    created_at: createdMessage.created_at,
    succeeded: true
  }
}

const uploadImageToImageServer = async (image: string) => {
  const filename = `${CHAT_MESSAGES_FOLDER}${Auth.fetchUsername()}_${Math.floor(Date.now()/1000)}.jpg`
  const imageUrl = `${AWS_BASE_URL}/${filename}`

  const thumbnailFilename = getThumbnailUrl(filename) 
  const thumbnailImage = await ImageCropper.createImage(image)
  const thumbnailBlob = await ImageCropper.resizeImage(thumbnailImage, 200)
  const thumbnail = thumbnailBlob ? URL.createObjectURL(thumbnailBlob) : ''

  await Promise.all([
    uploadToS3({ filename, fileUrl: image }), 
    uploadToS3({ filename: thumbnailFilename, fileUrl: thumbnail })
  ])

  return imageUrl
}


class ChatMessageStore extends EntityStore<ChatMessageType, {message: string, type?: number}> {
  config: Config = {
    ...defaultConfig,
    url: apiRoutes.CHAT_ROOMS,
    shouldUseToast: false,
  }

  constructor() {
    super()
    makeObservable(this, {
      sendChatMessage: action
    })
  }

  syncWithChatRoom() {
    const roomId = chatRoomStore.selectedRoom?.uuid
    const newConversation = {
      pagination_info: this.paginationInfo,
      messages: this.list
    }

    chatRoomStore.addRoomConversation({uuid: roomId} as ChatRoomType, newConversation)
  }

  async sendChatMessage(msg: string, type?: number) {
    const userParticipantID = chatRoomStore.getUserParticipant()?.id
    const participantUsername = chatRoomStore.getParticipant()?.user.username
    if (!userParticipantID) return

    let message = msg
    const sentMessage = packageMessage(message, userParticipantID, type)
    this.addToList(sentMessage, '-')
    this.syncWithChatRoom()

    if (type === MessageType.IMAGE) {
      try {
        message = await uploadImageToImageServer(message)
        sentMessage.message = message
      } catch {
        this.updateInListBy('uuid', sentMessage.uuid, {...sentMessage, succeeded: false})
        this.syncWithChatRoom()
        return
      }
    }

    const onMessageCreated = (data: ChatMessageType) => {
      const createdMessage = packageCreatedMessage(sentMessage, data)
      this.updateInListBy('uuid', sentMessage.uuid, createdMessage)
      this.syncWithChatRoom()
      chatRoomStore.updateRoomThumbnailInfo(chatRoomStore.selectedRoom?.uuid, createdMessage)
      publishMessageCreated(participantUsername, createdMessage)
    }

    const onMessageFailed = () => {
      const createdMessage = {...sentMessage, succeeded: false}
      this.updateInListBy('uuid', sentMessage.uuid, createdMessage)
    }

    this.createEntity({ message, type }, {
      url: apiRoutes.CHAT_MESSAGES.replace(':roomId', chatRoomStore.selectedRoom?.uuid || ''),
      shouldAddToList: false,
      onCreatedItem: data => onMessageCreated(data),
      onFailedToCreate: () => onMessageFailed
    })
  }
}

class ChatRoomStore extends EntityStore<ChatRoomType, {participants: string[]}> {
  selectedRoom: ChatRoomType | null = null
  conversations: Conversations = {}

  config: Config = {
    ...defaultConfig,
    url: apiRoutes.CHAT_ROOMS,
    idKey: 'uuid'
  }

  constructor() {
    super()
    makeObservable(this, {
      selectedRoom: observable,
      setActiveRoom: action,
      selectActiveRoom: action,
      addRoomConversation: action,
      addMessageToRoom: action,
      hasUnread: computed
    })
  }

  getParticipant(room?: ChatRoomType) {
    const lookupRoom = room || this.selectedRoom
    return lookupRoom?.participants.find(p => p.user.username !== userStore.username)
  }

  getUserParticipant(room?: ChatRoomType) {
    const lookupRoom = room || this.selectedRoom
    return lookupRoom?.participants.find(p => p.user.username === userStore.username)
  }

  setActiveRoom(room: ChatRoomType | null) {
    this.selectedRoom = room
  }

  addRoomConversation(room: ChatRoomType, roomConversation: ConversationType) {
    this.conversations = {
      ...this.conversations,
      [room.uuid]: {
        pagination_info: roomConversation.pagination_info || getPaginationDefaults(),
        messages: roomConversation.messages || []
      }
    }
  }

  selectActiveRoom(room: ChatRoomType) {
    this.setActiveRoom(room)
    if (isBaerifyAdminChat(room)) return

    const selectedConversation = this.conversations[room.uuid] || {
      messages: [],
      pagination_info: getPaginationDefaults()
    }

    chatMessageStore.setListData(selectedConversation.messages, 1)
    chatMessageStore.setPaginationInfo(selectedConversation.pagination_info)

    if (!!selectedConversation.messages.length) return


    chatMessageStore.listEntity({
      url: apiRoutes.CHAT_MESSAGES.replace(':roomId', room.uuid),
      page: 1,
      onFetchedList: (data, pagination) => {
        this.addRoomConversation(room, {
          messages: data,
          pagination_info: pagination
        })
      },
    })
  }

  removeChatRoom(roomId: string) {
    this.deleteFromListBy('uuid', roomId)
    if (this.selectedRoom?.uuid !== roomId) return
    
    this.setActiveRoom(null)
    chatMessageStore.clearData()
  }

  deleteChatRoom() {
    const onChatDelete = (room: ChatRoomType | null) => {
      this.setActiveRoom(null)
      chatMessageStore.clearData()
      if (!room) return

      const participant = this.getParticipant(room)
      publishChatroomDeleted(participant?.user.username, room.uuid)
    }

    uiStore.openConfirmDialog({
      dialogTitle: 'Delete Confirmation',
      dialogBody: 'Are you sure you want to end this conversation?',
      onConfirmAction: () => {
        const room = this.selectedRoom
        this.deleteEntity({
          itemId: this.selectedRoom?.uuid,
          onDeletedItem: () => onChatDelete(room),
        })
      }
    })
  }

  startNewChat(recipient: string, onComplete?: () => void) {
    const participants = [recipient, userStore.username]
    this.createEntity({ participants }, {
      onCreatedItem: data => {
        this.addToList(data)
        this.selectActiveRoom(data)
        onComplete && onComplete()
      },
    })
  }

  addMessageToRoom(roomId: string, chatMessage: ChatMessageType) {
    const currentConversations = this.conversations[roomId]
    if (!currentConversations?.messages?.length) return
    const newConversation = {
      pagination_info: currentConversations?.pagination_info,
      messages: [chatMessage, ...(currentConversations?.messages || [])]
    }
    this.addRoomConversation({uuid: roomId} as ChatRoomType, newConversation)
  }

  getNewChatRoom(roomId: string) {
    this.listEntity({ 
      page: 1,
      onFetchedList: () => {
        const room = this.getFromList(roomId, 'uuid')
        if (room) this.selectActiveRoom(room)
      }
    })
  }

  updateRoomThumbnailInfo(roomId: string | undefined, latestChat: ChatMessageType) {
    if (!roomId) return
    const room = this.getFromList(roomId, 'uuid')
    if (!room) return
    
    const updatedRoom = {
      ...room,
      last_message: latestChat.message,
      updated_at: latestChat.created_at || new Date().toISOString()
    }
    this.updateInList(updatedRoom)
  }

  incrementUnread(room?: ChatRoomType) {
    if (!room) return
    const newRoom = {...room, unread: room.unread + 1}
    this.updateInList(newRoom)
  }

  loadChatRooms() {
    const createdAt = userStore?.item?.created_at || ''
    this.listEntity({ 
      page: 1,
      onFetchedList: data => {
        if (data.length) return
        this.addToList({...WelcomeChatRoom, updated_at: createdAt})
      }
    })
  }

  async markAsRead(room: ChatRoomType, force?: boolean) {
    if (room.unread === 0 && !force) return
    const url = `${apiRoutes.CHAT_ROOMS}/${room.uuid}/mark_as_read`
    const newRoom = {...room, unread: 0}

    fetch({ url, method: methods.POST})
    this.updateInList(newRoom)
  }

  get hasUnread() {
    return this.list.some(room => room.unread > 0)
  }
}

export const chatRoomStore = new ChatRoomStore()
export const chatMessageStore = new ChatMessageStore()

export const BaerifyAdmin: UserSummary = {
  avatar: 'https://s3.eu-west-1.amazonaws.com/baerify.com/logo-high.png',
  first_name: 'Welcome to',
  last_name: 'Baerify',
  username: 'bodunde'
}

export const WelcomeChatRoom: ChatRoomType = {
  uuid: '',
  updated_at: userStore.item?.created_at || '',
  last_message: 'Hey, Welcome and thank you for joining baerify',
  last_message_sender: BaerifyAdmin,
  participants: [
    {id: 0, user: BaerifyAdmin}
  ],
  unread: 0
}

export const WelcomeChatMessage: ChatMessageType = {
  id: 0,
  participant: 0,
  created_at: userStore.item?.created_at || '',
  message: 'Hey, Welcome and thank you for joining baerify. Your messages would reflect here.',
  succeeded: true,
  seen: true
}

export const isBaerifyAdminChat = (room: ChatRoomType | null) => {
  if (!room) return false;
  return room?.participants?.length === 1 && room.participants[0].user.username === 'bodunde'
}