import styled from 'styled-components'
import MCheckbox from '@mui/material/Checkbox'
import { colors } from '../../styles/_var'

export const StyledCheckbox = styled(MCheckbox)`
  &.MuiCheckbox-root.Mui-checked {
    color: ${colors.primary};
  }

  ${({ color }) => color === 'secondary' && `
    &.MuiCheckbox-root {
      color: ${colors.red};
    }
  `}
`