import React, { FunctionComponent, useState } from "react"
import { faCaretDown, faCaretUp, faHeart } from "@fortawesome/free-solid-svg-icons"
import { faHeart as EmptyHeart } from "@fortawesome/free-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Avatar from "@mui/material/Avatar"
import { userStore } from "../../stores/userStore"
import { colors } from "../../styles/_var"
import { getPaginationDefaults } from "../../utils/constants"
import { humanize } from "../../utils/helpers"
import { IPagination } from "../../utils/interfaces"
import CommentReplyForm from "../CommentReplyForm"
import Text from "../Text"
import {
  CommentContent,
  Wrapper,
  CommentInfo,
  CommentBody,
  CommentWrapper,
  ReplyWrapper
} from './Comment.styles'
import TextWithIcon from "../TextWithIcon"
import { useSearchParams } from "react-router-dom"

export type CommentType = {
  id: number,
  username: string,
  content: string,
  created_at?: string,
  avatar: string,
  liked?: boolean,
  likes?: number,
  num_of_replies?: number,
  replies?: {
    comments: CommentType[],
    pagination_info: IPagination
  }
}

export type CommentActionsType = {
  onClickShowReplyForm?: (commentId: number) => void,
  onClickDelete?: (commentId: number) => void,
  postReply?: (reply: string) => void,
  like?: () => void,
  showReplyForm?: boolean,
  deleting?: boolean,
  loadReplies?: () => void,
  deleteReply?: (replyId: number) => void,
  likeReply?: (reply: CommentType) => void,
  isReply?: boolean
}

const DEFAULT_FUNC = () => {}

const Comment: FunctionComponent<CommentType & CommentActionsType> = React.memo(({
  id,
  avatar,
  username,
  content,
  created_at,
  liked,
  likes,
  num_of_replies,
  onClickDelete=DEFAULT_FUNC,
  onClickShowReplyForm,
  postReply,
  showReplyForm,
  deleting,
  like=DEFAULT_FUNC,
  likeReply=DEFAULT_FUNC,
  loadReplies,
  isReply=false,
  deleteReply=DEFAULT_FUNC,
  replies = {
    comments: [],
    pagination_info: getPaginationDefaults()
  }
}) => {
  const [searchParams] = useSearchParams()
  const commentId = searchParams.get('commentId')
  const replyingTo = searchParams.get('replyingTo')
  const showReplyDefault = (replyingTo === `${id}`)

  const [showReplies, setShowReplies] = useState(showReplyDefault)
  const onShowReplyForm = () => {
    onClickShowReplyForm && onClickShowReplyForm(id)
  }

  const onShowReplies = () => {
    setShowReplies(!showReplies)
    if (!showReplies) loadReplies && loadReplies()
  }

  const ownsComment = userStore.username === username

  return (
    <Wrapper deleting={deleting} notifying={commentId === `${id}`}>
      <Avatar 
        src={avatar}
        alt='comment-author'
        sx={{width: 25, height: 25}}
      >
        {username[0].toUpperCase()}
      </Avatar>
      <CommentContent>
        <CommentWrapper>
          <CommentBody>
            <Text variant="regular" weight="bold">{username}</Text>
            <Text variant="regular">{content}</Text>
            <CommentInfo>
              <Text variant="small"  color={colors.grey}>{humanize(created_at || '')}</Text>
              <Text variant="small"  color={colors.grey}>{likes} likes</Text>
              {!isReply && (
                <Text
                  link
                  weight='bold'
                  variant="small"
                  color={colors.grey}
                  onClick={onShowReplyForm}
                >
                  Reply
                </Text>
              )}
              {ownsComment && (
                <Text
                  link
                  weight='bold'
                  variant="small"
                  color={colors.grey}
                  onClick={() => isReply ? deleteReply(id) : onClickDelete(id)}
                >
                  Delete
                </Text>
              )}
            </CommentInfo>
            {showReplyForm && <CommentReplyForm onReply={postReply} />}
            {(!isReply && !!num_of_replies) && (
              <TextWithIcon 
                maxWidth={70}
                mt={1} 
                color={colors.darkgrey}
                icon={showReplies ? faCaretUp : faCaretDown}
                variant='small'
                weight='bold'
                onClick={onShowReplies}
              >
                {num_of_replies} Replies
              </TextWithIcon>
            )}
          </CommentBody>
          <FontAwesomeIcon
            size='2x'
            onClick={like}
            color={colors.primary}
            icon={liked ? faHeart : EmptyHeart}
          />
        </CommentWrapper>
        {showReplies && (
          <ReplyWrapper>
            {replies.comments.map(reply => (    
              <Comment
                {...reply}
                key={reply.id}
                onClickDelete={onClickDelete}
                deleteReply={deleteReply}
                like={() => likeReply(reply)}
                isReply={true}
              />
            ))}
            {(replies.pagination_info.has_next && !!replies.pagination_info.current_page) && (
              <Text
                link
                mb={1}
                ml={2}
                inline
                weight='bold'
                variant="small"
                color={colors.primary}
                onClick={loadReplies}
              >
                Load more replies.
              </Text>
            )}
          </ReplyWrapper>
        )}
      </CommentContent>
    </Wrapper>
  )
})

export default Comment
