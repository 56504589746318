import React, { FunctionComponent } from 'react'
import Text from '../Text'
import {
  CodeInput,
  CodeInputWrapper,
  HiddenInputField,
  Wrapper
} from './ActivationCodeInput.styles'

type ActivationCodeInputProps = {
  onChange: () => void;
  value: string;
}

const ActivationCodeInput: FunctionComponent<ActivationCodeInputProps> = React.memo(({
  value,
  onChange
}) => {
  const [one, two, three, four] = value.split('')

  return (
    <Wrapper>
      <HiddenInputField 
        inputRef={input => input && input.focus()}
        value={value}
        onChange={onChange}
      />
      <CodeInputWrapper>
        <CodeInput>
          <Text center weight="bold" variant="medium">{one ? one.toUpperCase() : ''}</Text>
        </CodeInput>
        <CodeInput>
          <Text center weight="bold" variant="medium">{two ? two.toUpperCase() : ''}</Text>
        </CodeInput>
        <CodeInput>
          <Text center weight="bold" variant="medium">{three ? three.toUpperCase() : ''}</Text>
        </CodeInput>
        <CodeInput>
          <Text center weight="bold" variant="medium">{four ? four.toUpperCase() : ''}</Text>
        </CodeInput>
      </CodeInputWrapper>
    </Wrapper>
  )
})

export default ActivationCodeInput

