import styled from "styled-components"
import { Link } from 'react-router-dom'
import { fontSize } from "../../styles/_var"
import { getStyle } from "../Button/Button.styles"


export const StyledButtonLink = styled(({ variant, invertOnHover, block, ...rest }) => <Link {...rest} />)`
  ${({ variant, invertOnHover, block }) => {
    return getStyle(variant, invertOnHover, block)
  }}
`

export const StyledLink = styled(({ boldLink, ...rest }) => <Link {...rest} />)`
  text-decoration: none;
  ${({ boldLink }) => boldLink && `
    text-decoration: none;
    font-weight: bold;
    font-size: ${fontSize.sm};
  `}
`
