import React, { FunctionComponent, useRef, useState } from 'react'
import ImageCropper from '../../utils/imageCropper'
import ImagePreviewer from './ImagePreviewer'
import { HiddenFileInput } from './ImageUpload.styles'

export interface IContentAreaArgs {
  croppedImage: string,
  rawImage: string,
  openFileChooser: () => void,
}

interface IImageUpload {
  contentArea: (payload: IContentAreaArgs) => any,
  aspectRatio?: number,
  maxWidth?: number,
  onImageChange?: (image: string) => void,
  noPreview?: boolean
}

const ImageUpload: FunctionComponent<IImageUpload> = ({
  onImageChange,
  aspectRatio=1,
  maxWidth=500,
  contentArea,
  noPreview=false
}) => {
  const input:any = useRef(null);
  const [croppedImage, setCroppedImage] = useState('')
  const [rawImage, setRawImage] = useState('')

  const openFileChooser = () => {
    input.current?.click()
  }

  const onInputChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const input = evt.target
    if (input.files && input.files[0]) {
      const reader = new FileReader();
      
      reader.onload = async (e:any) => {
        const rawImage = e.target.result
        setRawImage(rawImage)
        evt.target.value = null as any

        if (noPreview && rawImage) {
          const croppedImage: string = await processForNoPreview(rawImage) as string
          onComplete(croppedImage)
        }
      }
      reader.readAsDataURL(input.files[0]); // convert to base64 string
    }
  }

  const processForNoPreview = async (rawImage: string) => {
    const image = await ImageCropper.createImage(rawImage)

    var MAX_WIDTH = maxWidth;
    var MAX_HEIGHT = maxWidth;

    var width = image.width;
    var height = image.height;

    // resizing logic goes here
    if (width > height) {
      if (width > MAX_WIDTH) {
        height = height * (MAX_WIDTH / width);
        width = MAX_WIDTH;
      }
    } else {
      if (height > MAX_HEIGHT) {
        width = width * (MAX_HEIGHT / height);
        height = MAX_HEIGHT;
      }
    }

    const canvas = document.createElement("canvas");
    canvas.width = width;
    canvas.height = height;
    canvas.getContext("2d")?.drawImage(image, 0, 0, width, height);

    return new Promise(resolve => {
      canvas.toBlob((file: any) => {
        resolve((URL.createObjectURL(file)))
      }, 'image/jpeg')
    })
  }

  const onComplete = (imageUrl: string) => {
    onImageChange && onImageChange(imageUrl)
    setCroppedImage(imageUrl)
  }

  return (
    <>
      {contentArea({ openFileChooser, rawImage, croppedImage })}
      <HiddenFileInput
        type="file"
        name="myImage"
        accept="image/*"
        ref={input}
        onChange={onInputChange} />
      {!!rawImage && !noPreview && (
        <ImagePreviewer
          aspectRatio={aspectRatio}
          imageUrl={rawImage}
          maxWidth={maxWidth}
          onClose={() => setRawImage('')}
          onComplete={onComplete} />
      )}
    </>
  )
}

export default ImageUpload



