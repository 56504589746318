import { observer } from 'mobx-react-lite'
import React, { useCallback, useEffect } from 'react'
import useIsMobile from '../../../hooks/useIsMobile'
import { chatMessageStore, chatRoomStore, isBaerifyAdminChat } from '../../../stores/chatStore'
import ChatConversations from './ChatConversations'
import ChatRooms from './ChatRooms'
import { ChatMessagesSection, ChatRoomSections, Wrapper } from './Messages.styles'
import sortBy from 'lodash/sortBy'
import uniqBy from 'lodash/uniqBy'
import { apiRoutes } from '../../../utils/constants'
import uiStore from '../../../stores/uiStore'


const Messages = () => {  
  const isMobile = useIsMobile()
  const goBack = useCallback(() => {
    chatRoomStore.setActiveRoom(null)
  }, [])

  useEffect(() => {
    if (!chatRoomStore.notFetchedYet) {
      chatRoomStore.loadChatRooms()
    }

    if (isMobile) {
      uiStore.setNavVisibility(false)
    }

    return () => {
      uiStore.setNavVisibility(true)
    }
    // eslint-disable-next-line
  }, [])

  const selectedRoom = chatRoomStore.selectedRoom
  const showRoomSection = !isMobile || (isMobile && !selectedRoom)
  const showMessagesSection = !isMobile || (isMobile && !!selectedRoom)
  const messages = sortBy(uniqBy(chatMessageStore.list, 'id'), e => e.created_at)
  const rooms = sortBy(uniqBy(chatRoomStore.list, 'uuid'), e => e.updated_at).reverse()

  const loadOlder = () => {
    if (selectedRoom) {
      chatMessageStore.listEntity({
        url: apiRoutes.CHAT_MESSAGES.replace(':roomId', selectedRoom.uuid) + `?before=${messages[0].created_at}`,
        page: 1,
        onFetchedList: (data, pagination) => {
          chatMessageStore.list = [...data.reverse(), ...messages]
          chatRoomStore.addRoomConversation(selectedRoom, {
            messages: chatMessageStore.list,
            pagination_info: pagination
          })
        },
      })
    }
  }


  return (
    <Wrapper $isMobile={isMobile}>
      {showRoomSection && (
        <ChatRoomSections>
          <ChatRooms
            rooms={rooms}
            getParticipant={room => chatRoomStore.getParticipant(room)}
            selectActiveRoom={room => {
              chatRoomStore.selectActiveRoom(room)
              chatRoomStore.markAsRead(room)
            }}
            selectedRoom={chatRoomStore.selectedRoom}
          />
        </ChatRoomSections>
      )}
      {showMessagesSection && (
        <ChatMessagesSection $isMobile={isMobile}>
          <ChatConversations
            isBaerifyAdminChat={isBaerifyAdminChat(chatRoomStore.selectedRoom)}
            loadOlder={loadOlder}
            goBack={goBack}
            onDelete={() => chatRoomStore.deleteChatRoom()}
            onSend={(message, type) => chatMessageStore.sendChatMessage(message, type)}
            paginationInfo={chatMessageStore.paginationInfo}
            messages={messages}
            participant={chatRoomStore.getParticipant()}
          />
        </ChatMessagesSection>
      )}
    </Wrapper>
  )
}

export default observer(Messages)
