import React, { FunctionComponent, useState } from 'react';
import { getStyleSpacing } from '../../utils/helpers';
import { ComponentSpacing } from '../../utils/interfaces';
import { TextWrapper, StyledText, Tooltip } from './Text.styles'

export type TextVariant = 'title' | 'small' | 'medium' | 'regular' | 'subtext' | 'large' | 'semi-large'
export type TextWeight = 'regular' | 'bold'

export interface ITextProps extends React.HTMLAttributes<HTMLParagraphElement>, ComponentSpacing {
    variant?: TextVariant,
    className?: string,
    nowrap?: boolean,
    color?: string,
    truncate?: boolean,
    inline?: boolean,
    tooltip?: string,
    center?: boolean,
    left?: boolean,
    right?: boolean,
    justify?: boolean,
    maxWidth?: number,
    opacity?: number,
    lighten?: boolean,
    weight?: number | TextWeight,
    link?: boolean,
    uppercase?: boolean,
    lowercase?: boolean,
    italic?: boolean,
    underline?: boolean,
}

const Text: FunctionComponent<ITextProps> = React.memo(({
  children,
  tooltip,
  mt,
  mb,
  mr,
  ml,
  color,
  maxWidth,
  style={},
  opacity,
  lighten,
  weight,
  ...rest
}) => {
  const [showToolTip, setShowToolTip] = useState<boolean>(false);
  const setToolTip = (status: boolean) => (e: React.MouseEvent) => {
    if (!tooltip) return
    setShowToolTip(status);
  }

  const spacingComponents = { mr, ml, mb, mt }
  const spacingStyle = getStyleSpacing(spacingComponents)
  const componentStyle = {...spacingStyle, ...style}

  if (color) componentStyle.color = color

  if (maxWidth) {
    componentStyle.maxWidth = maxWidth
    componentStyle.marginLeft = 'auto'
    componentStyle.marginRight = 'auto'
  }

  if (opacity || opacity === 0) {
    componentStyle.opacity = opacity
  }

  if (lighten) {
    componentStyle.opacity = 0.6
  }

  if (weight) {
    let fontWeight;
    if (Number.isInteger(weight)) {
      fontWeight = weight
    } else if (weight === 'bold') {
      fontWeight = 600
    } else {
      fontWeight = 400
    }

    componentStyle.fontWeight = fontWeight
  }

  if (!tooltip) return <StyledText {...rest} style={componentStyle}>{children}</StyledText>

  return (
    <TextWrapper inline={rest.inline} style={spacingStyle}>
      <StyledText 
        {...rest}
        style={style}
        onMouseEnter={setToolTip(true)}
        onMouseLeave={setToolTip(false)}
        hasTooltip={!!tooltip}
      >
        {children}
      </StyledText>
      {!!tooltip && <Tooltip showToolTip={showToolTip}>{tooltip}</Tooltip>}
    </TextWrapper>
  )
})


export default Text;