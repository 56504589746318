import React, { useCallback } from "react"
import { observer } from "mobx-react-lite"
import StoryList from "../../pages/dashboard/Stories/StoryList"
import { publishedStoryStore } from "../../stores/storyStore"
import { colors } from "../../styles/_var"
import { Story } from "../../utils/interfaces"
import Text from "../Text"
import { Wrapper } from "./LatestStories.styles"


const LatestStories = () => {
  const makeRequest = useCallback((page?: number) => {
    publishedStoryStore.makeRequest(undefined, { page })
  }, [])

  const loading = publishedStoryStore.loading && !publishedStoryStore.hasData

  return (
    <Wrapper>
      <Text variant="regular" mb={1} color={colors.grey} weight="bold">
        Recent Stories
      </Text>
      <StoryList
        loading={loading}
        makeRequest={makeRequest}
        data={publishedStoryStore.data?.slice(0, 4).map<Story>(({story}) => ({...story, published: true})) || null}
        hasData={publishedStoryStore.hasData}
        showNextButton={publishedStoryStore.showNextButton}
        noDataText="No new stories at the moment"
      />
    </Wrapper>
  )
}

export default observer(LatestStories)
