import React from 'react'
import { observer } from 'mobx-react-lite'
import { useParams } from 'react-router-dom'
import useGetPartner from '../../../hooks/useGetPartner'
import DashboardWithSidebar from '../../../layouts/DashboardWithSidebar'
import { partnerConflictStore, partnerStore } from '../../../stores/partnerStore'
import BaerificationLoader from '../BaerificationResults/BaertificationLoader'
import { PartnerItemHeader } from './Partners.styles'
import { colors, spacing } from '../../../styles/_var'
import { routes } from '../../../utils/constants'
import ConflictResults from '../../../components/ConflictResults'
import useLoadConflicts from '../../../hooks/useLoadConflicts'
import AvatarWithLabel from '../../../components/AvatarWithLabel'
import BackButton from '../../../components/BackButton'
import EmptyState from '../../../components/EmptyState'

const PartnerConflicts = () => {
  const { partnerId } = useParams()
  useGetPartner(partnerId)
  const loadingPageSimulation = useLoadConflicts(partnerId)

  if (!partnerId) return null
  const partner = partnerStore.item
  const fullName = `${partner?.first_name} ${partner?.last_name}`

  return (
    <DashboardWithSidebar>
      <PartnerItemHeader style={{marginBottom: spacing.xs}}>
        <AvatarWithLabel 
          label={`${partner?.first_name} ${partner?.last_name}`}
          src={partner?.image}
          labelProps={{variant: 'regular'}}
          sx={{width: 30, height: 30, border: `solid 2px ${colors.darkgrey}`}}
        />
        <BackButton label='Back to partners' to={routes.VIEW_PARTNER.replace(':partnerId', partnerId)} />
      </PartnerItemHeader>
      {(partnerConflictStore.loading || loadingPageSimulation) && <BaerificationLoader />}
      {(partnerConflictStore.hasData && !loadingPageSimulation) && (
        <ConflictResults
          partnerId={partnerId}
          subject={fullName}
          results={partnerConflictStore.data || []}
        />
      )}
      {!partnerConflictStore.hasData && partnerConflictStore.complete && (
        <EmptyState message={`No matches found for ${fullName}`} />
      )}
    </DashboardWithSidebar>
  )
}

export default observer(PartnerConflicts)
