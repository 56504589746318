import React, { FunctionComponent } from 'react'
import Box from '../../../components/Box'
import Text from '../../../components/Text'
import { colors } from '../../../styles/_var'
import { Subs } from '../../../utils/constants'
import { humanize } from '../../../utils/helpers'
import { INotification } from '../../../utils/interfaces'
import { NotificationItem } from './Notifications.styles'
import { ReactComponent as Icon } from '../../../assets/images/conflict-null.svg'


const SubscriptionChangeView: FunctionComponent<Partial<INotification>> = React.memo(({
  created_at,
  notification_object,
  read
}) => {
  const type = notification_object.type
  return (
    <NotificationItem read={read}>
      <Icon height="55px" width="55px" />
      <Box ml={2}>
        <Text variant='small' color={colors.grey}>
          {created_at && humanize(created_at)}
        </Text>
        {type === 'BR' && (
          <>
            <Text variant='regular' weight='bold' mb={1}>Your account has been downgraded.</Text>
            <Text variant='regular' color={colors.grey}>
              You are now on the Baerify Regular plan. Some amazing features would be unavailable to you on this plan, subscribe to Baerify Plus or Baerify Gold to have access to all our amazing features.
            </Text>
          </>
        )}
        {type !== 'BR' && (
          <>
            <Text variant='regular' weight='bold' mb={1}>Your account has been upgraded</Text>
            <Text variant='regular' color={colors.grey}>
              Thank you for your purchase, you are now subscribed to the the {Subs[type]} plan and your account has been upgraded.
            </Text>
          </>
        )}
      </Box>
    </NotificationItem>
  )
})

export default SubscriptionChangeView