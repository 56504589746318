import styled from "styled-components";
import { colors, spacing } from "../../styles/_var";

export const Wrapper = styled.div``

export const Card = styled(({ justifyContent,  ...rest }) => <div {...rest} />)`
  display: flex;
  margin-bottom: ${spacing.xs};
  border-radius: 20px;
  background-color: ${colors.lightgrey};
  padding: 1.65rem 2rem 2rem;
  justify-content: ${({ justifyContent }) => justifyContent || 'space-between'};
  align-items: center;
  transition: opacity ease .2s;
  cursor: pointer;

  &:hover {
    opacity: .8;
  }
`

export const NameAndLocation = styled.div`
  & > p {
    line-height: normal;
    margin-bottom: 3px;
  }
`

export const Section = styled.div`
  margin-bottom: ${spacing.sm};
`