import React, { FunctionComponent, useEffect } from 'react';
import Text from '../Text';
import { observer } from 'mobx-react-lite';
import uiStore from '../../stores/uiStore';
import Alert from '@mui/material/Alert'
import { StyledStack } from './Toast.styles';


type Intervals = {
  [key: string]: NodeJS.Timeout
}


const Toast: FunctionComponent = () => {
  const { toastMessages } = uiStore

  useEffect(() => {
    const intervals: Intervals = {}
    toastMessages.forEach(toast => {
      if (toast.id && !intervals[toast.id]) {
        intervals[toast.id] = setTimeout(() => uiStore.removeToast(toast.id), toast.duration)
      }
    })
  }, [toastMessages])

  return (
    <StyledStack spacing={1}>
      {uiStore.toastMessages.map((toast) => (
        <Alert 
          onClose={() => uiStore.removeToast(toast.id)}
          severity={toast.severity}
          key={toast.id}
          variant='standard'
        >
          <Text variant="regular">{toast.message}</Text>
        </Alert>
      ))}
    </StyledStack>
  )
}

export default observer(Toast)
