import styled from "styled-components";
import { colors } from "../../styles/_var";

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  border: solid 1px ${colors.lightgrey};
  padding: 4px 11px;
  border-radius: 4px;

  svg circle {
    fill: white;
  }

  p {
    margin-left: 4px;
  }
`