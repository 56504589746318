import { observer } from 'mobx-react-lite'
import React, { FunctionComponent } from 'react'
import { fetchTodaysStatsStore } from '../../stores/userStore'
import { colors } from '../../styles/_var'
import Text from '../Text'
import { Wrapper } from './LeftSidebarComponents.styles'

type ComponentListProps = {
  title: string,
  list: string[]
}

const ComponentList: FunctionComponent<ComponentListProps> = React.memo(({ title, list }) => (
  <Wrapper>
    <Text color={colors.primary} variant='regular' weight="bold">
      {title}
    </Text>
    {list.map((item, i) => <Text variant='small' key={i}>{item}</Text>)}
  </Wrapper>
))


const LeftSideComponents = () => {
  return (
    <>
      <ComponentList title="Today's Stats" list={fetchTodaysStatsStore.partnerStats} />
      <ComponentList title="Stories" list={fetchTodaysStatsStore.storiesStats} />
    </>
  )
}

export default observer(LeftSideComponents)
