import React, { FunctionComponent } from "react"
import Text from "../Text"
import { ReactComponent as CheckCircle } from '../../assets/images/check-circle.svg'
import { Wrapper, ListItem } from './ListOptions.styles'
import { colors } from "../../styles/_var"

export type IList = {
  value: string | number,
  label: string | React.ReactNode,
  Action?: React.ReactNode
}

interface ListOptionsProp {
  lists: IList[],
  onChange?: (item: string | number) => void,
  value?: string | number,
  selectItemLabel?: string
}

const ListOptions: FunctionComponent<ListOptionsProp> = React.memo(({
  lists,
  onChange,
  value,
  selectItemLabel
}) => {
  const onSelect = (item: string | number) => () => {
    onChange && onChange(item);
  }

  const renderAction = (list: IList) => {
    const { Action } = list
    if (Action) return Action

    return <Text variant="small" color={colors.primary} underline>{selectItemLabel}</Text>
  }

  return (
    <Wrapper>
      {lists.map((list, i) => (
        <ListItem key={i} onClick={onSelect(list.value)} selectable={!!onChange}>
          {typeof list.label === 'string' ? <Text variant="regular">{list.label}</Text> : list.label}
          {list.value === value ? <CheckCircle /> : renderAction(list)}
        </ListItem>
      ))}
    </Wrapper>
  )
})

export default ListOptions
  