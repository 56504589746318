import React, { FunctionComponent } from "react"
import { ReactComponent as Dot } from '../../assets/images/dot.svg'
import { colors } from "../../styles/_var"
import Text from "../Text"
import { Wrapper } from "./Label.styles"

type LabelProps = {
  background?: string,
  color?: string
  text: string
}

const Label: FunctionComponent<LabelProps> = ({
  background=colors.danger,
  color=colors.white,
  text='label'
}) => {
  return (
    <Wrapper style={{background}}>
      <Dot />
      <Text variant="small" color={color}>
        {text}
      </Text>
    </Wrapper>
  )
}

export default Label