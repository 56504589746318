import React, { FunctionComponent } from 'react'
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';
import Text from '../Text'
import { colors } from '../../styles/_var';
import { observer } from 'mobx-react-lite';
import uiStore from '../../stores/uiStore';


const LoadingScreen: FunctionComponent = () => {
  const { displayLoadingScreen, loadingScreenMessage } = uiStore

  if (!displayLoadingScreen) return null
  return (
    <Backdrop
      sx={{ zIndex: 2000 }}
      open={true}
      color={colors.white}
    >
      <CircularProgress style={{color: colors.white}} />
      <Text ml={2} variant="medium" color={colors.white}>{loadingScreenMessage}</Text>
    </Backdrop>
  )
}

export default observer(LoadingScreen)
