export const colors = {
  primary: '#D77A61',
  lightPrimary: 'rgba(215, 122, 97, 0.15)',
  verylightPrimary: 'rgba(215, 122, 97, 0.1)',
  white: '#fff',
  red: '#FF3434',
  black: '#000',
  brown: '#381D2A',
  lightgrey: 'rgba(199, 199, 199, 0.24)',
  yellow: 'rgba(248, 232, 183, 0.5)',
  lightyellow: 'rgba(248, 232, 183, 0.25)',
  grey: '#898A8D',
  darkgrey: '#50565E',
  green: '#CCF5F2',
  darkGreen: '#196879',
  mediumgrey: '#DED7C2',
  pink: '#FD397F',
  danger: '#d9534f'
}

export const size = {
  sm: '600px',
  md: '900px',
  lg: '1200px',
  xl: '1536px',
}


 export const device = {
  sm: `(min-width: ${size.sm})`,
  md: `(min-width: ${size.md})`,
  lg: `(min-width: ${size.lg})`,
  xl: `(min-width: ${size.xl})`,
};


export const spacing = {
  xxs: '1rem',
  xs: '1.5rem',
  sm: '3rem',
  rg: '4.5rem',
  lg: '5rem',
  xl: '7rem',
  xxl: '12rem'
}


export const fontSize = {
  xxs: '1rem',
  xs: '1.2rem',
  sm: '1.2rem',
  rg: '1.5rem',
  md: '1.8rem',
  lg: '3.2rem',
  xl: '4rem'
}