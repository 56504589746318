import React from 'react'
import Dot from '../../components/Dot'
import Link from '../../components/Link'
import Text from '../../components/Text'
import { routes } from '../../utils/constants'
import { FooterContent, FooterWrapper } from './LandingPage.styles'

const LandingFooter = React.memo(() => {
  return (
    <FooterWrapper>
      <FooterContent>
        <Link to={routes.TERMS_AND_CONDITIONS}>
          <Text variant='regular'>Terms and Conditions</Text>
        </Link>
        <Dot mr={2} ml={2} />
        <Link to={routes.PRIVACY_POLICY}>
          <Text variant='regular'>Privacy Policy</Text>
        </Link>
        <Dot mr={2} ml={2} />
        <Link to={routes.CONTACT_US}>
          <Text variant='regular'>Contact Us</Text>
        </Link>
        <Dot mr={2} ml={2} />
        <Link to={routes.ABOUT_US}>
          <Text variant='regular'>About Us</Text>
        </Link>
      </FooterContent>
    </FooterWrapper>
  )
})

export default LandingFooter
