import React, { FunctionComponent, useEffect, useState } from 'react'
import { Story } from '../../../utils/interfaces'
import { ActionButtons, AddStoryPhoto, StoryImageThumbnail } from './Stories.styles'
import Button from '../../../components/Button'
import Editor from '../../../components/Editor'
import Input from '../../../components/Input'
import { colors } from '../../../styles/_var'
import ImageUpload from '../../../components/ImageUpload'
import { faImage, faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { storyStore } from '../../../stores/storyStore'
import { getFilenameFromS3Url, uploadToS3 } from '../../../utils/helpers'
import Auth from '../../../utils/auth'
import uiStore from '../../../stores/uiStore'
import { AWS_BASE_URL, STORIES_FOLDER, StoryType } from '../../../utils/constants'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

type CreateStoryFormProps = {
  story?: Story,
  onComplete: (story: Story) => void,
}

export const RightButton = () => <FontAwesomeIcon icon={faArrowRight} />

const CreateStoryForm: FunctionComponent<CreateStoryFormProps> = ({ story, onComplete }) => {
  const [storyImage, setStoryImage] = useState(story?.thumbnail_image || '')
  const [title, setTitle] = useState(story?.title || '')
  const [uploadingImage, setUploadingImage] = useState(false)
  const [filename, setFilename] = useState(getFilenameFromS3Url(story?.thumbnail_image))
  const [content, setContent] = useState(story?.content || '')
  const [storyType, setStoryType] = useState(story?.type || 0)

  useEffect(() => {
    if (filename) return
    setFilename(`${STORIES_FOLDER}${Auth.fetchUsername()}_${Math.floor(Date.now() / 1000)}.jpg`)
  }, [filename])

  const onImageChange = async (image: string) => {
    if (uploadingImage || !filename) return
    setUploadingImage(true)

    try {
      await uploadToS3({ filename, fileUrl: image })
      setStoryImage(`${AWS_BASE_URL}/${filename}`)
    } catch {
      uiStore.showErrorMessage('Error uploading image')
    }

    setUploadingImage(false)
  }

  const onChangeTitle = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTitle(e.target.value)
  }

  const getStoryPayload = (): Story => {
    return { title, content, thumbnail_image: storyImage }
  }

  const onCompleteStory = (type: number) => () => {
    setStoryType(type)
    const payload: Story = {...getStoryPayload(), type}

    if (story) {
      storyStore.updateEntity(payload, { 
        itemId: story.uuid,
        shouldUseToast: false,
        onUpdatedItem: (story) => {
          onComplete(story)
        },
      })
    } else {
      storyStore.createEntity(payload, {
        onCreatedItem: (story) => {
          onComplete(story)
        }
      })
    }
  }

  const canSubmit = () => {
    return title.trim() && storyImage.trim() && content.trim()
  }

  return (
    <>
      <Input
        mb={2}
        value={title}
        onChange={onChangeTitle}
        placeholder='Write a Title!'
        variant='outlined' 
        fullWidth
      />
      <ImageUpload 
        onImageChange={onImageChange}
        aspectRatio={4/3}
        contentArea={({ openFileChooser, croppedImage }) => (
          <>
            <AddStoryPhoto
              mb={2}
              iconSize='lg'
              icon={faImage}
              variant='regular'
              color={colors.primary}
              onClick={openFileChooser}
            >
              {(croppedImage || storyImage) ? 'Change' : 'Add'} Story Photo
            </AddStoryPhoto>
            { (croppedImage || storyImage) && <StoryImageThumbnail uploading={uploadingImage} src={storyImage || croppedImage} alt="story thumbnail" />}
          </>
        )}
      />
      <Editor value={content} onChange={setContent} />
      <ActionButtons>
        <Button
          mt={2}
          onClick={onCompleteStory(StoryType.DRAFT)}
          variant='secondary'
          disabled={!canSubmit()}
          loading={(storyStore.creating || storyStore.updating) && storyType === StoryType.DRAFT}
        >
          SAVE AS DRAFT
        </Button>
        <Button
          mt={2}
          ml={1}
          disabled={!canSubmit()}
          adornmentPosition='end'
          adornment={<RightButton />}
          onClick={onCompleteStory(StoryType.COMPLETE)}
          loading={(storyStore.creating || storyStore.updating) && storyType === StoryType.COMPLETE}
        >
          SUBMIT
        </Button>
      </ActionButtons>
    </>
  )
}

export default CreateStoryForm