import React, { FunctionComponent } from 'react'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'

type EditorProps = {
  value: string,
  onChange: (value: string) => void
}

const Editor: FunctionComponent<EditorProps> = ({
  value,
  onChange
}) => {

  const modules = {
    toolbar: [
      [{ 'header': [1, 2, false] }],
      ['bold', 'italic', 'underline','strike', 'blockquote'],
      [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
      // ['link', 'image'],
      ['link'],
      ['clean']
    ],
  }

  const formats = [
    'header',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link'
  ]

  return (
    <ReactQuill 
      theme='snow'
      value={value}
      modules={modules}
      formats={formats}
      onChange={onChange} 
    />
  )
}

export default Editor


// note to Bodunde. Figure out the image ish later