import React, { FunctionComponent } from 'react'
import Avatar from '@mui/material/Avatar'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import Box from '../../../components/Box'
import Text from '../../../components/Text'
import { colors } from '../../../styles/_var'
import { humanizeAbbr } from '../../../utils/helpers'
import { ChatRoomHeader, ChatRoomItem, ChatRoomWrapper, Content, UnreadMarker } from './Messages.styles'
import { ChatRoomType, ParticipantType } from '../../../utils/interfaces'
import { AWS_BASE_URL } from '../../../utils/constants'
import TextWithIcon from '../../../components/TextWithIcon'
import { faImage } from '@fortawesome/free-solid-svg-icons'
import { useNavigate } from 'react-router-dom'


type ChatRoomsProps = {
  rooms: ChatRoomType[],
  getParticipant: (room: ChatRoomType) => ParticipantType | undefined,
  selectedRoom: ChatRoomType | null,
  selectActiveRoom: (room: ChatRoomType) => void,
}

const showRoomLastActivity = (message: string) => {
  if (!message) return null

  if (message.includes(AWS_BASE_URL)) {
    return (
      <TextWithIcon
        icon={faImage}
        variant='regular'
        truncate
        color={colors.grey}
      >
        Image
      </TextWithIcon>
    )
  } else {
    return (
      <Text
        maxWidth={260}
        variant='regular'
        truncate color={colors.grey}
      >
        {message}
      </Text>
    )
  }
}

const ChatRooms: FunctionComponent<ChatRoomsProps> = React.memo(({
  rooms,
  getParticipant,
  selectedRoom,
  selectActiveRoom
}) => {
  const navigate = useNavigate()

  return (
    <ChatRoomWrapper>
      <ChatRoomHeader>
        <TextWithIcon
          onClick={() => navigate(-1)}
          icon={faChevronLeft}
          variant='medium' 
          weight='bold' 
          uppercase
        >
          Messages
        </TextWithIcon>
      </ChatRoomHeader>
      {rooms.map((room, i) => {
        const participant = getParticipant(room)
        return (
          <ChatRoomItem 
            active={room.uuid === selectedRoom?.uuid} 
            key={i}
            onClick={() => selectActiveRoom(room)}
          >
            <Content>
              <Box style={{position: 'relative'}}>
                <Avatar 
                  src={participant?.user.avatar}
                  sx={{
                    borderRadius: '10px',
                    width: 45,
                    height: 45
                  }} 
                >
                  {participant?.user.username[0].toUpperCase()}
                </Avatar>
                {room.unread > 0 && <UnreadMarker />}
              </Box>
              <Box ml={2}>
                <Text variant='regular' weight='bold'>
                  {participant?.user.first_name} {participant?.user.last_name}
                </Text>
                <Box>
                  {showRoomLastActivity(room.last_message)}
                </Box>
              </Box>
            </Content>
            <Text variant='small' color={colors.grey} style={{justifySelf: 'flex-end'}}>
              {humanizeAbbr(room.updated_at)}
            </Text>
          </ChatRoomItem>
        )
      })}
    </ChatRoomWrapper>
  )
})

export default ChatRooms
