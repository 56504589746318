import React, { FunctionComponent } from "react"
import { Wrapper } from "./ShareButtons.styles";
import {
  TwitterShareButton,
  FacebookShareButton,
  WhatsappShareButton,
  TelegramShareButton,
  EmailShareButton,
  LinkedinShareButton,
  RedditShareButton,
  TumblrShareButton,
  TwitterIcon,
  FacebookIcon,
  WhatsappIcon,
  TelegramIcon,
  EmailIcon,
  LinkedinIcon,
  TumblrIcon,
  RedditIcon
} from "react-share";

type ShareButtonsProps = {
  shareUrl?: string
}

const ShareButtons: FunctionComponent<ShareButtonsProps> = React.memo(({ shareUrl }) => {
  const shareURL = shareUrl || window.location.href
  return (
    <Wrapper>
      <TwitterShareButton url={shareURL}>
        <TwitterIcon />
      </TwitterShareButton>
      <FacebookShareButton url={shareURL}>
        <FacebookIcon />
      </FacebookShareButton>
      <WhatsappShareButton url={shareURL}>
        <WhatsappIcon />
      </WhatsappShareButton>
      <TelegramShareButton url={shareURL}>
        <TelegramIcon />
      </TelegramShareButton>
      <EmailShareButton url={shareURL}>
        <EmailIcon />
      </EmailShareButton>
      <LinkedinShareButton url={shareURL}>
        <LinkedinIcon />
      </LinkedinShareButton>
      <RedditShareButton url={shareURL}>
        <RedditIcon />
      </RedditShareButton>
      <TumblrShareButton url={shareURL}>
        <TumblrIcon />
      </TumblrShareButton> 
    </Wrapper>
  )
})

export default ShareButtons
  