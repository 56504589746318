import styled from "styled-components"
import { colors } from "../../styles/_var"
import PageContent from "../PageContent"

export const NavbarHeight = '7rem'


export const MenuItemsWrapper = styled.div`
  width: 450px;
`

export const NavbarWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: ${colors.white};
  box-sizing: border-box;
  z-index: 2;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.08);
`

export const NavbarContent = styled(PageContent)`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: ${NavbarHeight};
  position: relative;
  z-index: 2;
  background-color: ${colors.white};
`

export const SiteLogo = styled.img`
  height: 3rem;
`
