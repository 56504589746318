import { LoginCredentials, SubscriptionType, User } from '../utils/interfaces'
import { apiRoutes, AVATARS_FOLDER, AWS_BASE_URL, methods, routes } from "../utils/constants";
import FieldStore, { defaultConfig as fieldDefaultConfig, Config as FieldStoreConfig } from "./fieldStore";
import EntityStore, { defaultConfig as entityDefaultConfig, Config as EntityStoreConfig } from './entityStore';
import { action, computed, makeObservable, observable } from 'mobx';
import Auth from '../utils/auth';
import uiStore from './uiStore';
import { isObject, retrieveErrorMessage, uploadToS3 } from '../utils/helpers';
import cloneDeep from 'lodash/cloneDeep';
import { googleAuthRequest } from '../utils/apiRequests';


type ResetPassword = {
  password: string,
  reset_token: string
}

type ChangePassword = {
  old_password: string,
  new_password: string
}

class LoginStore extends FieldStore<LoginCredentials> {
  config: FieldStoreConfig<User> = { 
    ...fieldDefaultConfig,
    url: apiRoutes.LOGIN,
    method: methods.POST
  };
}

class RegisterStore extends FieldStore<User, User> {
  config: FieldStoreConfig<User> = { 
    ...fieldDefaultConfig,
    url: apiRoutes.REGISTER,
    method: methods.POST
  };
}

class UserStore extends EntityStore<User> {
  config: EntityStoreConfig = {
    ...entityDefaultConfig,
    url: apiRoutes.USERS
  }

  userInfo: User = {} as User

  constructor() {
    super()
    makeObservable(this, { 
      userInfo: observable,
      fetchUserDetails: action,
      setUserInfo: action,
      logout: action,
      setSubscription: action,
      googleAuth: action
    })
  }

  setUserInfo(userDetails: User) {
    this.userInfo = userDetails
  }

  logout(onLogout: () => void) {
    uiStore.setLoadingScreenMessage("Logging out")
    Auth.logUserOut()
    
    setTimeout(() => {
      onLogout()
      uiStore.setLoadingScreenMessage("")
    }, 400)
  }

  setSubscription(subscription: SubscriptionType) {
    const userData = cloneDeep(this.userInfo)
    if (userData.info) {
      userData.info.subscription = subscription
    }
    this.userInfo = userData
  }

  async fetchUserDetails(username?: string) {
    const user = username || this.username
    const url = `${apiRoutes.USERS}/${user}`
    await this.retrieveEntity({ 
      url,
      onRetrieveItem: (item) => {
        this.setUserInfo(item)
      },
      onFailedToRetrieve: (errorMessage) => {
        if (/not found/i.test(errorMessage)) {
          this.logout(() => {
            window.location.href = routes.LOGIN
          })
        }
      }
    })
  }

  async googleAuth(idToken: string) {
    uiStore.setLoadingScreenMessage('Please give us a moment')
    try {
      const response = await googleAuthRequest(idToken)
      Auth.saveUserDetails(response.data.results)
      uiStore.showSuccessMessage('Successful! redirecting you to dashboard.')
      setTimeout(() => {
        window.location.href = routes.DASHBOARD
      }, 400);
    } catch (e) {
      uiStore.showErrorMessage(retrieveErrorMessage(e))
    } finally {
      uiStore.setLoadingScreenMessage('')
    }
  }

  async updateUserDetails(updatePayload: any) {
    if (updatePayload['avatar']) {
      const filename = `${AVATARS_FOLDER}${Auth.fetchUsername()}.jpg`
      const avatarUrl = `${AWS_BASE_URL}/${filename}`

      uploadToS3({filename, fileUrl: updatePayload['avatar']}).then(() => {
        uiStore.showSuccessMessage('avatar updated')
        this.setUserInfo(this.userInfo)
      }).catch(() => {
        uiStore.showErrorMessage('Could not save avatar, try again!')
      })

      updatePayload['avatar'] = avatarUrl
    }
  
    await this.updateEntity(updatePayload, {
      url: apiRoutes.UPDATE_USER,
      method: 'PATCH',
      shouldUseToast: true,
      onUpdatedItem: (data: any) => {
        this.setUserInfo(data)

        if (updatePayload.username) {
          localStorage.setItem('username', data.username)
          this.logout(() => {
            window.location.href = '/'
          })
        }
      }
    })
  }

  get isActivated() {
    return this.userInfo.info?.activated
  }

  get userInfoLoaded() {
    return !!Object.keys(this.userInfo).length
  }

  get fullName() {
    return `${this.userInfo.first_name} ${this.userInfo.last_name}`
  }

  get avatar() {
    return this.userInfo.info?.avatar
  }

  get subscription() {
    return this.userInfo.info?.subscription
  }

  get hasRegularSubscription() {
    return this.subscription === 'BR'
  }

  get username() {
    return this.userInfo?.username || ''
  }

  get avatarText() {
    return this.username ? this.username[0].toUpperCase() : ''
  }

  get email() {
    return this.userInfo.email
  }

  get credit() {
    return this.userInfo.info?.user_credit || 0
  }

  get appsyncToken() {
    return this.userInfo.appsync_token
  }
}

class ForgotPasswordStore extends FieldStore<{email: string}> {
  config: FieldStoreConfig<{email: string}> = {
    ...fieldDefaultConfig,
    url: apiRoutes.RESET_PASSWORD_REQUEST,
    method: methods.POST
  }
}

class ResetPasswordStore extends FieldStore<ResetPassword> {
  config: FieldStoreConfig<ResetPassword> = {
    ...fieldDefaultConfig,
    url: apiRoutes.RESET_PASSWORD,
    method: methods.POST
  }
}

class ActivateUserStore extends FieldStore<{code: string}>{
  config: FieldStoreConfig<ResetPassword> = {
    ...fieldDefaultConfig,
    url: apiRoutes.ACTIVATE_USER,
    method: methods.POST
  }
}

class SendActivationCodeStore extends FieldStore<any> {
  config: FieldStoreConfig <any> = {
    ...fieldDefaultConfig,
    url: apiRoutes.SEND_ACTIVATION_CODE,
    method: methods.POST,
    useToastOnSuccess: true
  }
}

class FetchTodaysStatsStore extends FieldStore<any> {
  config: FieldStoreConfig <any> = {
    ...fieldDefaultConfig,
    url: apiRoutes.TODAYS_STATS,
    method: methods.GET,
  }

  constructor() {
    super()
    makeObservable(this, {
      partnerStats: computed,
      storiesStats: computed
    })
  }

  get partnerStats() {
    if (!this.data) return []
    return [
      `${this.data.partners_added} newly added partners`,
      `${this.data.todays_baerifications} baerify searches`
    ]
  }

  get storiesStats() {
    if (!this.data) return []
    return [
      `${this.data.stories_added} newly added stories`,
      `${this.data.published_stories} newly published stories`,
    ]
  }
}

class ChangePasswordStore extends FieldStore<ChangePassword> {
  config: FieldStoreConfig<ChangePassword> = {
    ...fieldDefaultConfig,
    url: apiRoutes.CHANGE_PASSWORD,
    method: methods.POST,
    useToastOnError: false,
    useToastOnSuccess: true,
    onRequestFailed: (errMessage: any) => {
      let toastErrMessages: any = ''

      if (isObject(errMessage)) {
        toastErrMessages = Object.values(errMessage)
      } else if (typeof errMessage === 'string' && errMessage.includes('Credentials')) {
        toastErrMessages = 'Old password is incorrect'
      } else {
        toastErrMessages = errMessage
      }

      uiStore.showToastMessage({
        message: toastErrMessages,
        severity: 'error',
        duration: 10000
      })
    }
  }
}

class DeleteAccountStore extends FieldStore<any> {
  config: FieldStoreConfig<ChangePassword> = {
    ...fieldDefaultConfig,
    url: apiRoutes.DELETE_ACCOUNT,
    method: methods.POST,
    useToastOnError: true,
    useToastOnSuccess: true,
    onRequestComplete: (_) => {
      userStore.logout(() => {
        window.location.href = '/'
      })
    }
  }
}



export const loginStore = new LoginStore()
export const registerStore = new RegisterStore()
export const userStore = new UserStore()
export const forgotPasswordStore = new ForgotPasswordStore()
export const resetPasswordStore = new ResetPasswordStore()
export const activateUserStore = new ActivateUserStore()
export const changePasswordStore = new ChangePasswordStore()
export const deleteAccountStore = new DeleteAccountStore()
export const sendActivationCodeStore = new SendActivationCodeStore()
export const fetchTodaysStatsStore = new FetchTodaysStatsStore()