import { faStar } from '@fortawesome/free-solid-svg-icons'
import React, { useEffect } from 'react'
import Button from '../../../components/Button'
import Text from '../../../components/Text'
import TextWithIcon from '../../../components/TextWithIcon'
import DashboardWithSidebar from '../../../layouts/DashboardWithSidebar'
import { upgradeSubscriptionStore } from '../../../stores/paymentStore'
import { userStore } from '../../../stores/userStore'
import { colors } from '../../../styles/_var'
import { SubscriptionPrice } from '../../../utils/constants'
import { toCurrency } from '../../../utils/helpers'
import { Features } from './UpgradeNow.styles'
import { observer } from 'mobx-react-lite'

const UpgradeNow = observer(() => {
  const subscription = userStore.subscription
  useEffect(() => {
    if (subscription !== 'BR') {
      window.location.href = '/'
    }
  }, [subscription])


  return (
    <DashboardWithSidebar>
      <Text variant='medium' weight='bold' mb={1}>Upgrade your account</Text>
      <Text variant='regular' color={colors.grey}>
        You are using our baerify free plan, To connect with this user, you will have to upgrade your account to <strong>Baerify Plus.</strong>
      </Text>
      <Features>
        <TextWithIcon
          iconSize='3x'
          icon={faStar}
          weight='bold'
          variant='large'
          color={colors.primary}
          mb={1}
        >
          Baerify Plus
        </TextWithIcon>
        <Text variant='large' inline>{toCurrency(SubscriptionPrice.BP/100)}</Text> {" "}
        <Text variant='medium' inline>per month</Text>
        <ul>
          <li>
            <Text variant='regular'>Unlimited messaging with connects</Text>
          </li>
          <li>
            <Text variant='regular'>Unlimited number of partners</Text>
          </li>
          <li>
            <Text variant='regular'>Unlimited number of daily baerifications</Text>
          </li>
          <li>
            <Text variant='regular'>Number of times partners have been searched for daily</Text>
          </li>
          <li>
            <Text variant='regular'>Email Notifications</Text>
          </li>
          <li>
            <Text variant='regular'>Ability to ignore/block conflicts</Text>
          </li>
        </ul>
        <Button block mt={1} onClick={() => upgradeSubscriptionStore.onSubscriptionSelected('BP')}>
          <Text variant='medium'>UPGRADE</Text>
        </Button>
      </Features>
    </DashboardWithSidebar>
  )
})

export default UpgradeNow