import React from 'react'
import { colors } from '../../styles/_var'
import { routes } from '../../utils/constants'
import Link from '../Link'
import Text from '../Text'

const LoginLink = React.memo(() => {
  return (
    <Link to={routes.LOGIN}>
      <Text variant='small' mt={3} center color={colors.primary}>
        Back to login
      </Text>
    </Link>
  )
})

export default LoginLink
