import { faCheckCircle, faExclamationCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { FunctionComponent } from 'react'
import { IModal } from '../../stores/uiStore'
import { colors } from '../../styles/_var'
import { MessageType } from '../../utils/interfaces'
import { Wrapper } from './ActionResponse.styles'
import Text from '../Text'


const ActionResponse: FunctionComponent<IModal> = React.memo(({
  title,
  message,
  variant
}) => {
  const getModalIcon = (variant: MessageType) => {
    switch (variant) {
      case 'error':
        return faTimesCircle
      case 'success':
        return faCheckCircle
      default:
        return faExclamationCircle
    }
  }

  return (
    <Wrapper>
      {!!title && <Text weight='bold' variant="medium">{title}</Text>}
      <FontAwesomeIcon
        icon={getModalIcon(variant)}
        size="5x"
        color={colors.primary} />
      {!!message && <Text variant="regular" mt={2} id="modal-message">{message}</Text>}
    </Wrapper>
  )
})

export default ActionResponse
