import styled from "styled-components";
import { colors } from "../../styles/_var";

export const Wrapper = styled.div`
  border-radius: 50%;
  width: 3.2rem;
  height: 3.2rem;
  background-color: ${colors.primary};
  display: flex;
  align-items: center;
  justify-content: center;
`
