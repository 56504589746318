import React, { FunctionComponent, useEffect, useState } from 'react'
import { routes } from '../../utils/constants'
import { Outlet, useNavigate } from 'react-router-dom'
import Auth from '../../utils/auth'
import MainAuthLayout from '../MainAuthLayout'


const AuthLayout: FunctionComponent = () => {
  const navigate = useNavigate()
  const [canAccessAuth, setCanAccessAuth] = useState(false)

  useEffect(() => {
    if (Auth.isLoggedIn()) {
      navigate(routes.DASHBOARD)
    } else {
      setCanAccessAuth(true)
    }
  }, [navigate])

  if (!canAccessAuth) return null

  return (
    <MainAuthLayout>
      <Outlet />
    </MainAuthLayout>
  )
}


export default AuthLayout