import React, { FunctionComponent } from "react"
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons"
import { useNavigate } from "react-router-dom"
import { colors } from "../../styles/_var"
import TextWithIcon from "../TextWithIcon"

type BackButtonProps = {
  to?: string,
  label?: string
}

const BackButton: FunctionComponent<BackButtonProps> = ({ to, label }) => {
  const navigate = useNavigate()

  const onClick = () => {
    if (to) {
      navigate(to)
    } else {
      navigate(-1)
    }
  }

  return (
    <TextWithIcon
      variant='regular'
      icon={faArrowLeft}
      color={colors.primary}
      onClick={onClick}
    >
      {label || 'Go Back'}
    </TextWithIcon>
  )
}

export default BackButton
