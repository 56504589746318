import React, { FunctionComponent, useEffect } from 'react'
import { Routes, Route, useLocation } from 'react-router-dom'
import ConfirmationDialog from './components/ConfirmationDialog'
import Dialog from './components/Dialog'
import LoadingScreen from './components/LoadingScreen'
import Toast from './components/Toast'
import AuthLayout from './layouts/AuthLayout'
import DashboardLayout from './layouts/DashboardLayout'
import Activate from './pages/auth/Activate'
import ForgotPassword from './pages/auth/ForgotPassword'
import Login from './pages/auth/Login'
import Register from './pages/auth/Register'
import ResetPassword from './pages/auth/ResetPassword'
import ContactUs from './pages/contactUs/ContactUs'
import BaerificationResults from './pages/dashboard/BaerificationResults'
import Home from './pages/dashboard/Home'
import Messages from './pages/dashboard/Messages'
import Notifications from './pages/dashboard/Notifications'
import Partners from './pages/dashboard/Partners'
import CreatePartner from './pages/dashboard/Partners/CreatePartner'
import EditPartner from './pages/dashboard/Partners/EditPartner'
import PartnerConflicts from './pages/dashboard/Partners/PartnerConflicts'
import ViewPartner from './pages/dashboard/Partners/ViewPartner'
import Stories, { CreateStory } from './pages/dashboard/Stories'
import EditStory from './pages/dashboard/Stories/EditStory'
import ViewStory from './pages/dashboard/Stories/ViewStory'
import UpgradeNow from './pages/dashboard/UpgradeNow/UpgradeNow'
import ErrorPage from './pages/errorPages/ErrorPage'
import LandingPage from './pages/landingPage/LandingPage'
import PrivacyPolicy from './pages/PrivacyPolicy/PrivacyPolicy'
import TermsOfService from './pages/TermsOfService/TermsOfServices'
import { routes } from './utils/constants'
import '@datadog/browser-logs/bundle/datadog-logs'
import { initializeGA, isDev, trackPageView } from './utils/helpers'


initializeGA()


const AppRoutes: FunctionComponent = () => {
  const location = useLocation()

  useEffect(() => {
    if (isDev) return

    window.DD_LOGS && window.DD_LOGS.init({
      clientToken: process.env.REACT_APP_DD_CLIENT_KEY,
      site: 'datadoghq.com',
      forwardErrorsToLogs: true,
      sampleRate: 6,
    })

    console.log('connected to dd')
  }, [])

  useEffect(() => {
    window.scroll(0, 0)
    trackPageView(location)
  }, [location])


  return (
    <>
      <Toast />
      <Dialog />
      <ConfirmationDialog />
      <LoadingScreen />
      <Routes>
        <Route path={routes.DASHBOARD} element={<DashboardLayout />}>
          <Route path='/' element={<Home />} />
          <Route path={routes.BAERFICACTION_RESULTS} element={<BaerificationResults />} />
          <Route path={routes.STORIES} element={<Stories />} />
          <Route path={routes.CREATE_STORY} element={<CreateStory />} />
          <Route path={routes.EDIT_STORY} element={<EditStory />} />
          <Route path={routes.VIEW_STORY} element={<ViewStory />} />
          <Route path={routes.NOTIFICATIONS} element={<Notifications />} />
          <Route path={routes.MESSAGES} element={<Messages />} />
          <Route path={routes.MY_PARTNERS} element={<Partners />} />
          <Route path={routes.CREATE_PARTNER} element={<CreatePartner />} />
          <Route path={routes.VIEW_PARTNER} element={<ViewPartner />} />
          <Route path={routes.EDIT_PARTNER} element={<EditPartner />} />
          <Route path={routes.PARTNER_CONFLICT} element={<PartnerConflicts />} />
          <Route path={routes.UPGRADE_NOW} element={<UpgradeNow />} />
        </Route>
        <Route path='/' element={<AuthLayout />}>
          <Route path={routes.LOGIN} element={<Login />} />
          <Route path={routes.REGISTER} element={<Register />} />
          <Route path={routes.FORGOT_PASSWORD} element={<ForgotPassword />} />
          <Route path={routes.RESET_PASSWORD} element={<ResetPassword />} />
        </Route>
        <Route path={routes.ACTIVATE_USER} element={<Activate />} />
        <Route path={routes.PRIVACY_POLICY} element={<PrivacyPolicy />} />
        <Route path={routes.TERMS_AND_CONDITIONS} element={<TermsOfService />} />
        <Route path={routes.ABOUT_US} element={<LandingPage />} />
        <Route path={routes.CONTACT_US} element={<ContactUs />} />
        <Route path='*' element={<ErrorPage errorCode='404' />} />
      </Routes>
    </>
  )
}

export default AppRoutes
