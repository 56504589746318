import { observer } from 'mobx-react-lite'
import React, { FunctionComponent } from 'react'
import { NotificationTypes } from '../../../utils/constants'
import { INotification } from '../../../utils/interfaces'
import CommentView from './CommentView'
import PartnerConflictView from './PartnerConflictView'
import StoryLikedView from './StoryLikedView'
import SubscriptionChangeView from './SubscriptionChangeView'



const NotificationView: FunctionComponent<INotification & React.HTMLAttributes<HTMLDivElement>> = ({
  onClick,
  ...rest
}) => {
  if (!rest.notification_object) return null
  return (
    <div onClick={onClick}>
      {(() => {
        switch(rest.verb) {
          case NotificationTypes.LIKED_YOUR_COMMENT:
            return <CommentView {...rest} />
          case NotificationTypes.PARTNER_CONFLICT:
            return <PartnerConflictView {...rest} />
          case NotificationTypes.REPLIED_YOUR_COMMENT:
            return <CommentView {...rest} />
          case NotificationTypes.STORY_LIKED:
            return <StoryLikedView {...rest} />
          case NotificationTypes.SUBSCRIPTION_CHANGE:
            return <SubscriptionChangeView {...rest} />
          case NotificationTypes.COMMENT_ADDED:
            return <CommentView {...rest} />
          default:
            return null
        }
      })()}
    </div>
  )
}


export default observer(NotificationView)