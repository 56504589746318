import { IPagination, IStatus, MethodType } from './interfaces'

export const regexPatterns = {
  phone: /^(\+)*(\d+)(-)*/,
  email: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  notUsername: /[@]?[^\w._|@]/
}

export const StoryType = {
  DRAFT: 1,
  COMPLETE: 2,
  PUBLISHED: 3
}

export const routes = {
  DASHBOARD: '/',
  ABOUT_US: '/about_us',
  LOGIN: '/login',
  REGISTER: '/register',
  CONTACT_US: '/contact_us',
  FORGOT_PASSWORD: '/forgot_password',
  RESET_PASSWORD: '/reset_password/:token',
  ACTIVATE_USER: '/activate_user',
  MY_PARTNERS: '/partners',
  CREATE_PARTNER: '/partners/new',
  VIEW_PARTNER: '/partners/:partnerId',
  EDIT_PARTNER: '/partners/:partnerId/edit',
  PARTNER_CONFLICT: '/partners/:partnerId/conflicts',
  STORIES: '/stories',
  CREATE_STORY: '/stories/new',
  VIEW_STORY: '/stories/:storyId',
  EDIT_STORY: '/stories/:storyId/edit',
  MESSAGES: '/messages',
  NOTIFICATIONS: '/notifications',
  PROFILE: '/profile',
  BAERFICACTION_RESULTS: '/results',
  UPGRADE_NOW: '/upgrade_now',
  PRIVACY_POLICY: '/privacy-policy',
  TERMS_AND_CONDITIONS: '/terms-and-conditions'
}

export const defaultCreditCardIcon = 'https://matusky.s3.us-west-2.amazonaws.com/credit-cards/Visa.png'

export const CreditCardIcons: any = {
  alipay: 'https://matusky.s3.us-west-2.amazonaws.com/credit-cards/Alipay.png',
  amazonpay: 'https://matusky.s3.us-west-2.amazonaws.com/credit-cards/AmazonPay.png',
  amex: 'https://matusky.s3.us-west-2.amazonaws.com/credit-cards/Amex.png',
  applepay: 'https://matusky.s3.us-west-2.amazonaws.com/credit-cards/ApplePay.png',
  bitcoin: 'https://matusky.s3.us-west-2.amazonaws.com/credit-cards/Bitcoin.png',
  bitpay: 'https://matusky.s3.us-west-2.amazonaws.com/credit-cards/BitPay.png',
  dinersclub: 'https://matusky.s3.us-west-2.amazonaws.com/credit-cards/DinersClub.png',
  discover: 'https://matusky.s3.us-west-2.amazonaws.com/credit-cards/Discover.png',
  etherium: 'https://matusky.s3.us-west-2.amazonaws.com/credit-cards/Etherium.png',
  facebookpay: 'https://matusky.s3.us-west-2.amazonaws.com/credit-cards/FacebookPay.png',
  googlepay: 'https://matusky.s3.us-west-2.amazonaws.com/credit-cards/GooglePay.png',
  klarna: 'https://matusky.s3.us-west-2.amazonaws.com/credit-cards/Klarna.png',
  lightcoin: 'https://matusky.s3.us-west-2.amazonaws.com/credit-cards/Lightcoin.png',
  mastercard: 'https://matusky.s3.us-west-2.amazonaws.com/credit-cards/Mastercard.png',
  paypal: 'https://matusky.s3.us-west-2.amazonaws.com/credit-cards/PayPal.png',
  qiwi: 'https://matusky.s3.us-west-2.amazonaws.com/credit-cards/Qiwi.png',
  shoppay: 'https://matusky.s3.us-west-2.amazonaws.com/credit-cards/ShopPay.png',
  skrill: 'https://matusky.s3.us-west-2.amazonaws.com/credit-cards/Skrill.png',
  stripe: 'https://matusky.s3.us-west-2.amazonaws.com/credit-cards/Stripe.png',
  visa: 'https://matusky.s3.us-west-2.amazonaws.com/credit-cards/Visa.png',
  wechat: 'https://matusky.s3.us-west-2.amazonaws.com/credit-cards/WeChat.png',
  yandex: 'https://matusky.s3.us-west-2.amazonaws.com/credit-cards/Yandex.png'
}

export const getPaginationDefaults = (): IPagination => {
  return { num_of_pages: 0, has_next: true, current_page: 0 }
}

export const PaginationDefaults: IPagination = {
  num_of_pages: 0,
  has_next: true,
  current_page: 0,
}

export const status: IStatus = {
  LOADING: 'LOADING',
  COMPLETE: 'COMPLETE',
  FAILED: 'FAILED'
}

export const errorMessage = 'Something went wrong, please try again';
export const AWS_BASE_URL = process.env.REACT_APP_NODE_ENV === 'production' ? 'https://baerify-prod.s3.eu-west-1.amazonaws.com' : 'https://baerify-staging.s3.eu-west-1.amazonaws.com'
export const STORIES_FOLDER = 'stories/'
export const AVATARS_FOLDER = 'avatars/'
export const CHAT_MESSAGES_FOLDER = 'chat_images/'
export const PARTNER_EVIDENCE_FOLDER = 'partner_evidence/'

export const methods: MethodType = {
  POST: 'POST',
  GET: 'GET',
  PUT: 'PUT',
  DELETE: 'DELETE'
}

export const apiRoutes = {
  LOGIN: '/users/login',
  REGISTER: '/users/register',
  USERS: '/users',
  RESET_PASSWORD_REQUEST: '/users/reset_password_request',
  RESET_PASSWORD: '/users/reset_password',
  ACTIVATE_USER: '/users/activate_user',
  SEND_ACTIVATION_CODE: 'users/send_activation_code',
  UPDATE_USER: '/users/update_details',
  CHANGE_PASSWORD: '/users/change_password',
  NOTIFICATION_SETTINGS: '/users/notifications',
  NOTIFICATIONS: '/notifications',
  DELETE_ACCOUNT: '/users/delete_account',
  CREDIT_CARDS: '/creditcards',
  SUBSCRIPTION: '/subscriptions',
  BAERIFY: '/partners/baerify',
  STORIES: '/stories',
  TODAYS_STATS: '/todays_stats',
  CHAT_ROOMS: '/chatrooms',
  CHAT_MESSAGES: '/chatrooms/:roomId/messages',
  MY_STORIES: `/stories?type=${StoryType.COMPLETE}`,
  MY_PUBLISHED_STORIES: `/stories?type=${StoryType.PUBLISHED}`,
  MY_DRAFTS: `/stories?type=${StoryType.DRAFT}`,
  PUBLICATIONS: '/stories/publications',
  RECENT_BAERIFICATION: '/partners/recent_baerifications',
  STORY_COMMENTS: '/stories/:storyId/comments',
  CREDIT_CARDS_UPDATE: (cardId: string) => `/creditcards/${cardId}`,
  MAKE_DEFAULT_CREDIT_CARD: (cardId: string) => `/creditcards/${cardId}/default`,
  PARTNERS: '/partners',
}

export const Subs: any = {
  BR: 'Baerify Regular',
  BP: 'Baerify Plus',
  // BG: 'Baerify Gold',
  // BPR: 'Baerify Premium'
}

export const Subscriptions: any = {
  BR: 'Baerify Regular (Free)',
  BP: 'Baerify Plus ($10.00)',
  // BG: 'Baerify Gold (N2,500.00)',
  // BPR: 'Baerify Premium (N4,000.00)'
}

export const SubscriptionsList: any = Object.keys(Subscriptions).map(sub => ({ value: sub, label: Subscriptions[sub] }))

export const SubscriptionPrice: any = {
  BR: 0,
  BP: 1000,
  BG: 250000,
  BPR: 400000
}

export const RelationshipLabel = [
  '',
  'Just Talking',
  'Casual Dating',
  'Dating',
  'In a relationship',
  'Engaged',
  'Married'
]

export const RelationshipLabel2: any = {
  'Just Talking': 'Just Talking to',
  'Casual Dating': 'Casually Dating',
  'Dating': 'Dating',
  'In a relationship': 'In a relationship with',
  'Engaged': 'Engaged to',
  'Married': 'Married to'
}

export const NotificationTypes = {
  SUBSCRIPTION_CHANGE: 'SUBSCRIPTION_CHANGE',
  PARTNER_CONFLICT: 'PARTNER_CONFLICT',
  REPLIED_YOUR_COMMENT: 'REPLIED_YOUR_COMMENT',
  LIKED_YOUR_COMMENT: 'LIKED_YOUR_COMMENT',
  STORY_LIKED: 'STORY_LIKED',
  COMMENT_ADDED: 'COMMENT_ADDED'
}

export const MessageType = {
  TEXT: 1,
  IMAGE: 2
}