import { SwitchProps } from "@mui/material/Switch"
import React, { FunctionComponent } from "react"
import { StyledSwitch } from "./Switch.styles"


const Switch: FunctionComponent<SwitchProps> = React.memo((props) => {
  return <StyledSwitch {...props} />
})

export default Switch
  