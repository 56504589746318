import { AlertColor } from '@mui/material/Alert'
import isEmpty from 'lodash/isEmpty'
import { makeAutoObservable } from 'mobx'
import React from 'react'
import { makeid } from '../utils/helpers'

type ToastPosition = 'top' | 'bottom' | 'left-bottom' | 'right-bottom' | 'left-top' | 'right-top'

export interface IModal {
  title?: string,
  message?: string,
  variant?: AlertColor
}

export interface IToastMessage {
  id?: string,
  message: string,
  severity?: AlertColor,
  position?: ToastPosition,
  duration?: number,
}

export const TOAST_DEFAULTS: IToastMessage = {
  id: '',
  message: '',
  severity: 'success',
  position: 'top',
  duration: 5000
}

type DialogPayload = {
  dialogTitle?: React.ReactNode | string;
  dialogBody?: React.ReactNode | string;
  onConfirmAction?: () => void;
  onCancelAction?: () => void;
  cancelButtonText?: string,
  confirmButtonText?: string,
}

class UiStore {
  loadingScreenMessage = ''
  modalPayload: IModal = {}
  toastMessages: IToastMessage[] = []
  showDialog: boolean = false
  dialogPayload: DialogPayload = {}
  showConfirmationDialog: boolean = false
  confirmationDialogPayload: DialogPayload = {}
  userAccountExpanded: string = ''
  showFooter = true
  showNav = true

  constructor() {
    makeAutoObservable(this)
  }

  openDialog(dialogPayload: DialogPayload) {
    this.showDialog = true
    this.dialogPayload = dialogPayload
  }

  closeDialog() {
    this.showDialog = false
    this.dialogPayload = {}
  }

  setLoadingScreenMessage(message: string) {
    this.loadingScreenMessage = message
  }

  displayModal(modalPayload: IModal) {
    this.modalPayload = modalPayload
  }

  successModal(message: string) {
    this.displayModal({ variant: 'success', message })
  }

  errorModal(message: string) {
    this.displayModal({ variant: 'error', message })
  }

  closeModal() {
    this.modalPayload = {}
  }

  openConfirmDialog(dialogPayload: DialogPayload) {
    this.showConfirmationDialog = true
    this.confirmationDialogPayload = dialogPayload
  }

  closeConfirmDialog() {
    this.confirmationDialogPayload = {}
    this.showConfirmationDialog = false
  }

  showToastMessage(messageData: IToastMessage) {
    if (typeof messageData.message === 'object') {
      messageData.message = Object.values(messageData.message) as any
    } 
    const newToastMessage = {...TOAST_DEFAULTS, ...messageData, id: makeid(5)}
    this.toastMessages = [...this.toastMessages, newToastMessage]
  }

  showErrorMessage(message: string) {
    this.showToastMessage({
      message,
      severity: 'error'
    })
  }

  showSuccessMessage(message: string) {
    this.showToastMessage({
      message,
      severity: 'success'
    })
  }

  showInfoMessage(message: string) {
    this.showToastMessage({
      message,
      severity: 'info'
    })
  }

  removeToast(toastId?: string) {
    if (toastId) {
      this.toastMessages = this.toastMessages.filter(toast => toast.id !== toastId)
    } else {
      this.toastMessages = []
    }
  }

  setFooterVisibility(visibility: boolean) {
    this.showFooter = visibility  
  }

  setExpanded(panelName: string) {
    this.userAccountExpanded = panelName
  }

  setNavVisibility(visibility: boolean) {
    this.showNav = visibility
  }

  get displayLoadingScreen() {
    return !!this.loadingScreenMessage
  }

  get showModal() {
    return !isEmpty(this.modalPayload)
  }

  get hasToast() {
    return !!this.toastMessages.length
  }
}

export default new UiStore()
