import React, { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { useParams } from 'react-router-dom'
import SimpleForm from '../../../components/SimpleForm'
import Input from '../../../components/Input'
import validators from '../../../utils/validators'
import { resetPasswordStore } from '../../../stores/userStore'
import ActionResponse from '../../../components/ActionResponse'
import LoginLink from '../../../components/LoginLink'
import AuthLayoutHeader from '../../../components/AuthLayoutHeader'


const ResetPassword = () => {
  const { token } = useParams()

  const onSubmit = (data: any) => {
    const resetPayload = {...data, reset_token: token}
    resetPasswordStore.makeRequest(resetPayload)
  }

  useEffect(() => {
    return () => {
      resetPasswordStore.clear()
    }
  }, [])

  if (resetPasswordStore.complete) {
    return (
      <>
        <ActionResponse 
          title='Success' 
          message='Password Reset Successful! Go ahead and login'
          variant='success'
        />
        <LoginLink />
      </>
    )
  }

  return (
    <>
      <AuthLayoutHeader 
        title='Password Reset?'
        subtitle='Proceed to reset your password'
      />
      <SimpleForm 
          formFields={resetPasswordFields}
          submitForm={onSubmit}
          actionButtons={{
            label: 'Change Password',
            loading: resetPasswordStore.loading,
            invertOnHover: true,
            isSubmit: true
          }}
        />
    </>
  )
}

export default observer(ResetPassword)

const resetPasswordFields = [
  {
    name: 'password',
    placeholder: 'Enter your new password',
    component: Input,
    type: 'password',
    max_length: 100,
    validate: validators.isPassword
  }
]