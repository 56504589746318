import styled from 'styled-components'
import { device, spacing } from '../../styles/_var'

export default styled.div`
  padding: 0 ${spacing.xs};

  @media ${device.sm} {
    padding: 0 ${spacing.rg};
  }

  @media ${device.lg} {
    padding: 0 ${spacing.xxl};
  }

  max-width: 125rem;
  margin-right: auto;
  margin-left: auto;
`
