import styled from "styled-components";
import { spacing } from "../../styles/_var";

export const Wrapper = styled.div`
  margin-top: ${spacing.xs};
  display: flex;
  justify-content: center;

  button {
    border-radius: 5px !important;
  }
`
