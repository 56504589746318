import styled from "styled-components";
import { colors, device, spacing } from "../../styles/_var";
import Text from "../Text";

export const Wrapper = styled.div`
  background: ${colors.yellow};
  padding: 2.3rem;
  border-radius: 20px;
  display: flex;
  justify-content: space-between;
`

export const AuthorWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const AuthorImage = styled.img`
  border-radius: 50%;
  width: 2.5rem;
  height: 2.5rem;
`

export const HeaderSection = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
`

export const StoryImage = styled.div`
  background-image: url("https://media.wired.com/photos/62166d6484b02e35168334b6/191:100/w_1280,c_limit/Culture_AkilahTowsend---_0174-4.jpg");
  background-size: cover;
  with: 100%;
  height: 25rem;
  margin-bottom: ${spacing.xs};
`

export const StoryInfoSection = styled.div`
  display: flex;
  align-items: center;
  margin-top: ${spacing.xs};
  padding-bottom: 5px;
`

export const ActionIcons = styled.div`
  display: flex;
  align-items: center;
  transform: translateY(-30%);

  svg {
    width: 20px;
    height: 20px;

    &:last-child {
      width: 17px;
      height: 17px;
    }
  }

  svg:first-child {
    margin-right: ${spacing.xxs};
  }

  svg:last-child path {
    fill: ${colors.darkgrey};
  }
`

export const Title = styled(Text)`
  line-height: 2.4rem;
`

export const SectionOne = styled.div`
  flex: 1;
`

export const SectionTwo = styled.div`
  margin-left: ${spacing.xxs};
  width: 12rem;

  @media ${device.sm} {
    width: 15rem;
  }
`

export const Thumbnail = styled(({ src, ...rest }) => <div {...rest} />)`
  width: 100%;
  height: 100%;
  background-size: cover;
  ${({ src }) => src && `
    background-image: url(${src});
  `}
`