import React, { FunctionComponent } from "react"
import { StyledRadio } from "./Radio.styles"
import { RadioProps } from "@mui/material/Radio"


const Radio: FunctionComponent<RadioProps> = React.memo((props) => {
  return (
    <StyledRadio {...props} />
  )
})

export default Radio
  