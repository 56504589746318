import React, { FunctionComponent } from "react"
import { ReactComponent as Arrow } from '../../assets/images/arrow.svg';
import { Wrapper } from "./GoButton.styles";
import IconButton from '@mui/material/IconButton';


const GoButton: FunctionComponent<React.HTMLAttributes<HTMLDivElement>> = React.memo((props) => {
  return (
    <Wrapper {...props}>
      <IconButton sx={{ color: 'rgba(255, 255, 255, 0.54)' }}>
        <Arrow />
      </IconButton>
    </Wrapper>
  )
})

export default GoButton
  