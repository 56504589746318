import React from 'react'
import { observer } from 'mobx-react-lite'
import { useNavigate } from 'react-router-dom'
import BackButton from '../../../components/BackButton'
import Text from '../../../components/Text'
import useGetStory from '../../../hooks/useGetStory'
import DashboardWithSidebar from '../../../layouts/DashboardWithSidebar'
import { routes, StoryType } from '../../../utils/constants'
import { Story } from '../../../utils/interfaces'
import CreateStoryForm from './CreateStoryForm'
import uiStore from '../../../stores/uiStore'
import { myDraftsStore } from '../../../stores/storyStore'


const EditStory = () => {
  const navigate = useNavigate()
  const { story } = useGetStory()

  if (!story) return null

  const onUpdateStory = (story: Story) => {
    let successMessage: string

    if (story.type === StoryType.COMPLETE) {
      successMessage = 'Story successfully Updated.'
    } else {
      successMessage = 'Story saved to draft.'
    }
    uiStore.showSuccessMessage(successMessage)
    navigate(routes.STORIES)
    myDraftsStore.updateItem(story, 'uuid')
  }

  return (
    <DashboardWithSidebar>
      <BackButton to={routes.STORIES} />
      <Text variant='medium' weight='bold' mt={2} mb={2}>
        Update your story
      </Text>
      <CreateStoryForm
        story={story}
        onComplete={onUpdateStory}
      />
    </DashboardWithSidebar>
  )
}

export default observer(EditStory)

