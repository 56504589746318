import styled from "styled-components";
import { colors, spacing } from "../../../styles/_var";

export const Wrapper = styled.div``

export const NotificationWrapper = styled.div`
  padding: ${spacing.xxs} 0;
`

export const NotificationItem = styled(({ read, ...rest }) => <div {...rest} />)`
  border-top: solid 1px ${colors.lightgrey};
  padding: ${spacing.xxs};
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: all ease .2s;

  &:hover {
    transform: scale(1.02);
  }

  ${({ read }) => !read && `
    background-color: ${colors.lightyellow};
  `}

  &:last-child {
    border-bottom-style: none;
  }
`
