import styled from "styled-components"
import { colors, fontSize, spacing } from "../../styles/_var"
import { IButtonProps, ButtonVariant } from "./Button"



export const AdornedButton = styled(({ position, ...rest }) => <div {...rest} />)`
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  align-items: center;

  ${({ position }) => position === 'start' && `
    flex-direction: row;
    & img, svg {
      margin-right: 5px;
    }
  `}  

  ${({ position }) => position === 'after-text' && `
    & img, svg {
      margin-left: 5px;
    }
  `}  

  ${({ position }) => position === 'end' && `
    justify-content: space-between;
    padding: 0 ${spacing.xxs};
  `}
`

export const getStyle = (variant: ButtonVariant, invertOnHover: boolean, block: boolean=false) => {
  let style = `
    min-width: 11rem;
    display: inline-block;
    border-radius: 13px;
    cursor: pointer;
    padding-top: 1rem;
    padding-bottom: 1rem;
    border: solid 1px ${colors.primary};
    font-size: ${fontSize.xs};
    font-weight: 600;
    box-sizing: border-box;
    transition: all ease .2s;
    text-align: center;
    text-decoration: none;
  `

  if (block) {
    style += 'width: 100%;'
  }

  if (variant === 'primary') {
    style += `
      background: ${colors.primary};
      color: ${colors.white};
      &:hover {
        opacity: 0.7;
      }
    `
  }

  if (variant === 'secondary') {
    style += `
      background: ${colors.white};
      color: ${colors.primary};
    `
  }

  if (variant === 'danger') {
    style += `
      background: ${colors.red};
      color: ${colors.white};
      border: solid 1px ${colors.primary};
    `
  }

  return style;
}

export const StyledButton = styled.button<IButtonProps>`
  &:disabled {
    opacity: .6;
    cursor: not-allowed;
  }
  ${props => getStyle(props.variant || 'primary', props.invertOnHover || false, props.block || false)}
  ${props => props.alignCenter && `
    width: auto;
    display: block;
    margin-left: auto;
    margin-right: auto;
  `}
`