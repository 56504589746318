import React, { FunctionComponent } from 'react'
import { colors } from '../../styles/_var'
import Box from '../Box'
import Text from '../Text'

type AuthLayoutHeaderProps = {
  title?: string,
  subtitle?: string | React.ReactNode
}

const AuthLayoutHeader: FunctionComponent<AuthLayoutHeaderProps> = React.memo(({
  title,
  subtitle
}) => {
return (
  <Box mb={3}>
    <Text variant='medium' weight="bold">{title}</Text>
    <Text variant='small' color={colors.grey}>{subtitle}</Text>
  </Box> 
)
})

export default AuthLayoutHeader
