import React, { FunctionComponent } from 'react'
import { NoPartnersWrapper } from './Partners.styles'
import noPartner from '../../../assets/images/no-partners.png'
import Text from '../../../components/Text'
import { colors } from '../../../styles/_var'
import Button from '../../../components/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import Link from '../../../components/Link'
import { routes } from '../../../utils/constants'

const NoPartners: FunctionComponent<{message?: string}> = React.memo(({
  message
}) => {
  return (
    <NoPartnersWrapper>
      <img src={noPartner} alt='no partner' width={250} height='auto' />
      <Text variant='medium' weight='bold' color={colors.grey} mt={1}>
        {message || 'You have no partners added yet'}
      </Text>
      <Text variant='regular' color={colors.grey} maxWidth={190} center>
        Click the button below to get started!
      </Text>
      <Link to={routes.CREATE_PARTNER}>
        <Button
          mt={1}
          adornment={<FontAwesomeIcon icon={faArrowRight} />}
          adornmentPosition='end'
        >
          <Text variant='regular' weight={400} color={colors.white} mr={1}>
            ADD A PARTNER
          </Text>
        </Button>
      </Link>
    </NoPartnersWrapper>
  )
})

export default NoPartners