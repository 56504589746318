import styled from "styled-components"
import { colors, spacing } from "../../styles/_var"
import Stack from '@mui/material/Stack';

export const StyledStack = styled(Stack)`
  padding-top: ${spacing.xxs};
  position: fixed;
  top: 0;
  z-index: 2000;
  width: 100%;
  left: 0;

  & .MuiAlert-standard, .MuiAlert-filled, .MuiAlert-outlined {
    align-items: center;
    align-self: center;
    transition: all ease .2s;
  }

  & .MuiAlert-standardError {
    color: ${colors.red};
  }

  & .MuiAlert-action {
    padding-top: 0;
  }

  & p {
    font-size: 1.5rem;
  }
`