import React, { FunctionComponent } from 'react'
import { User } from '../../../utils/interfaces'
import Text from '../../../components/Text'
import StoryLabel from '../../../components/StoryLabel'
import { Header } from './Stories.styles'
import AvatarWithLabel from '../../../components/AvatarWithLabel'

type StoryHeaderProps = {
  owner?: User,
  type?: number,
  title: string
}

const StoryHeader: FunctionComponent<StoryHeaderProps> = ({
  owner,
  type,
  title
}) => {
  return (
    <>
      <Header>
        <AvatarWithLabel
          label={owner?.username || ''}
          src={owner?.info?.avatar} 
          labelProps={{variant: 'small'}}
        />
        <StoryLabel type={type} />
      </Header>
      <Text variant='medium' mt={2} weight='bold'>{title}</Text>
    </>
  )
}

export default StoryHeader
