import React, { FunctionComponent, useState } from 'react'
import validators from '../../../utils/validators'
import InputAdornment from '@mui/material/InputAdornment'
import PhoneNoInput from '../../../components/PhoneNoInput'
import Input from '../../../components/Input'
import Text from '../../../components/Text'
import SimpleForm from '../../../components/SimpleForm'
import Box from '../../../components/Box'
import { colors } from '../../../styles/_var'


type CreatePartnerStepOneType = {
  onFormComplete: (personalInfo: any) => void,
  onExtensionChange: (ext: string) => void,
  ext: string,
  data: any
}

const CreatePartnerStepOne: FunctionComponent<CreatePartnerStepOneType> = React.memo(({
  onFormComplete,
  data,
  onExtensionChange,
  ext
}) => {
  const [formPayload, setFormPayload] = useState<any>(data)

  const savePartnerDetails = (data: any) => {
    setFormPayload(data)
    const partnerPersonalInfo = {
      ...data,
      phone: `${ext}-${data.phone}`
    }
    onFormComplete(partnerPersonalInfo)
  }

  const formFields = [
    {
      name: 'first_name',
      label: 'Bae\'s First Name*',
      component: Input,
      validate: validators.required
    },
    {
      name: 'last_name',
      label: 'Bae\'s Last Name*',
      component: Input,
      validate: validators.required
    },
    {
      name: 'email',
      label: 'Bae\'s Email*',
      component: Input,
      validate: validators.isEmail
    },
    {
      name: 'phone',
      component: PhoneNoInput,
      max_length: 50,
      label: 'Bae\'s Phone Number*',
      validate: validators.required,
      extension: ext,
      onExtensionChange: (e: any) => {
        onExtensionChange(e.target.value)
      }
    },
    {
      name: 'instagram',
      label: 'Bae\'s Instagram (optional)',
      component: Input,
      validate: validators.isUsername,
      InputProps: {
        startAdornment: (
          <InputAdornment position="start">
            <Text variant='regular'>@</Text>
          </InputAdornment>
        ),
      }
    },
    {
      name: 'twitter',
      label: 'Bae\'s Twitter (optional)',
      component: Input,
      validate: validators.isUsername,
      InputProps: {
        startAdornment: (
          <InputAdornment position="start">
            <Text variant='regular'>@</Text>
          </InputAdornment>
        ),
      }
    }
  ]

  return (
    <>
      <Box style={{maxWidth: 400}}>
        <Text variant='regular' color={colors.grey} mb={1}>
          Is your bae really your bae? Add a partner to your account to find out.
        </Text>
      </Box>
      <SimpleForm 
        initialValues={formPayload}
        formFields={formFields}
        submitForm={savePartnerDetails}
        actionButtons={{
          label: 'Next',
          isSubmit: true
        }}
      />
    </>
  )
})

export default CreatePartnerStepOne
