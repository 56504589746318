import React, { FunctionComponent } from 'react'
import { observer } from 'mobx-react-lite'
import { useNavigate, useParams } from 'react-router-dom'
import CircularProgress from '@mui/material/CircularProgress';
import Link from '../../../components/Link'
import Text from '../../../components/Text'
import useGetPartner from '../../../hooks/useGetPartner'
import DashboardWithSidebar from '../../../layouts/DashboardWithSidebar'
import { partnerStore, pictureEvidenceStore } from '../../../stores/partnerStore'
import { colors, spacing } from '../../../styles/_var'
import { routes } from '../../../utils/constants'
import { PartnerItemHeader } from './Partners.styles'
import NoPartners from './NoPartners'
import PartnerInfo from './PartnerInfo'
import PartnerEvidence from './PartnerEvidence'
import { PartnerEvidenceType } from '../../../utils/interfaces'
import Box from '../../../components/Box'
import Button from '../../../components/Button'
import Alert from '@mui/material/Alert';
import BackButton from '../../../components/BackButton'


const ViewPartner = () => {
  const { partnerId } = useParams()
  const navigate = useNavigate()
  useGetPartner(partnerId)

  if (!partnerId) return null
  const partner = partnerStore.item

  const addEvidence = async (picture: string, caption?: string) => {
    pictureEvidenceStore.saveEvidence(partnerId, picture, caption)
  }

  const removeEvidence = async (picture: PartnerEvidenceType) => {
    pictureEvidenceStore.removeEvidence(partnerId, picture)
  }

  const deleteParner = () => {
    partnerStore.deletePartner(partnerId, () => {
      navigate(routes.MY_PARTNERS)
    })
  }

  const renderPartnerView = () => {
    return (
      <>
        {partner?.has_conflict && <ConflictNotice partnerId={partner.uuid} />}
        {!partner && <NoPartners message='Unfortunately we cannot locate this partner' />}
        {!!partner && (
          <>
            <PartnerInfo partner={partner} />
            <Box style={{textAlign: 'center'}}>
              <Link to={routes.EDIT_PARTNER.replace(':partnerId', partner.uuid || '')}>
                <Button variant='primary' mr={2}>Edit</Button>
              </Link>
              <Button variant='secondary' onClick={deleteParner}>
                Delete
              </Button>
            </Box>
            {!!partner && (
              <PartnerEvidence
                evidence={pictureEvidenceStore.list}
                onAdd={addEvidence}
                onRemove={removeEvidence}
              />
            )}
          </>
        )}
      </>
    )
  }

  return (
    <DashboardWithSidebar>
      <PartnerItemHeader style={{marginBottom: spacing.xs}}>
        <Text variant='medium' weight='bold'>
          {partner?.first_name} {partner?.last_name}
        </Text>
        <BackButton label='Back to partners' to={routes.MY_PARTNERS} />
      </PartnerItemHeader>
      {(!partner && partnerStore.fetching) && <Box alignCenter mt={1}><CircularProgress style={{color: colors.primary}} /></Box>}
      {(partner || !partnerStore.fetching) && renderPartnerView()}
    </DashboardWithSidebar>
  )
}

export default observer(ViewPartner)

const ConflictNotice: FunctionComponent<{partnerId?: string}> = React.memo(({
  partnerId
}) => {
  return (
    <Alert severity="warning" style={{marginBottom: spacing.sm}}>
      <Text variant='regular' inline>Matches found! To view click </Text> {" "}
      <Link to={routes.PARTNER_CONFLICT.replace(':partnerId', partnerId || '')}>
        <Text variant='regular' underline inline color={colors.danger}>here.</Text>
      </Link>
    </Alert>
  )
})