import styled from "styled-components";
import Switch from '@mui/material/Switch';
import { colors } from "../../styles/_var";

export const StyledSwitch = styled(Switch)`
  & .MuiSwitch-colorPrimary.Mui-checked {
    color: ${colors.primary};
  }

  & .MuiSwitch-colorPrimary.Mui-checked+.MuiSwitch-track {
    background-color: ${colors.primary};
  }
`
