import React, { useCallback } from 'react'
import { observer } from 'mobx-react-lite'
import Alert from '@mui/material/Alert';
import { myStoryStore } from '../../../stores/storyStore'
import StoryList from './StoryList'
import { spacing } from '../../../styles/_var';
import Text from '../../../components/Text';

const MyStories = () => {
  const makeRequest = useCallback((page?: number) => {
    myStoryStore.makeRequest(undefined, { page })
  }, [])

  const loading = myStoryStore.loading && !myStoryStore.hasData

  return (
    <>
      <Alert severity='info' style={{marginBottom: spacing.sm}}>
        <Text variant='regular'>
          You'll be notified if we publish any of your stories
        </Text>
      </Alert>
      <StoryList 
        loading={loading}
        makeRequest={makeRequest}
        data={myStoryStore.data}
        showNextButton={myStoryStore.showNextButton}
        noDataText='You have not written any stories yet'
        hasData={myStoryStore.hasData}
      />
    </>
  )
}

export default observer(MyStories)
