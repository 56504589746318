import React from 'react'
import { useNavigate } from "react-router-dom";
import { observer } from 'mobx-react-lite';
import Text from '../../../components/Text'
import { colors } from '../../../styles/_var'
import SimpleForm, { IActionButton } from '../../../components/SimpleForm'
import Input from '../../../components/Input'
import Link from '../../../components/Link'
import Box from '../../../components/Box'
import { routes } from '../../../utils/constants'
import validators from '../../../utils/validators'
import { loginStore } from '../../../stores/userStore';
import Auth from '../../../utils/auth';
import AuthLayoutHeader from '../../../components/AuthLayoutHeader';
import GoogleAuthButton from '../../../components/GoogleAuthButton';
import { gaSendEvent } from '../../../utils/helpers';


const Login = () => {
  const loginFields = [
    {
      name: 'username',
      label: 'Username or Email',
      component: Input,
      max_length: 50,
      validate: validators.required
    },
    {
      name: 'password',
      label: 'Password',
      type: 'password',
      component: Input,
      max_length: 50,
      validate: validators.required,
    },
  ]

  const actionButtons: IActionButton[] = [
    {
      label: 'LOG IN',
      loading: loginStore.loading,
      isSubmit: true
    }
  ]
  const navigate = useNavigate()
  const submitForm = (data: any) => {
    gaSendEvent({
      category: "auth",
      action: "login",
      label: "user login", // optional
    });
    loginStore.makeRequest(data, {
      onRequestComplete(serverData: any) {
        Auth.saveUserDetails(serverData.results)
        setTimeout(() => navigate(routes.DASHBOARD), 400)
      }
    })
  }

  return (
    <>
      <AuthLayoutHeader 
        title='Welcome Back'
        subtitle='Sign in to continue'
      />
      <SimpleForm
        formFields={loginFields}
        submitForm={submitForm}
        actionButtons={actionButtons}
      />
      <GoogleAuthButton />
      <Text variant='small' center mt={2}>Did you forget your password?</Text>
      <Link to={routes.FORGOT_PASSWORD}>
        <Text variant='small' weight='bold' center color={colors.primary}>
          Reset here
        </Text>
      </Link>
      <Box alignCenter mt={4}>
        <Text variant='small' center inline>Don't have an account? </Text> {""}
        <Link to={routes.REGISTER}>
          <Text variant='small' weight='bold' inline center color={colors.primary}>
            Sign up
          </Text>
        </Link>
      </Box>
    </>
  )
}

export default observer(Login)
