import React, { FunctionComponent } from 'react'
import Text from '../../../components/Text'
import { MenuItem } from '../../../components/UserAvatarButton/UserAvatarButton.styles'
import { ChatMessageOptionsWrapper } from './Messages.styles'

type ChatMessageOptionsProps = {
  onDelete: () => void,
}

const ChatMessageOptions: FunctionComponent<ChatMessageOptionsProps> = React.memo(({
  onDelete
}) => {
  return (
    <ChatMessageOptionsWrapper showMenu={true}>
      <MenuItem onClick={onDelete}>
        <Text variant="regular" ml={1}>Delete</Text>
      </MenuItem>
    </ChatMessageOptionsWrapper>
  )
})

export default ChatMessageOptions
