import { action, computed, makeObservable } from "mobx";
import { apiRoutes, methods } from "../utils/constants";
import { fetch } from "../utils/helpers";
import { INotification } from "../utils/interfaces";
import FieldStore, { Config, defaultConfig } from "./fieldStore";

class NotificationsStore extends FieldStore<INotification[]> {
  config: Config<any> = {
    ...defaultConfig,
    url: apiRoutes.NOTIFICATIONS,
    method: methods.GET,
    isPaginated: true,
  }

  constructor() {
    super()
    makeObservable(this, {
      markAsRead: action,
      hasUnread: computed
    })
  }

  async markAsRead(notification: INotification) {
    const url = `${apiRoutes.NOTIFICATIONS}/mark_as_read`
    await fetch({ url, method: 'POST', body: { notification_ids: notification.id }})
    this.updateItem({...notification, read: true})
  }

  getComment(objectId: string) {
    const notification = this.data?.find(notification => {
      return +notification.notification_object.id === +objectId && notification.verb.includes('COMMENT')
    })

    return notification ? notification.notification_object : notification
  }

  get hasUnread() {
    return this.data?.some(notification => !notification.read)
  }
}

export const notificationsStore = new NotificationsStore()