import React, { FunctionComponent } from "react"
import { Avatar } from "@mui/material"
import { colors } from "../../styles/_var"
import { humanize, plurify } from "../../utils/helpers"
import { IConflict } from "../../utils/interfaces"
import GoButton from "../GoButton"
import Text from "../Text"
import { ResultWrapper, Wrapper, Header } from "./ConflictResults.styles"
import { partnerConflictStore } from "../../stores/partnerStore"
import uiStore from "../../stores/uiStore"
import ConflictView from "../ConflictView"

type ConflictResultsProps = {
  results: IConflict[],
  subject?: string,
  partnerId?: string,
}

type ConflictResultProp = {
  result: IConflict,
  subject?: string,
  partnerId?: string
}

const Result: FunctionComponent<ConflictResultProp> = React.memo(({
  result,
  subject,
  partnerId
}) => {
  const {
    evidence,
    relationship,
    relationship_start_date,
    user,
    concealed
  } = result
  const onClickResult = () => {
    partnerConflictStore.setSelectedConflict(result)
    uiStore.openDialog({
      dialogBody: <ConflictView subject={subject} partnerId={partnerId} />
    })
  }

  return (
    <ResultWrapper concealed={concealed}>
      <Header>
        <Avatar
          src={user.avatar}
          sx={{
            width: 40,
            height: 40,
            bgcolor: colors.pink,
            border: `solid 2px ${colors.grey}`
          }}
        >
          <Text variant="medium">
            {user.username[0].toUpperCase()}
          </Text>
        </Avatar>
        <GoButton style={{ width: 40, height: 40}} onClick={onClickResult} />
      </Header>
      <Text variant="regular" mt={1} weight='bold'>
        {user.first_name} {user.last_name}
      </Text>
      <Text variant="regular" color={colors.primary} inline>{relationship}</Text>
      {" "}
      <Text inline> began {humanize(relationship_start_date)}</Text>
      <Text variant="regular" color={colors.darkgrey}>
        +{evidence?.length} photos
      </Text>
    </ResultWrapper>
  )
})

const ConflictResults: FunctionComponent<ConflictResultsProps> = ({ 
  results,
  subject,
  partnerId
}) => {
  if (!results) return null

  const count = results?.length

  return (
    <Wrapper>
      <Text
        center
        mb={3}
        variant="semi-large"
        color={colors.darkgrey}
      >
        {plurify('match', 'matches', count)} found...
      </Text>
      {results.map((result, i) => (
        <Result
          result={result}
          subject={subject}
          key={i}
          partnerId={partnerId} 
        />
      ))}
    </Wrapper>
  )
}

export default ConflictResults
  