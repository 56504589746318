import React from 'react'
import Divider from '@mui/material/Divider'
import Text from '../../components/Text'
import { colors } from '../../styles/_var'

const ContactUsHeader = React.memo(() => {
  return (
    <>
      <Text variant='title' weight='bold' center>
        Contact Us
      </Text>
      <Text
        mt={3}
        mb={3}
        center
        variant='medium'
        color={colors.darkgrey}
        maxWidth={490}
      >
        Please fill out the form below to contact us and one of our representatives would get back to you within 24 hours.
      </Text>
      <Divider style={{borderBottomWidth: 2}} />
    </>
  )
})

export default ContactUsHeader
