import React from 'react'
import { DashboardWrapper } from '../../layouts/MainDashboardLayout/MainDashboardLayout'
import LandingPageNav from '../landingPage/LandingPageNav'
import { Wrapper } from './ContactUs.styles'
import Grid from '@mui/material/Grid'
import ContactUsHeader from './ContactUsHeader'
import ContactUsForm from './ContactUsForm'
import ContactUsInstructions from './ContactUsInstructions'
import LandingFooter from '../landingPage/LandingFooter'


const ContactUs = React.memo(() => {
  return (
    <DashboardWrapper>
      <LandingPageNav />
      <Wrapper>
        <ContactUsHeader />
        <Grid container>
          <Grid item xs={12} md={6}>
            <ContactUsForm />
          </Grid>
          <Grid item xs={12} md={6}>
            <ContactUsInstructions />
          </Grid>
        </Grid>
      </Wrapper>
      <LandingFooter />
    </DashboardWrapper>
  )
})

export default ContactUs
