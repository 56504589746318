import React, { FunctionComponent } from 'react'
import LatestStories from '../../../components/LatestStories'
import QuickBaerificationForm from '../../../components/QuickBaerificationForm'
import { RecentBaerifications, RecentPartners } from '../../../components/RightSideComponents'
import useIsMobile from '../../../hooks/useIsMobile'
import DashboardWithSidebar from '../../../layouts/DashboardWithSidebar'

const Home: FunctionComponent = () => {
  const isMobile = useIsMobile()

  return (
    <DashboardWithSidebar>
      <QuickBaerificationForm />
      {isMobile && <RecentPartners />}
      {isMobile && <RecentBaerifications />}
      <LatestStories />
    </DashboardWithSidebar>
  )
}

export default Home
