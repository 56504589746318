import React, { useCallback } from 'react'
import { observer } from 'mobx-react-lite'
import { publishedStoryStore } from '../../../stores/storyStore'
import StoryList from './StoryList'
import { Story } from '../../../utils/interfaces'

const RecentStories = () => {
  const makeRequest = useCallback((page?: number) => {
    publishedStoryStore.makeRequest(undefined, { page })
  }, [])

  const loading = publishedStoryStore.loading && !publishedStoryStore.hasData

  return (
    <StoryList
      loading={loading}
      makeRequest={makeRequest}
      data={publishedStoryStore.data?.map<Story>(({story}) => ({...story, published: true})) || null}
      hasData={publishedStoryStore.hasData}
      showNextButton={publishedStoryStore.showNextButton}
      noDataText="No new stories at the moment"
    />
  )
}

export default observer(RecentStories)
