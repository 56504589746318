import styled from "styled-components"
import { spacing } from "../../styles/_var"

export const NavbarHeight = '6rem'


export const MenuItemsWrapper = styled.div`
  width: 450px;
`

export const ActionSection = styled.div`
  display: flex;
  & > a {
    margin-right: ${spacing.xs}
  }

  & > a:last-child {
    margin-right: 0;
  }
`