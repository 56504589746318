import styled from 'styled-components'
import PageContent from '../../components/PageContent'
import Box from '../../components/Box'
import { colors, device, spacing } from '../../styles/_var'

export const Wrapper = styled(PageContent)`
  padding-top: 12rem;
`

export const ContactFormWrapper = styled(Box)`
  margin-right: auto;
  margin-left: auto;
  max-width: 40rem;
  box-shadow: 0 0 2px 2px ${colors.primary};
  padding: ${spacing.sm} ${spacing.rg};

  @media ${device.md} {
    margin-right: 0;
    margin-left: 0;
  }
`