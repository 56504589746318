import styled from "styled-components";


export const Wrapper = styled(({ center, ...rest }) => <div {...rest} />)`
  ${({ center }) => center && `
    justify-content: center;
  `}

  cursor: pointer;
  display: flex;
  align-items: center;

  svg {
    margin-right: 5px;
  }
`
