import styled from "styled-components";
import { ProfileHeader, Wrapper as ProfileWrapper } from "../UserProfile/UserProfile.styles";

export const Wrapper = styled(ProfileWrapper)`
  margin-bottom: 0;
`

export const SectionHeader = styled(ProfileHeader)`
  height: 5.5rem;
`

export const SectionBody = styled.div``
