import React, { FunctionComponent, useState } from 'react'
import Box from '../../../components/Box'
import Button from '../../../components/Button'
import Input from '../../../components/Input'
import ListOptions from '../../../components/ListOptions'
import moment from 'moment'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { IList } from '../../../components/ListOptions/ListOptions'
import Text from '../../../components/Text'
import { RelationshipLabel } from '../../../utils/constants'
import { colors } from '../../../styles/_var'
import uiStore from '../../../stores/uiStore'
import { StepTwoWrapper } from './Partners.styles'

type CreatePartnerStepTwoProps = {
  gotoPreviousStep: () => void,
  onComplete: (relationship: number, date: string) => void,
  creatingPartner: boolean,
  initialRelationship?: number,
  initialRelationshipStartDate?: string,
}

let relationshipList:IList[]  = []

RelationshipLabel.forEach((label, i) => {
  if (label) relationshipList.push({label, value: i})
})

const CreatePartnerStepTwo: FunctionComponent<CreatePartnerStepTwoProps> = React.memo(({
  gotoPreviousStep,
  onComplete,
  creatingPartner,
  initialRelationship,
  initialRelationshipStartDate
}) => {
  const [relationshipStartDate, setRelationshipStartDate] = React.useState<moment.Moment>(moment(initialRelationshipStartDate).local());
  const [relationship, setRelationship] = useState<string | number>(initialRelationship || 0)

  const onSubmit = () => {
    if (!relationship) {
      uiStore.showErrorMessage('Please select a relationship')
    } else {
      onComplete(+relationship, relationshipStartDate.utc().format())
    }
  }

  return (
    <StepTwoWrapper>
      <Box style={{maxWidth: 400}}>
        <Text variant='regular' color={colors.grey} mb={1}>
          Could you please select what kind of relationship you have with your bae
        </Text>
      </Box>
      <ListOptions
        value={relationship}
        onChange={setRelationship}
        lists={relationshipList}
      />
      <Text variant='regular' color={colors.grey} mb={1} mt={1}>
        When did this relationship begin?
      </Text>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <DateTimePicker
          renderInput={(props) => <Input {...props} />}
          value={relationshipStartDate}
          onChange={(newValue) => newValue && setRelationshipStartDate(newValue)}
        />
      </LocalizationProvider>
      <Box mt={2} alignCenter>
        <Button variant='secondary' onClick={gotoPreviousStep}>
          Go Back
        </Button>
        <Button variant='primary' ml={2} onClick={onSubmit} loading={creatingPartner}>
          Save Partner
        </Button>
      </Box>
    </StepTwoWrapper>
  )
})

export default CreatePartnerStepTwo