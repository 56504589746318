import { CircularProgress } from '@mui/material'
import React from 'react'
import BaerifierImageIcon from '../../../components/BaerifierImageIcon'
import Text from '../../../components/Text'
import { colors } from '../../../styles/_var'
import { Wrapper } from './BaerificationLoader.styles'

const BaerificationLoader = React.memo(() => {
  return (
    <Wrapper>
      <CircularProgress style={{color: colors.grey}} />
      <BaerifierImageIcon mt={4} mb={4} duration={100} />
      <Text variant='semi-large' mt={1}>
        Checking for matches...
      </Text>
      <Text maxWidth={280} variant="regular" color={colors.grey} mt={2}>
        Our AI bots are running a check to the details provided to see if we find other users whose bae matches yours
      </Text>
    </Wrapper>
  )
})

export default BaerificationLoader
