import React, { useCallback } from 'react'
import { observer } from 'mobx-react-lite'
import { myDraftsStore } from '../../../stores/storyStore'
import StoryList from './StoryList'

const MyStories = () => {
  const makeRequest = useCallback((page?: number) => {
    myDraftsStore.makeRequest(undefined, { page })
  }, [])

  const loading = myDraftsStore.loading && !myDraftsStore.hasData

  return (
    <StoryList
      loading={loading}
      makeRequest={makeRequest}
      data={myDraftsStore.data}
      showNextButton={myDraftsStore.showNextButton}
      hasData={myDraftsStore.hasData}
      noDataText="Your drafts are empty"
    />
  )
}

export default observer(MyStories)
