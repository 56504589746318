import React, { useState } from 'react'
import SimpleForm from '../../components/SimpleForm'
import Input from '../../components/Input'
import uiStore from '../../stores/uiStore'
import validators from '../../utils/validators'
import { retrieveErrorMessage } from '../../utils/helpers'
import Text from '../../components/Text'
import { ContactFormWrapper } from './ContactUs.styles'
import { IHelpRequest } from '../../utils/interfaces'
import { sendHelpRequest } from '../../utils/apiRequests'

const useContactUsRequest = () => {
  const [loading, setLoading] = useState(false)
  const sendMessage = async (payload: IHelpRequest, onSuccess: any, onFail: any) => {
    try {
      setLoading(true)
      const response = await sendHelpRequest(payload)
      onSuccess(response.data.message)
    } catch (e) { 
      onFail(retrieveErrorMessage(e))
    } finally {
      setLoading(false)
    }
  }
  return { loading, sendMessage }
}


const ContactUsForm = () => {
  const onFailedToSendMessage = (errMessage: string) => uiStore.errorModal(errMessage)
  const onSuccessfullySendMessage = (form: any) => (responseMessage: string) => {
    uiStore.showSuccessMessage(responseMessage)
    form.restart()
  }
  const { loading, sendMessage } = useContactUsRequest()

  return (
    <ContactFormWrapper mt={5} mb={4}>
      <Text variant='medium' weight='bold' center mb={3}>
        We are here to help.
      </Text>
      <SimpleForm
        formFields={contactFields}
        submitForm={(data, form) => sendMessage(
          data,
          onSuccessfullySendMessage(form),
          onFailedToSendMessage
        )}
        actionButtons={{
          label: 'Send Message',
          loading,
          invertOnHover: true,
          isSubmit: true
        }} />
    </ContactFormWrapper>
  )
}

export default ContactUsForm

const contactFields = [
  {
    name: 'fullname',
    placeholder: 'Please enter your full name',
    component: Input,
    validate: validators.required
  },
  {
    name: 'email',
    placeholder: 'Please enter your email',
    component: Input,
    validate: validators.isEmail,
  },
  {
    name: 'message',
    placeholder: 'What do you need help with?',
    multiline: true,
    component: Input,
    validate: validators.required
  }
]
