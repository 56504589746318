import styled from "styled-components";
import PageContent from "../../components/PageContent";
import { spacing } from "../../styles/_var";

export const Wrapper = styled(({ isMobile, ...rest }) => <PageContent {...rest} />)`
  padding-top: ${({ isMobile }) => isMobile ? '9rem' : '10rem'};
  padding-bottom: ${({ isMobile }) => isMobile ? '7rem' : '0'};
  display: flex;
`

export const LeftSidebar = styled(({ isMobile, ...rest }) => <div {...rest} />)`
  ${({ isMobile }) => isMobile && `
    display: none;
  `}
  width: 15rem;
`

export const RightSidebar = styled(({ isMobile, ...rest }) => <div {...rest} />)`
  ${({ isMobile }) => isMobile && `
    display: none;
  `}
  width: 30rem;
`

export const MainContent = styled(({ isMobile, ...rest }) => <div {...rest} />)`
  flex: 1;
  margin-left: ${({ isMobile }) => isMobile ? 0 : spacing.xl};
  margin-right: ${({ isMobile }) => isMobile ? 0 : spacing.xl};
`

